
export class Product {
  id: number;
  cikkszam: string;
  ccskod: string;
  focsop: string;
  nev: string;
  defImage: string;
  subfolderName: string;
  topfolderName: string;
  akcioazon: string;
  wPriceBrutto: number;
  wPriceNetto: number;
  wPrice: string;
  kedvszaz: number;
  akedvszaz: number;
  eredNettoListaar: number;
  eredBruttoListaar: number;
  akcios: number;
  me:string;
  me_szor: number;
  statme: string;
  statszor: number;
  loadTms: number;
  alergen: string;
  alergenArr: string[];
  isFavorite: boolean;
  dbmeny: number;
  keselt: number;
  gymenny: number;
}
