import { Action } from '@ngrx/store';
import { Product } from '../models/product.model';
import { ActionWithPayload } from './action-with-payload';

export const PRODUCTS_UPDATED = 'PRODUCTS_UPDATED';
//export const CARS_ADD = 'CARS_ADD';

export function productsReducer(state: Product[] = [], action: ActionWithPayload<Product[]>) {
  switch (action.type) {
    case PRODUCTS_UPDATED:          
      var resultArray:Array<Product>=[] ;
      action.payload.forEach( i => { 
        resultArray.push(i);
      });
      return resultArray;
    
    //case CARS_ADD:                
    //  return state.concat(action.payload);
     default:
	return state;
  }
}