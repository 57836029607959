import { Action } from '@ngrx/store';
import { Order } from '../models/order.model';
import { ActionWithPayload } from './action-with-payload';

export const ORDER_UPDATED = 'ORDER_UPDATED';

export function orderReducer(state: Order = null, action: ActionWithPayload<Order>) {
  switch (action.type) {
    case ORDER_UPDATED:                
      if(action.payload == null) {
        return null;
      }
      return Object.assign({}, action.payload);                
    default:
	  return state;
  }
}