import { Component, OnInit, ElementRef, Input } from '@angular/core';
import { Router, ActivatedRoute, Params, NavigationExtras } from '@angular/router';
import { FavoritesService } from '../../services/favorites.service';
import { Product } from '../../models/product.model';
import { AppState } from '../../app-state';
import { Store } from '@ngrx/store';
declare var $: any;
import { EMAIL_REGEX, PASSWORD_REGEX, PRODUCT_IMAGES_DIR } from '../../utils/constants';

@Component({
    selector: 'favorites',
    templateUrl: './favorites.component.html'

})
export class FavoritesComponent implements OnInit {
    product_images_url: string = PRODUCT_IMAGES_DIR;

    subscribeFavorites: any;
    subscribeProducts: any;
    favorites: string[] = [];
    //favoritesProducts: number[] = [];
    products: Product[] = [];
    favoriteProducts: Product[] = [];

    constructor(
        private router: Router,
        private elementRef: ElementRef,
        private favoritesService: FavoritesService,
        private store: Store<AppState>) {
    }

    ngOnInit() {
        this.subscribeProducts = this.store.select('products').subscribe(
            products => {
                if (products ) {
                    this.products = products;
                    this.setFavoriteProducts();
                } 
            },
            error => console.log(error)
        );
        
        this.subscribeFavorites = this.store.select('favorites').subscribe(
            favorites => {
                if (favorites) {
                    this.favorites = favorites;
                    this.setFavoriteProducts();                    
                } else {
                    this.favorites = [];
                    this.favoriteProducts = [];   
                }
            },
            error => console.log(error)
        );
    }

    setFavoriteProducts() {
        if(this.products.length > 0) {
            this.favoriteProducts = [];
            for(var product of this.products) {                
                var isFavorite = this.favorites.find(f=> f == product.cikkszam);
                if(isFavorite) {
                    //product.isFavorite = true;                    
                    this.favoriteProducts.push(product);
                } else {
                    //product.isFavorite = false;
                }
            }
        }
    }

    
    onClickItem(item: any) {
        let navigationExtras: NavigationExtras = {
            queryParams: { 'id': item.cikkszam }
        };
        this.router.navigate([{ outlets: { popup: 'product' } }], navigationExtras);
    }

    removeItem(item: Product) {
        this.favoritesService.removeFavoritesItem(item.cikkszam);
    }

    onClickRemoveAll() {
        this.favoritesService.clearAll();
    }

    ngOnDestroy(): void {
        this.subscribeFavorites.unsubscribe();
        this.subscribeProducts.unsubscribe();
    }
}
