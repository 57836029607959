import { ActionWithPayload } from './action-with-payload';
import { CartItem } from '../models/cartitem.model';

export const ADD_CARTITEM = 'ADD_CARTITEM';
export const REMOVE_CARTITEM = 'REMOVE_CARTITEM';
export const UPDATE_CARTITEM_QTY = 'UPDATE_CARTITEM_QTY';
export const CLEAR_CART = 'CLEAR_CART';


export function cartItemsReducer(state: CartItem[] = null, action: ActionWithPayload<CartItem>) {
  switch (action.type) {
   
    case CLEAR_CART:
      var resultArray:Array<CartItem>=null ;
      return resultArray;
    case ADD_CARTITEM:
      if(state == null) {
        state = [];        
      } 
      state = state.concat(action.payload)      
      return state;
    case REMOVE_CARTITEM:
      state = state.filter(i => i.cikkszam != action.payload.cikkszam);            
      return state;
    case UPDATE_CARTITEM_QTY:      
      var cartItem = state.find(i => i.cikkszam == action.payload.cikkszam);
      cartItem.qty = action.payload.qty;           
      var resultArray:Array<CartItem>=[] ;
      state.forEach( i => { 
        resultArray.push(i);
      });
      return resultArray;    
    default:
      return state;
  }
}