import { Component, OnInit, ElementRef, Input } from '@angular/core';
import { FolderUtil } from '../../utils/folder-util';
import { FolderService } from '../../services/folder.service';
import { Filter } from '../../models/filter.model';
import { AppState } from '../../app-state';
import { Store } from '@ngrx/store';
declare var $: any;
var self;

@Component({
  selector: 'folders',
  templateUrl: './folders.component.html'

})
export class FoldersComponent implements OnInit {
  filter: Filter;
  treeReady: boolean;
  HTMLTree: string = '';
  subscribeFolders: any;
  subscribeFilter: any;
  categoryToggle: any;
  folderBranch: string[] = [];
  @Input() eventDetail: any;
  selectedTopFolders: any[] = [];
  selectedSubFolders: any[] = [];

  constructor(
    private elementRef: ElementRef,
    private folderService: FolderService,
    private store: Store<AppState>) {
    self = this;
  }

  ngOnInit() {
    var foldersTreeObj = FolderUtil.convertArrayToTree(this.folderService.folders);

    this.HTMLTree = FolderUtil.createHTMLTree(foldersTreeObj);    
    //this.initFolders(this.folderService.folders);
    this.folderService.removeFolderObservable.subscribe(value => {      
      if (value == 'ALL') {
        this.selectedTopFolders = [];
        this.selectedSubFolders = [];
      } else {
        if (value.length > 2) {
          this.subFolderChangeStatus(value);
        } else {
          this.onClickTopFolderCheckbox(value, false);
        }
      }
    })

    this.subscribeFilter = this.store.select('filter').subscribe(
      filter => {
        if (filter) {
          this.filter = filter;
        }
      },
      error => console.log(error)
    );
  }

  ngAfterViewChecked() {
    var self = this;
    if (!this.treeReady) {
      if ($('.subcategory-link').length > 0 && $('.topfolder').length > 0) {
        setTimeout(function () {
          self.initFolders(self.folderService.folders);
        }, 0);
        this.treeReady = true;
      }
    }
  }

  initFolders(foldersArray) {
    var self = this;
    var categoryToggle = $('.has-children > div > a');
    categoryToggle.on('click', function (e) {   
      console.log($(e.target).parent().parent());   
      if ($(e.target).parent().parent().is('.expanded')) {
        $(e.target).parent().parent().removeClass('expanded');
      } else {        
        categoryToggle.parent().parent().removeClass('expanded');        
        $(this).parent().parent().addClass('expanded');
      }
    });

    var el = self.elementRef.nativeElement.querySelectorAll('.subcategory-link');
    for (let i = 0; i < el.length; i++) {
      el[i].addEventListener('click', self.onClickSubFolder.bind(this));
    }

    var el = self.elementRef.nativeElement.querySelectorAll('.topfolder');
    for (let i = 0; i < el.length; i++) {
      el[i].addEventListener('click', function (e) {
        var tmp = e.target.id.split('_');
        var clickedTopFolderId = tmp[0];
        if ($('#' + e.target.id).hasClass('icon-square')) {
          self.onClickTopFolderCheckbox(clickedTopFolderId, true);
        } else {
          self.onClickTopFolderCheckbox(clickedTopFolderId, false);
        }
      });
    }
  }


  onClickTopFolderCheckbox(folderId: any, status: boolean) {    
    if (status) {
      $('#' + folderId + '_icon').removeClass('icon-square');
      $('#' + folderId + '_icon').addClass('icon-check-square');
    } else {
      $('#' + folderId + '_icon').removeClass('icon-check-square');
      $('#' + folderId + '_icon').addClass('icon-square');
    }

    var childFolders = this.folderService.folders.filter(f => f.parentId == folderId);
    var childFolder: any;
    for (childFolder of childFolders) {
      if (status) {
        $('#' + childFolder.id + "_check").removeClass('hide-check');
        var f = self.selectedSubFolders.find(f => f == childFolder.id);
        if (!f) {
          self.selectedSubFolders.push(childFolder.id);
        }

      } else {
        $('#' + childFolder.id + "_check").addClass('hide-check');
        self.selectedSubFolders = self.selectedSubFolders.filter(f => f != childFolder.id);

      }
    }
    if (status) {
      self.selectedTopFolders.push(folderId);
    } else {
      self.selectedTopFolders = self.selectedTopFolders.filter(f => f != folderId);
    }
    
    this.filterUpdate();
  }

  ngAfterViewInit() {
  }

  onClickSubFolder(e) {
    var tmp = e.target.id.split('_');
    var clickedSubFolderId = tmp[0];
    self.subFolderChangeStatus(clickedSubFolderId);
  }

  subFolderChangeStatus(clickedSubFolderId) {

    var subFolder: any = self.folderService.folders.find(f => f.id == clickedSubFolderId);

    if ($('#' + clickedSubFolderId + "_check").hasClass('hide-check')) {
      $('#' + clickedSubFolderId + "_check").removeClass('hide-check');
      self.selectedSubFolders.push(clickedSubFolderId);
    } else {
      $('#' + clickedSubFolderId + "_check").addClass('hide-check');

      $('#' + subFolder.parentId + '_icon').removeClass('icon-check-square');
      $('#' + subFolder.parentId + '_icon').addClass('icon-square');// a szülő kikapcsolása

      self.selectedSubFolders = self.selectedSubFolders.filter(f => f != clickedSubFolderId);
      self.selectedTopFolders = self.selectedTopFolders.filter(f => f != subFolder.parentId);
    }    
    this.filterUpdate();
  }


  filterUpdate() {
    this.filter.subfolders = self.selectedSubFolders;
    this.filter.topfolders = self.selectedTopFolders;
    //delete this.filter.minPrice;
    //delete this.filter.maxPrice;
    self.store.dispatch({ type: 'FILTER_UPDATED', payload: this.filter });
  }










/*


  onClickFolder(e) {    
    if ($('#' + e.target.id + "_check").hasClass('hide-check')) {
      $('#' + e.target.id + "_check").removeClass('hide-check');
    } else {

      $('#' + e.target.id + "_check").addClass('hide-check');
    }
   
  }

  getParentFolder(parentId: string, self) {
    var parentFolder = this.folderService.folders.find(f => f.id == parentId);
    self.folderBranch.push(parentFolder.id);
    if (parentFolder.parentId != '') {
      self.getParentFolder(parentFolder.parentId);
    }
  }
*/
  ngOnDestroy(): void {
    if (this.subscribeFolders) {
      this.subscribeFolders.unsubscribe();
      this.subscribeFilter.unsubscribe();
    }
  }
}
