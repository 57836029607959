import { Component, OnInit,ViewChild } from '@angular/core';
import {OwlCarousel} from 'ngx-owl-carousel';

declare var $:any;

@Component({
  selector: 'hero-slider',
  templateUrl: './hero-slider.component.html',
})
export class HeroSliderComponent implements OnInit {
  @ViewChild('owlElement', { static:true}) owlElement: OwlCarousel
  owlReady: boolean;
  
  ngAfterViewInit() {
     
  }
 
  ngOnInit() {
 
  }

  ngAfterViewChecked() {
    if(!this.owlReady) {       
      if(this.owlElement) {
        this.owlReady = true;        
      }
    }
  }

  owlMouseLeave() {    
    if(this.owlReady) { 
      this.owlElement.next();
    }
  }
}
