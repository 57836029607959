import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { HttpErrorResponse } from '@angular/common/http';
import { AppState } from '../app-state';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { PhotoSwipeComponent } from '../components/photo-swipe/photo-swipe.component';



@Injectable()
export class ConfigService {
  shopConfig: any;
  photoswipe: any;
  //configObservable = new Subject<any>();
  constructor(
    private httpClient: HttpClient,
    private store: Store<AppState>,
    ) {
      
     this.getShopConfig().subscribe(
      config => {        
        this.shopConfig = config;
        this.store.dispatch({ type: 'CONFIG_UPDATED', payload: config });
        
      },
      (err: HttpErrorResponse) => {

      }
     );
    
  }
  
  getShopConfig(): any {
    var t = new Date().getTime();
    return this.httpClient.get<any>('assets/json/shop_config.json?'+t);
  }

  setPhotoswipeComp(psw: PhotoSwipeComponent) {
    this.photoswipe = psw;
  }

  getPhotoswipeComp() {
    return this.photoswipe;;
  }


  private extractData(res: Response) {
    let body = res.json();
    return body || {};
  }

  private handleError(error: any): Promise<any> {
    console.log('An error occurred', error); // for demo purposes only
    return Promise.reject(error.message || error);
  }
}
