import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params, NavigationExtras } from '@angular/router';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { AppState } from '../../../app-state';
import { User } from '../../../models/user.model';
import { Helpdesk } from '../../../models/helpdesk.model';
import { UserService } from '../../../services/user.service';
import { OrderService } from '../../../services/order.service';
import { NgbActiveModal, NgbModal,NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { HelpdeskModal } from '../../../components/helpdesk/helpdesk.modal';

declare var iziToast: any;
declare var $: any;
declare var velocity: any;

@Component({
  templateUrl: './helpdesk-page.html',
  //styleUrls: ['./layout.component.css']
})
export class HelpdeskPage implements OnInit {
  subscribeHelpdesks: any;
  subscribeUser: any;
  helpdesks: Helpdesk[] = [];
  visibledHelpdesks: Helpdesk[] = [];
  user: User;
  currentPage: number = 1;
  pageSize: number = 6;
  listItemsNum: number = 0;
  collectionSize: number = 0;
  modalRef : NgbModalRef;
  constructor(
    private modalService: NgbModal,
    private router: Router,
    private userService: UserService,
    private orderService: OrderService,
    private fb: FormBuilder,
    private store: Store<AppState>) {
  }

  ngOnInit() {
    var $scrollTop = $('.scroll-to-top-btn');
    $scrollTop.removeClass('visible');
    this.subscribeUser = this.store.select('user').subscribe(
      user => {
        if (user != null) {
          this.user = user;
        }
      },
      error => console.log(error)
    );
    this.subscribeHelpdesks = this.store.select('helpdesks').subscribe(
      helpdesks => {
        if (helpdesks) {
          this.helpdesks = helpdesks;
          this.collectionSize = this.helpdesks.length;
          this.setVisibledHelpdesks();
          console.log(this.helpdesks);
        }
      },
      error => console.log(error)
    );
    this.userService.getHelpdesks();
  }

  /*onClickOrder(order: any) {
    let navigationExtras: NavigationExtras = {
      queryParams: { 'id': order.id }
    };
    this.router.navigate([{ outlets: { popup2: 'order' } }], navigationExtras);

  }*/

  onClickMenuItem(menuItemId: string) {
    let navigationExtras: NavigationExtras = {
      queryParams: {}
    };
    this.router.navigate([{ outlets: { popup: menuItemId } }], navigationExtras);

  }

  onPageChange(page: number) {
    //this.scrollTop('.container');
    this.currentPage = page;
    this.setVisibledHelpdesks();
    $('.popup-container-inner').scrollTop(0);
  }

  setVisibledHelpdesks() {
    this.visibledHelpdesks = this.helpdesks.slice((this.currentPage - 1) * this.pageSize, (this.currentPage - 1) * this.pageSize + this.pageSize);

  }

  onClickAdd() {
  
    this.modalService.open(HelpdeskModal, { size: 'lg' });
    
  }

  onClickAddAnswer(helpdesk) {  
    this.modalRef = this.modalService.open(HelpdeskModal, { size: 'lg' });
    this.modalRef.componentInstance.editedHelpdesk = helpdesk;  
  }

  
  /*scrollTop(target) {
    $(target).velocity( 'scroll', {
      container: $( '.outerMainContainer' ),				
      duration: 1200,
      easing: 'easeOutExpo',
      mobileHA: false
    } );    
  }*/

  ngOnDestroy(): void {
    this.subscribeHelpdesks.unsubscribe();
    this.subscribeUser.unsubscribe();
  }
}
