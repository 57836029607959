import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params, NavigationExtras } from '@angular/router';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { AppState } from '../../app-state';
import { Product } from '../../models/product.model';
import { Filter } from '../../models/filter.model';
import { Order } from '../../models/order.model';
import { ProductService } from '../../services/product.service';
import { CartService } from '../../services/cart.service';
import { FavoritesService } from '../../services/favorites.service';
import { EMAIL_REGEX, PASSWORD_REGEX, PRODUCT_IMAGES_DIR } from '../../utils/constants';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { User } from 'src/app/models/user.model';
import { NgxIzitoastService } from 'ngx-izitoast';
import { Utils } from 'src/app/utils/utils';
import { GoogleAnalyticsEventsService } from '../../services/google-analytics.service';


@Component({
  selector: 'products',
  templateUrl: './products.component.html'

})
export class ProductsComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI;
  subscribeProducts: any;
  subscribeFavorites: any;
  subscribeOrder: any;
  pageSize: number = 12;
  allProducts: Product[] = [];
  filteredProducts: Product[] = [];
  visibledProducts: Product[] = [];
  favorites: string[] = [];
  filter: Filter = new Filter();
  subscribeFilter: any;
  subscribeUser: any;
  worker: Worker;
  user: User = new User();
  order: Order = new Order();
  sorting: string = 'price_inc';
  DEFAULT_SORTING: string = 'price_inc';
  product_images_url: string = PRODUCT_IMAGES_DIR;

  constructor(    
    private googleAnalyticsEventsService: GoogleAnalyticsEventsService,
    public iziToast: NgxIzitoastService,
    private router: Router,
    private productService: ProductService,
    private cartService: CartService,
    private favoritesService: FavoritesService,
    private store: Store<AppState>) {

    this.worker = new Worker('../../app.worker', { type: 'module' });
    this.worker.onmessage = ({ data }) => {
      this.filteredProducts = data.filteredProducts;      
      this.productService.minPrice.next(data.minPrice);      
      this.productService.maxPrice.next(data.maxPrice);      
      this.setVisibledProductsReset();
      this.setProductIsFavorite();
    };
  }

  ngOnInit() {
    console.log(this.filter);
    this.sorting = this.DEFAULT_SORTING;
    this.subscribeUser = this.store.select('user').subscribe(
      user => {
        if (user != null && user.id > 0) {
          this.user = user;
        } else {
          this.user = null;
        }
      },
      error => console.log(error)
    );

    this.subscribeFilter = this.store.select('filter').subscribe(
      filter => {
        if (filter) {                   
          this.filter = filter;
          console.log(this.filter);
          console.log(this.filter);
          if (!this.filterIsEmpty()) {
            this.doFilter();
          } else {
            this.filteredProducts = [];
            this.setVisibledProductsReset();
          }
        } else {
        }
      },
      error => console.log(error)
    );

    this.subscribeOrder = this.store.select('order').subscribe(
      order => {
        if (order) {
          this.order = order;
        }
      },
      error => console.log(error)
    );

    this.subscribeFavorites = this.store.select('favorites').subscribe(
      favorites => {
        if (favorites) {
          this.favorites = favorites;
        } else {
          this.favorites = [];
        }
        this.setProductIsFavorite();
      },
      error => console.log(error)
    );


    this.subscribeProducts = this.store.select('products').subscribe(
      products => {
        if (products && products.length > 0) {
          this.allProducts = products;
          if (!this.filterIsEmpty()) {
            this.filteredProducts = products;
            this.doFilter();
            this.setVisibledProductsReset();
            this.sorting = this.DEFAULT_SORTING;
          }
        }
      },
      error => console.log(error)
    );
  }

  setProductIsFavorite() {
    for (var product of this.filteredProducts) {
      var isFavorite = this.favorites.find(f => f == product.cikkszam);
      if (isFavorite) {
        product.isFavorite = true;
        console.log('set favorite: true');
      } else {
        product.isFavorite = false;
      }
    }
  }

  filterIsEmpty() {
    var empty = true;
    if (this.filter.alergenek.length > 0 || this.filter.gyartok.length > 0 ||
      this.filter.alergenek.length > 0 || this.filter.markak.length > 0 || this.filter.subfolders.length > 0
      || this.filter.topfolders.length > 0 || this.filter.ujsagok.length > 0 || this.filter.keyword.length > 0) {
      empty = false;
    }
    return empty;
  }

  onScroll() {

    if (this.visibledProducts.length < this.filteredProducts.length) {
      this.visibledProducts = this.filteredProducts.slice(0, this.visibledProducts.length + this.pageSize);
    }
  }

  setVisibledProductsReset() {
    this.visibledProducts = this.filteredProducts.slice(0, this.pageSize);
  }

  onClickAddToCart(product) {
    var step = Utils.getStep(product);
    this.cartService.addCartItemPreCheck(this.user, product, step);
  }

  doFilter() {  
    var data = { allProducts: this.allProducts, filter: this.filter };
    this.worker.postMessage(data);
  }

  orderByChanged() {
    var sortingSplit = this.sorting.split('_');
    if (sortingSplit[0] == 'price') {
      this.doSortByPrice(sortingSplit[1] == 'inc');
    }
    if (sortingSplit[0] == 'name') {
      this.doSortByName(sortingSplit[1] == 'inc');
    }
    this.store.dispatch({ type: 'FILTER_UPDATED', payload: this.filter });
  }

  doSortByPrice(inc: boolean) {
    this.allProducts.sort((obj1: Product, obj2) => {
      var obj1Price = obj1.wPriceNetto;
      var obj2Price = obj2.wPriceNetto;

      if (!inc) {
        if (obj1Price < obj2Price) {
          return 1;
        }

        if (obj1Price > obj2Price) {
          return -1;
        }
      }
      if (inc) {
        if (obj1Price > obj2Price) {
          return 1;
        }

        if (obj1Price < obj2Price) {
          return -1;
        }
      }
      return 0;
    });

  }

  doSortByName(inc: boolean) {
    this.allProducts.sort((obj1: Product, obj2) => {
      var obj1Name = obj1.nev;
      var obj2Name = obj2.nev;

      if (!inc) {
        if (obj1Name.localeCompare(obj2Name, 'hu') == -1) {
          return 1;
        }
        if (obj1Name.localeCompare(obj2Name, 'hu') == 1) {
          return -1;
        }
      }
      if (inc) {
        if (obj1Name.localeCompare(obj2Name, 'hu') == 1) {
          return 1;
        }
        if (obj1Name.localeCompare(obj2Name, 'hu') == -1) {
          return -1;
        }
      }
      return 0;
    });

  }

  onClickProduct(product: any) {
    let navigationExtras: NavigationExtras = {
      queryParams: { 'id': product.cikkszam, 'n': product.nev.replace(/ /g, '_') }
    };
    this.router.navigate([{ outlets: { popup: 'product' } }], navigationExtras);

  }

  onClickAddToFavorites(product) {
    event.stopPropagation();
    this.favoritesService.addFavoritesItem(product.cikkszam);
    this.iziToast.show({ title: '"' + product.nev + '"', message: "hozzáadva kedvencekhez.", position: 'topCenter', timeout: 1500, close: false });
  }

  onClickRemoveFavorite(product) {
    event.stopPropagation();
    this.favoritesService.removeFavoritesItem(product.cikkszam);
    this.iziToast.show({ title: '"' + product.nev + '"', message: "törölve a kedvencek közül.", position: 'topCenter', timeout: 1500, close: false });
  }

  ngOnDestroy(): void {
    this.subscribeProducts.unsubscribe();
    this.subscribeFilter.unsubscribe();
    this.subscribeUser.unsubscribe();
  }
}
