import { Component, ViewChild, ElementRef, Input } from '@angular/core';

// Import PhotoSwipe
import PhotoSwipe           from 'photoswipe';
import PhotoSwipeUI_Default from 'photoswipe/dist/photoswipe-ui-default';

// Image Interface


@Component({
    selector   : 'app-photo-swipe',
    templateUrl: './photo-swipe.component.html',
    styleUrls  : ['./photo-swipe.component.css']
})
export class PhotoSwipeComponent
{
    @ViewChild('photoSwipe', {static: true}) photoSwipe: ElementRef;

    @Input() images: IImage[] = [];

    // ========================================================================
    constructor() { }

    // ========================================================================
    openGallery(i: number, images?: IImage[])
    {
        // Build gallery images array
        images = images || this.images;

        // define options (if needed)
        const options = {
            closeOnScroll: false,
            // optionName: 'option value'
            // for example:
            index: i // start at first slide
        };

        // Initializes and opens PhotoSwipe
        const gallery = new PhotoSwipe(this.photoSwipe.nativeElement, PhotoSwipeUI_Default, images, options);
        gallery.listen('imageLoadComplete', function(index, item) {
            
            //var linkEl = item.el.children[0];
                console.log(item.el);
/*            var img = item.container.children[0];
            //if (!linkEl.getAttribute('data-size')) {
             //   alert('!');    
              linkEl.setAttribute('data-size', img.naturalWidth + 'x' + img.naturalHeight);
              item.w = img.naturalWidth;
              item.h = img.naturalHeight;
              gallery.invalidateCurrItems();
              gallery.updateSize(true);*/
            //}
          });
        gallery.init();
        
    }
    // ========================================================================
}

export interface IImage
{
    src: string,
    w: number,
    h: number,
}