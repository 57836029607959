import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute, Params, NavigationExtras } from '@angular/router';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { AppState } from '../../../app-state';
import { User } from '../../../models/user.model';
import { UserService } from '../../../services/user.service';
import { EMAIL_REGEX, PASSWORD_REGEX, CIM_MAX_LENGTH, HELYSEG_MAX_LENGTH, NEV1_MAX_LENGTH, TEL1_MAX_LENGTH } from '../../../utils/constants';
import { PasswordValidation } from '../../../utils/password-validation';
import { HttpErrorResponse } from '@angular/common/http';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ShippingAddress } from '../../../models/shipping-address.model';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { NgxIzitoastService } from 'ngx-izitoast';
declare var $: any;

@Component({
  templateUrl: './shipping-address.page.html',
  
})
export class ShippingAddressPage implements OnInit {
  shippingAddressForm: FormGroup;
  //shipAddrs: ShippingAddress[] = [];
  subscribeUser: any;
  subscribeShopConfig: any;
  user: User = new User();
  submitClicked: boolean;
  modalTitle: string;
  shopConfig: any;
  helysegMaxLength: number = HELYSEG_MAX_LENGTH;
  cimMaxLength: number = CIM_MAX_LENGTH;
  nev1MaxLength: number = NEV1_MAX_LENGTH;
  tel1MaxLength: number = TEL1_MAX_LENGTH;
  zipNumberMask = [/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
  visibleDeleteButton: boolean;

  @ViewChild('modal', {static: true}) modal: ElementRef;
  @BlockUI() blockUI: NgBlockUI;
  constructor(
    public iziToast: NgxIzitoastService,
    private modalService: NgbModal,
    private router: Router,
    private userService: UserService,
    private fb: FormBuilder,
    private store: Store<AppState>) {

    this.createForm();
  }

  ngOnInit() {
   // var $scrollTop = $('.scroll-to-top-btn');
   // $scrollTop.removeClass('visible');
    this.subscribeUser = this.store.select('user').subscribe(
      user => {
        if (user != null && user.id > 0) {
          this.user = user;
          this.initForm();
          //this.userService.getShippingAddresses();
        } else {
          this.user = null;
        }
      },
      error => console.log(error)
    );
    
    this.subscribeShopConfig = this.store.select('shopConfig').subscribe(
      shopConfig => {
        if (shopConfig) {
          this.shopConfig = shopConfig;
        }
      },
      error => console.log(error)
    );
  }

  onClickSave() {
    this.submitClicked = true;    
    if (this.shippingAddressForm.status == 'VALID') {
      this.shippingAddressForm.controls['nev1'].setValue(this.shippingAddressForm.controls['nev'].value.substring(0, 40)); 
      this.shippingAddressForm.controls['nev1a'].setValue(this.shippingAddressForm.controls['nev'].value.substring(40, 80)); 
      
      this.userService.updateShippingAddress(this.shippingAddressForm.value).subscribe(
        user => {
          this.store.dispatch({ type: 'USER_UPDATED', payload: user });
          this.shippingAddressForm.markAsPristine();
          this.iziToast.show({title: "Adatok elmentve!", position:'topCenter', timeout: 2000});          
        },
        (err: HttpErrorResponse) => {

        }
      );
    } 
  }

/*
  onClickSave() {
    this.submitClicked = true;    
    if (this.addressForm.status == 'VALID') {
      this.blockUI.start();
      this.userService.updateShippingAddress(this.addressForm.value).subscribe(
        shipAddrs => {
          this.blockUI.stop();
          this.iziToast.show({title: "Adatok elmentve!", position:'topCenter', timeout: 2000});          
          this.clearForm();
          this.modalService.dismissAll();
          this.store.dispatch({ type: 'SHIPPING_ADDRESSES_UPDATED', payload: shipAddrs });

        },
        (err: HttpErrorResponse) => {

        }
      );
    }
  }

  onChangeCopyAddress(e) {
    
    if (this.addressForm.controls['copyAddress'].value) {
      this.addressForm.controls['name'].setValue(this.user.nev1);
      this.addressForm.controls['zip'].setValue(this.user.irsz);
      this.addressForm.controls['city'].setValue(this.user.helyseg);
      this.addressForm.controls['address'].setValue(this.user.cim);
      this.addressForm.controls['phone'].setValue(this.user.tel1);
    } 
    else {
      this.addressForm.controls['name'].setValue('');
      this.addressForm.controls['zip'].setValue('');
      this.addressForm.controls['city'].setValue('');
      this.addressForm.controls['address'].setValue('');
      this.addressForm.controls['phone'].setValue('');
      this.addressForm.controls['copyAddress'].setValue(false);
    }
  }

  /*onClickAddNew() {
    this.visibleDeleteButton = false;
    this.clearForm();
    this.modalTitle = 'Szállítási cím felvitele';
    this.modalService.open(this.modal).result.then((result) => {
      if (result == 'yes') {
        this.onClickSave();
      }
    }, (reason) => {

    });
  }

  onClickEdit(shipAddr: ShippingAddress) {
    this.visibleDeleteButton = true;
    this.initForm(shipAddr);
    this.modalTitle = 'Szállítási cím módosítása';
    this.modalService.open(this.modal).result.then((result) => {
      if (result == 'yes') {
        this.onClickSave();
      }
      if (result == 'delete') {
        var c = confirm('Biztosan törölni szeretné a címet?');
        if (c == true) {
          this.deleteAddress(shipAddr.id);
        }
      }
    }, (reason) => {

    });
  }

  deleteAddress(id: number) {
    this.blockUI.start();
    this.userService.deleteShippingAddress(id).subscribe(
      shipAddrs => {
        this.blockUI.stop();
        this.clearForm();
        this.store.dispatch({ type: 'SHIPPING_ADDRESSES_UPDATED', payload: shipAddrs });
      },
      (err: HttpErrorResponse) => {
      }
    );
  }

  clearForm() {
    this.submitClicked = false;
    this.addressForm.reset();
    this.addressForm.controls['copyAddress'].setValue(false);
    this.addressForm.markAsPristine();
    this.addressForm.markAsUntouched();
  }
*/
  createForm() {
    console.log('createForm!!!!!!!!!!');
    this.shippingAddressForm = this.fb.group({      
      nev: new FormControl('', [Validators.required]),
      nev1: new FormControl('', []),
      nev1a: new FormControl('', []),
      helyseg: new FormControl('', [Validators.required]),
      irsz: new FormControl('', [Validators.required]),
      cim: new FormControl('', [Validators.required])           
    }
    );
  }

  onClickMenuItem(menuItemId: string) {
    let navigationExtras: NavigationExtras = {
      queryParams: {}
    };
    this.router.navigate([{ outlets: { popup: menuItemId } }], navigationExtras);
  }

  initForm() {    
    this.shippingAddressForm.controls['nev'].setValue(this.user.nev1 + this.user.nev1a);
    this.shippingAddressForm.controls['helyseg'].setValue(this.user.helyseg);
    this.shippingAddressForm.controls['irsz'].setValue(this.user.irsz);
    this.shippingAddressForm.controls['cim'].setValue(this.user.cim);
    
  }
  /*initForm(shipAddr: ShippingAddress) {
    this.addressForm.controls['id'].setValue(shipAddr.id);
    this.addressForm.controls['name'].setValue(shipAddr.name);
    this.addressForm.controls['city'].setValue(shipAddr.city);
    this.addressForm.controls['zip'].setValue(shipAddr.zip);
    this.addressForm.controls['address'].setValue(shipAddr.address);
    this.addressForm.controls['phone'].setValue(shipAddr.phone);
    this.addressForm.controls['copyAddress'].setValue(!shipAddr.copyAddress ? false : true);
  }*/

  ngOnDestroy(): void {
    this.subscribeUser.unsubscribe();
    this.subscribeShopConfig.unsubscribe();
  }
}
