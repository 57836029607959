import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Order } from '../models/order.model';
import { Observable, Subject } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { HttpErrorResponse } from '@angular/common/http';
import { AppState } from '../app-state';
import { ProductService } from './product.service';
import { API_URL } from '../utils/constants';
import { EMAIL_REGEX, PASSWORD_REGEX } from '../utils/constants';

@Injectable()
export class OrderService {
  subscribeOrder: any;
  preOrderTeljDatum: any;
  constructor(
    private httpClient: HttpClient,
    private productService: ProductService,
    private store: Store<AppState>
  ) {

    this.store.select('user').subscribe(
      user => {         
        if(user!= null && user.id > 0) {           
          this.resumeFromSavedOrder(JSON.parse(localStorage.getItem('order')));
        } else {
          this.store.dispatch({ type: 'ORDER_UPDATED', payload: null });
        }
      },
      error => console.log(error)
    );

    this.subscribeOrder = this.store.select('order').subscribe(
      _order => {
       console.log(_order, '=====');
        var savedOrder = JSON.parse(localStorage.getItem('order'));
        if(!_order && savedOrder && localStorage.getItem('token')) {          
          this.resumeFromSavedOrder(savedOrder);
        }
        if(this.preOrderTeljDatum && _order && this.preOrderTeljDatum != _order.hatido) {          
          if(localStorage.getItem('token')) { 
            this.productService.getProducts('');
          } else {
            this.productService.getProducts('/public');
          }          
        }
        if(_order) {
           this.preOrderTeljDatum = _order.hatido;
        }
      },
      error => console.log(error)
    );
  }
  
  resumeFromSavedOrder(savedOrder) {
    if(savedOrder) { 
      var order = new Order();
      order.hatido = savedOrder.hatido;
      order.szallmod = savedOrder.szallmod;          
      order.fizmod = savedOrder.fizmod;                            
      this.store.dispatch({ type: 'ORDER_UPDATED', payload: order });
    }
  }

  postOrder(order: Order): Observable<any> {
    return this.httpClient.post<any>(API_URL + "order", order);
  }

  getOrders() {
    
    this.httpClient.get<Order[]>(API_URL + 'order')
      .subscribe(
        orders => {          
          
          var order: any; 
          for (order of orders) {                    
            if (order.orderLogList.length > 1) {
              this.doSortDesc(order.orderLogList);
            }                        
          }            
          this.store.dispatch({ type: 'ORDERS_UPDATED', payload: orders });
          console.log(orders);  
        },
        (err: HttpErrorResponse) => {

        }
      );
  }

  public doSortDesc(logs: any[]) {
    logs.sort((obj1: any, obj2) => {     
      if (obj1.id < obj2.id) {
        return 1;
      }

      if (obj1.id > obj2.id) {
        return -1;
      }  
      return 0;
    });
  }

  getOrder(orderId: any) {    
    return this.httpClient.get<Order>(API_URL + 'order/'+orderId);
  }
/*
  postOrder(order: Order): Observable<any> {
    return this.httpClient.post<any>(API_URL + "order", order);
  }


  public doSortDesc(logs: any[]) {
    logs.sort((obj1: any, obj2) => {     
      if (obj1.id < obj2.id) {
        return 1;
      }

      if (obj1.id > obj2.id) {
        return -1;
      }  
      return 0;
    });
  }


  private extractData(res: Response) {
    let body = res.json();
    return body || {};
  }

  private handleError(error: any): Promise<any> {
    console.log('An error occurred', error); // for demo purposes only
    return Promise.reject(error.message || error);
  }*/
}
