import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { Store } from '@ngrx/store';
import { AppState } from '../app-state';
import { Page } from '../models/page.model';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

@Injectable()
export class FileService {

  constructor(
    private httpClient: HttpClient,
    private store: Store<AppState>) {          
  }

  getStaticFile(pageId: string) {        
    var httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'text/html, application/xhtml+xml, */*',
        'Content-Type': 'application/x-www-form-urlencoded'
      }),
      responseType: 'text'
    };
  
    return this.httpClient.get(environment.API_URL + "static/"+pageId, {responseType: 'text'});
  }

  getPage(pageId: any):Observable<Page> {        
    return this.httpClient.get<Page>(environment.API_URL + "static/"+pageId);
  }

  savePage(page: Page) {
    return this.httpClient.put<Page>(environment.API_URL + "static/"+page.id, page);
  }

  /*deleteAttachedFile(productId: number, filename: string): Observable<Product> {
    var attachedFile = new AttachedFile();
    attachedFile.name = filename;
    return this.httpClient.put<any>(environment.API_URL + "upload/delete/" + productId, attachedFile);
  }*/


  private extractData(res: Response) {
    let body = res.json();
    return body || {};
  }

  private handleError(error: any): Promise<any> {
    console.log('An error occurred', error); // for demo purposes only
    return Promise.reject(error.message || error);
  }
}
