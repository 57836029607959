import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params, NavigationExtras } from '@angular/router';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';

import { PasswordValidation } from '../../../utils/password-validation';
import {
  NEV_MAX_LENGTH, NEV1_MAX_LENGTH, PASSWORD_REGEX, EMAIL_REGEX, EMAIL_MAX_LENGTH, CONTACT_PERSON_MAX_LENGTH, TEL1_MAX_LENGTH,
  HELYSEG_MAX_LENGTH, CIM_MAX_LENGTH, PASSWORD_MAX_LENGTH, TAX_REGEX
} from '../../../utils/constants';

import { User } from '../../../models/user.model';
import { TokenInterceptor } from '../../../token.interceptor';
import { UserService } from '../../../services/user.service';
import { HttpErrorResponse } from '@angular/common/http';
import { USER_UPDATED } from '../../../reducers/user';
import { AppState } from '../../../app-state';
import { Store } from '@ngrx/store';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { NgxIzitoastService } from 'ngx-izitoast';

@Component({

  templateUrl: './recovery-password.html',

})
export class RecoveryPasswordPage {
  form: FormGroup;
  submitClicked: boolean;
  sendSuccess: boolean;
  emailMaxLength: number = EMAIL_MAX_LENGTH;
  @BlockUI() blockUI: NgBlockUI;

  constructor(
    public iziToast: NgxIzitoastService,
    private router: Router,
    private userService: UserService,
    private fb: FormBuilder,
    private store: Store<AppState>) {

    this.createForm();

  }

  ngOnInit() {
  }

  onClickResetPwd() {
    this.submitClicked = true;
    if (this.form.valid) {
      this.blockUI.start();
      this.userService.passwordRecovery(this.form.controls['email'].value)
        .subscribe(
          (response: any) => {
            this.submitClicked = true;         
            this.sendSuccess   = true;
            //this.sentNewPasswordToast();
            this.blockUI.stop();
          },
          (err: HttpErrorResponse) => {
            this.blockUI.stop();
            if (err.error.email) {
              this.form.controls['email'].setErrors({ 'none_existed': true });
            }
            
          }
        );
    }
    //this.lostPassword = false;
  }



  createForm() {
    this.form = this.fb.group({
      email: new FormControl('', [
        Validators.maxLength(EMAIL_MAX_LENGTH),
        Validators.required,
        Validators.pattern(EMAIL_REGEX)])
    }

    );
  }

  /*createForm() {
    this.signupForm = this.fb.group({
      nev: new FormControl('', [Validators.required]),
      nev1: new FormControl('', []),
      nev1a: new FormControl('', []),
      tel1: new FormControl('', [Validators.required]),
      contactPerson: new FormControl('', [Validators.required]),
      aiasz: new FormControl('', [Validators.required, Validators.pattern(TAX_REGEX)]),
      password: new FormControl('', [
        Validators.required,
        Validators.pattern(PASSWORD_REGEX)
      ]),
      confirmPassword: new FormControl('', [
        Validators.required,
        Validators.pattern(PASSWORD_REGEX)
      ]),
      email: new FormControl('', [
        Validators.maxLength(this.emailMaxLength),
        Validators.required,
        Validators.pattern(EMAIL_REGEX)]),
      irsz: new FormControl('', [Validators.required]),
      helyseg: new FormControl('', [Validators.required]),
      cim: new FormControl('', [Validators.required])
    },
      {
        validator: PasswordValidation.MatchPassword // your validation method
      }
    );
  }

  createSignInForm() {
    this.signinForm = this.fb.group({
      email: new FormControl('', [
        Validators.required]),
      password: new FormControl('', [
        Validators.required        
      ]),

    });
  }*/
}
