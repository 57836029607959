import { Component, OnInit, ViewChild, ViewContainerRef, ComponentFactoryResolver, ComponentFactory, ComponentRef } from '@angular/core';
import { AppState } from '../../app-state';
import { CartService } from '../../services/cart.service';
import { FormControl, FormArray, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute, Params, NavigationExtras, NavigationEnd } from '@angular/router';
import { CartItem } from '../../models/cartitem.model';
import { Product } from '../../models/product.model';
import { Utils } from '../../utils/utils';
import { Store } from '@ngrx/store';
import { NgxIzitoastService } from 'ngx-izitoast';
import { PRODUCT_IMAGES_DIR } from '../../utils/constants';

import { FoldersComponent } from '../../components/folders/folders.component';
import { Folder } from '../../models/folder.model';
declare var $: any;

@Component({
  templateUrl: './cart-page.html',
  //styleUrls: ['./layout.component.css']
})
export class CartPage implements OnInit {
  form: FormGroup;
  formItems: FormArray;
  subscribeCartItems: any;
  cartItems: CartItem[] = [];
  qty: any[] = [];
  product_images_url: string = PRODUCT_IMAGES_DIR;

  constructor(
    public iziToast: NgxIzitoastService,
    private router: Router,
    private formBuilder: FormBuilder,
    public cartService: CartService,
    private store: Store<AppState>) {
  }

  ngOnInit() {
    var $scrollTop = $('.scroll-to-top-btn');
    $scrollTop.removeClass('visible');    
    this.subscribeCartItems = this.store.select('cartItems').subscribe(
      cartItems => {
        
        //if (cartItems && cartItems.length > this.cartItems.length) {
        if (cartItems) {
          this.cartItems = cartItems;
          this.form = this.formBuilder.group({
            items: this.formBuilder.array([])
          });
          this.addItems();
        }
        if (cartItems == null) {
          this.cartItems = [];
          this.form = this.formBuilder.group({
            items: this.formBuilder.array([])
          });
        }
      },
      error => console.log(error)
    );
  }
  

  addItems(): void {
    
    this.formItems = this.form.get('items') as FormArray;
    for (var cartItem of this.cartItems) {
      this.formItems.push(this.createItem(cartItem));
    }
  }

  createItem(cartItem: CartItem): FormGroup {
    var qty = cartItem.qty;
    if(cartItem.dbmeny &&  cartItem.dbmeny != 1) {
      qty = Math.round(qty / cartItem.dbmeny);      
    }
    var step = Utils.getStep(cartItem);

    return this.formBuilder.group({
      cikkszam: cartItem.cikkszam,
      productId: cartItem.id,
      qty: new FormControl(qty, [Validators.required]),
      nev: cartItem.nev,
      defImage: cartItem.defImage,
      netto: cartItem.netto,
      eredNettoListaar: cartItem.eredNettoListaar,
      akcios: cartItem.akcios,
      me: cartItem.me,
      wPrice: cartItem.wPrice,
      kedvszaz: cartItem.kedvszaz,
      akedvszaz: cartItem.akedvszaz,
      akcioazon: cartItem.akcioazon,
      dbmeny: cartItem.dbmeny,
      keselt: cartItem.keselt,
      step: step
      /*unitName: cartItem.unitName,
      partnerPrice: cartItem.partnerPrice,
      price: cartItem.price,
      discount: cartItem.discount,
      inStock: cartItem.inStock*/
    });
  }

  
    onRefreshCart(item) {      
      var cartItem = new CartItem();
      if(item.qty.value && item.qty.value % item.step.value == 0) {

        if(item.dbmeny.value && item.dbmeny.value != 1) {
          console.log(item.qty.value +'*'+item.dbmeny.value);
          cartItem.qty = item.qty.value * item.dbmeny.value;
        } else { 
          cartItem.qty = item.qty.value;
        }
        cartItem.cikkszam = item.cikkszam.value;
        this.store.dispatch({ type: 'UPDATE_CARTITEM_QTY', payload: cartItem });    

      } else {
        this.iziToast.show({title: 'Figyelem!' , message: "Nem megengedett mennyiség.", position:'topCenter', timeout: 2000, close: false, color:'red'});
        var citem = this.cartItems.find(i=>i.cikkszam==item.cikkszam.value);
        if(item.dbmeny.value && item.dbmeny.value != 1) {
          item.qty.setValue(citem.qty / item.dbmeny.value);
        } else { 
          item.qty.setValue(citem.qty);
        }
      }
    }

    removeCartItem(item) {    
      var cartItem = new CartItem();    
      cartItem.cikkszam = item.cikkszam.value;
      this.cartService.removeCartItem(cartItem);    
    }
  

    onClickCheckout() {
      let navigationExtras: NavigationExtras = {
        queryParams: {  }
      };
     this.router.navigate([{ outlets: { popup: 'checkout' } }], navigationExtras);
    }

    onClickCartItem(cikkszam: any) {
      let navigationExtras: NavigationExtras = {
        queryParams: { 'id': cikkszam }
      };
      this.router.navigate([{ outlets: { popup: 'product' } }], navigationExtras);
    }
  /*
    onClickCartItem(item) {
      let navigationExtras: NavigationExtras = {
        queryParams: { 'id': item.productId.value }
      };
     this.router.navigate([{ outlets: { popup: 'product' } }], navigationExtras);
    }
  
    
  
    
  
    
  
   
    
    addItem(): void {
      this.formItems = this.form.get('items') as FormArray;    
    }
  
    
  
    
  */
  onClearCart(item) {
    this.cartService.clearCart();
  }

  onClickBack() {
    this.router.navigate([{ outlets: { popup: null } }]);
  }

  getControls() {
    return (<FormArray>this.form.controls['items']).controls;
  }

  ngOnDestroy(): void {
    this.subscribeCartItems.unsubscribe();    
  }
}
