import { Component, OnInit, Injectable, ViewChild, ElementRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { DatePipe } from '@angular/common';
import { Utils } from '../../utils/utils';
import { I18n, CustomDatepickerI18n } from '../../utils/cal-locale';
import { AppState } from '../../app-state';
import { NgbActiveModal, NgbModal, NgbDate, NgbDatepickerI18n, NgbDatepicker, NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { User } from 'src/app/models/user.model';
import { Order } from 'src/app/models/order.model';
import { UserService } from 'src/app/services/user.service';
import { CartService } from 'src/app/services/cart.service';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgxIzitoastService } from 'ngx-izitoast';
import { SZALLMOD_HAZHOZSZALLITAS, SZALLMOD_SZEMELYESATVETEL, SZALLDAYS_MIN, SZALLDAYS_MAX } from '../../utils/constants';
import { HttpErrorResponse } from '@angular/common/http';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Helpdesk } from 'src/app/models/helpdesk.model';


@Component({
  selector: 'helpdesk-modal',
  templateUrl: './helpdesk.modal.html',
  providers: [I18n, { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n }] // define custom NgbDatepickerI18n provider
})
export class HelpdeskModal implements OnInit {
  helpdeskForm: FormGroup;
  submitClicked: boolean;
  @BlockUI() blockUI: NgBlockUI;
  editedHelpdesk: Helpdesk;
  /*teljesitesForm: FormGroup;
  isDisabled: any;
  user: User;
  subscribeUser: any;
  subscribeOrder: any;
  //subscribeShipAddrs: any;
  subscribeCartItems: any;
  order: Order = new Order();
  tmpOrder: Order = new Order();
  minDateConfig: any;
  maxDateConfig: any;
  datePipe = new DatePipe('en-US');
  turanapokText: any;
  model: NgbDateStruct;
  submitClicked: boolean;
  cartItems: any[] = [];
  public visibledCancel: boolean = true;  
  hazhozszallMode: any = SZALLMOD_HAZHOZSZALLITAS;
  szemelyesatvetelMode: any = SZALLMOD_SZEMELYESATVETEL
  addCartAfterSetting:any;*/
  @ViewChild('dp', { static: false }) dp: ElementRef;

  constructor(
    public userService: UserService,
    public iziToast: NgxIzitoastService,
    private fb: FormBuilder,
    public modal: NgbActiveModal,
    private store: Store<AppState>

  ) {

  }

  /*initsubscribeOrder() {
    this.subscribeOrder = this.store.select('order').subscribe(
      order => {
        
        if (order) {
          this.order = order;
          console.log(this.user.tura);
          console.log(this.order);  
        
          //this.setDisabledDays(this.order.szallmod);            
        } 
        if(this.order.szallmod) {
          this.setDisabledDays(this.order.szallmod);
        } else {
          if(!this.order) { 
            this.order = new Order();
          }
          if(this.user.tura) {
            this.order.szallmod =SZALLMOD_HAZHOZSZALLITAS; 
            this.setDisabledDays(SZALLMOD_HAZHOZSZALLITAS);
          } else {
            this.order.szallmod =SZALLMOD_SZEMELYESATVETEL;
            this.setDisabledDays(SZALLMOD_SZEMELYESATVETEL);
          }
        }
        
        this.createForm();
        this.initForm();
        this.initHataridoMinMax();

      },
      error => console.log(error)
    );
  }*/

  ngOnInit() {
    this.createForm();
  }


  onClickCancel() {

  }


  public onClickSave() {
    this.submitClicked = true;
    if (this.helpdeskForm.status == 'VALID') {
      this.blockUI.start();      
      this.userService.postHelpdesk(this.helpdeskForm.value)
        .subscribe(
          response => {
            this.blockUI.stop();
            this.modal.close();
            this.userService.getHelpdesks();

          },
          (res: HttpErrorResponse) => {
            this.blockUI.stop();

          }
        );
    }
    /*console.log(this.order);
    console.log(this.teljesitesForm.value);
    this.submitClicked = true;
    if (this.teljesitesForm.status == 'VALID') {
      if(this.addCartAfterSetting) {
        this.addCartAfterSetting.cartService.addCartItem(this.addCartAfterSetting.product, this.addCartAfterSetting.qty);
        this.addCartAfterSetting = null;
      }
      var d = new Date();
      d.setFullYear(this.teljesitesForm.controls['hatido'].value.year);
      d.setMonth(this.teljesitesForm.controls['hatido'].value.month - 1);
      d.setDate(this.teljesitesForm.controls['hatido'].value.day);
      this.order.hatido = this.datePipe.transform(d, 'yyyy-MM-dd');
      this.order.szallmod = this.teljesitesForm.controls['szallmod'].value;
      //this.order.shippingAddressId = this.teljesitesForm.controls['shippingAddressId'].value;
      localStorage.setItem('order', JSON.stringify(this.order));
      this.store.dispatch({ type: 'ORDER_UPDATED', payload: this.order });
      this.modal.close();
      this.modMessage();
    }*/
  }


  createForm() {
    if (this.editedHelpdesk) {
      this.helpdeskForm = this.fb.group({
        title: new FormControl('', []),
        content: new FormControl('', [Validators.required]),
        id: this.editedHelpdesk.id
      } );
    } else {
      this.helpdeskForm = this.fb.group({
        title: new FormControl('', [Validators.required]),
        content: new FormControl('', [Validators.required]),
      } );
    }
  }
  /*
    initForm() {
      var hatidoConf = null;
      if (this.order.hatido) {
        var p: any[] = [];
        var d = this.order.hatido.split('-');
  
        p[1] = parseInt(d[1]);
        p[0] = parseInt(d[0]);
        p[2] = parseInt(d[2]);
        hatidoConf = { year: p[0], month: p[1], day: p[2] };
        this.model = hatidoConf;
      }    
      this.teljesitesForm.controls['hatido'].setValue(hatidoConf);
      this.teljesitesForm.controls['szallmod'].setValue(this.order.szallmod);    
    }
  */

  ngOnDestroy(): void {
    this.editedHelpdesk = null;

  }
}



