
export class Filter {
    public minPrice: number;
    public maxPrice: number;
    
    //public folders: any;
    public subfolders: any = [];
    public topfolders: any = [];
    public ujsagok: any = [];
    public gyartok: any = [];
    public markak: any = [];
    public alergenek: any = [];
    
    //public hasChildFolder: any;
    public keyword: string = '';
    public filterByPriceButton: boolean;
    public kifuto: boolean;
    public ujdonsag: boolean;
}
