export class CartItem {
  id: number;
  cikkszam: string;
  
  nev: string;  
  qty: number; 

  defImage: string; 
  netto: number; 
  eredNettoListaar: number;
  brutto: number; 
  eredBruttoListaar: number;
  akcios: number;
  me: string;
  wPrice: string; 
  kedvszaz: number; 
  akedvszaz: number; 
  akcioazon: string;
  dbmeny: number;
  keselt: number;
  /*unitName: string;
  
  partnerPrice: number; 
  price: number; 
  partnerPriceBrutto: number; 
  priceBrutto: number; 
  discount: number;
  
  inStock: boolean;*/
}
