import { Component, OnInit, EventEmitter, Output, ViewChild, ViewContainerRef, ComponentFactoryResolver, ComponentFactory, ComponentRef } from '@angular/core';
import { AppState } from '../../app-state';
import { CheckoutService } from '../../services/checkout.service';
import { FormControl, FormArray, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute, Params, NavigationExtras, NavigationEnd } from '@angular/router';
import { CartItem } from '../../models/cartitem.model';
import { Product } from '../../models/product.model';
import { Store } from '@ngrx/store';
import { FoldersComponent } from '../../components/folders/folders.component';
import { Folder } from '../../models/folder.model';
import { Order } from '../../models/order.model';
import { CASH_PAYMENT_MODE, BANKTRANSFER_PAYMENT_MODE } from '../../utils/constants';
import { User } from '../../models/user.model';
declare var $: any;

@Component({
  selector: 'payment',
  templateUrl: './payment.component.html',
  //styleUrls: ['./layout.component.css']
})
export class PaymentComponent implements OnInit {
  shopConfig: any;  
  subscribeStepObsrv:any;
  subscribeOrder: any;
  subscribeUser: any;
  order: Order;
  selectedFizmod: string;
  @Output() onNextToReview = new EventEmitter();
  @Output() onBackToShipping = new EventEmitter();
  @Output() onPaymentValid = new EventEmitter();
  cash: string = CASH_PAYMENT_MODE;
  bank: string = BANKTRANSFER_PAYMENT_MODE;

  user: User;
  constructor(
    private checkoutService: CheckoutService,
    private router: Router,
    private store: Store<AppState>) {
    this.shopConfig = {
      paymentMethods: []
    };

    this.subscribeStepObsrv = this.checkoutService.stepChangeObservable.subscribe(value => {
      if (value == 3) {
        this.leavePage();
      }      
    });
  }

  ngOnInit() {

    this.subscribeUser = this.store.select('user').subscribe(
      user => {
        if (user != null && user.id > 0) {
          this.user = user;
          this.initSubscribeOrder();

        } else {
          this.user = null;
        }
      },
      error => console.log(error)
    );
  }

  initSubscribeOrder() {
    this.subscribeOrder = this.store.select('order').subscribe(
      order => {
        if (order) {
          this.order = order;
          if(this.order.fizmod) {
            this.selectedFizmod = this.order.fizmod;
          } else {
            this.selectedFizmod = this.user.fizmod;
          }
        }
        console.log(this.selectedFizmod);
      },
      error => console.log(error)
    );
  }

  onClickBackToShipping() {
    this.leavePage();
    this.onBackToShipping.emit();
  }

  leavePage() {
    this.order.fizmod = this.selectedFizmod;
    this.store.dispatch({ type: 'ORDER_UPDATED', payload: this.order });        
    this.onPaymentValid.emit();   
  }

  /*initSubscrConfig() {
    this.subscribeShopConfig = this.store.select('shopConfig').subscribe(
      shopConfig => {
        if (shopConfig) {

          this.shopConfig = shopConfig;
          if (this.user.paytypeCode == CASH_PAYMENT_MODE) {
            this.shopConfig.paymentMethods = this.shopConfig.paymentMethods.filter(m => m.id == CASH_PAYMENT_MODE);
          }
        }
      },
      error => console.log(error)
    );
  }*/

  onChangePaymentMode(paymentMode) {
    this.order.fizmod = paymentMode;
    this.store.dispatch({ type: 'ORDER_UPDATED', payload: this.order });
  }

  onClickNext() {       
    this.order.fizmod = this.selectedFizmod;
    this.onNextToReview.emit();
  }

  ngOnDestroy(): void { 
       
    this.subscribeOrder.unsubscribe();
    this.subscribeStepObsrv.unsubscribe();
  }
}
