import { Component, OnInit, AfterViewInit, AfterViewChecked, ViewChild, Renderer, ElementRef } from '@angular/core';
import { Router, ActivatedRoute, Params, NavigationExtras, NavigationEnd, Event } from '@angular/router';
import { Title } from "@angular/platform-browser";
import { Store } from '@ngrx/store';
import { AppState } from '../../app-state';
import { NgxIzitoastService } from 'ngx-izitoast';
import { Product } from '../../models/product.model';
//import { PhotoSwipeComponent } from '../../components/photo-swipe/photo-swipe.component';
import { ProductService } from '../../services/product.service';
import { FavoritesService } from '../../services/favorites.service';
import { FolderService } from '../../services/folder.service';
import { CartService } from '../../services/cart.service';
import { UserService } from '../../services/user.service';
import { PRODUCT_DATA_EXPIRED_TIME_SEC,PRODUCT_IMAGES_DIR } from '../../utils/constants';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { User } from 'src/app/models/user.model';
import { Order } from 'src/app/models/order.model';
import { Utils } from 'src/app/utils/utils';
//import 'hammerjs';
import { ConfigService } from 'src/app/services/config.service';
import { GoogleAnalyticsEventsService } from '../../services/google-analytics.service';
import { trigger, state, style, animate, transition } from '@angular/animations';

declare var $: any;
var self;


@Component({
  templateUrl: './product-page.html',
  animations: [
    trigger('imageLoadedState', [
      state('before', style({   
        opacity: '0',        
      })),
      state('after', style({
        opacity: '1',
        
      })),
      transition('*=>after', animate('900ms')),      
      transition('*=>before', animate('900ms')),      
    ])
    
  
  ],
  //styleUrls: ['./layout.component.css']
})
export class ProductPage implements OnInit {
  subscribeProduct: any;
  subscribeFolders: any;
  subscribeFavorites: any;
  subscribeOrder: any;
  subscribeUser: any;
  product: Product = new Product();
  productId: any;
  preOrderTeljDatum: string;
  folderBranch: any[] = [];
  descHtml: any = "";
  galleryInitReady: boolean;
  qty: any = 1;
  user: User;
  isFavorite: boolean;
  favorites: string[] = [];
  images: any[] = [];
  otherFiles: any[] = [];
  self: any;
  @BlockUI() blockUI: NgBlockUI;
  order: Order;
  step: number = 0;
  //@ViewChild('mainContainer') mainContainer: ElementRef;
  //@ViewChild('header') header: ElementRef;
  product_images_url: string = PRODUCT_IMAGES_DIR;
  imageLoadedCurrentState: string = 'before';

  constructor(
    private googleAnalyticsEventsService: GoogleAnalyticsEventsService,
    public iziToast: NgxIzitoastService,
    private renderer: Renderer,
    private titleService: Title,
    private router: Router,
    private cartService: CartService,
    private configService: ConfigService,
    private favoritesService: FavoritesService,
    private route: ActivatedRoute,
    private store: Store<AppState>,
    private productService: ProductService,
    private folderService: FolderService,
    private userService: UserService) {

    self = this;
    this.route.queryParamMap.subscribe(params => {
      if ((this.productId && this.productId != params.get('id')) || !this.productId) {
        var now = Date.now() / 1000;
        this.product = new Product();
        this.galleryInitReady = false;
        this.productId = params.get('id');
        var productFromStore = this.productService.products.find(p => p.cikkszam == this.productId);
        if (productFromStore && now - productFromStore.loadTms < PRODUCT_DATA_EXPIRED_TIME_SEC) {
          this.initProduct(productFromStore);
        } else {
          this.blockUI.start();
          this.productService.getProduct(this.productId);
        }
      }
    });

    this.router.events
      .subscribe((event) => {
        if (event instanceof NavigationEnd) {

        }
      });
  }

  onImageLoaded() {  
    this.imageLoadedCurrentState = 'after';
  }

  onClickAddToCart() {
    if (this.qty) {
      if (this.qty % this.step == 0) {
        this.cartService.addCartItemPreCheck(this.user, this.product, this.qty);
        this.qty = this.step;
      } else {
        this.iziToast.show({ title: 'Figyelem!', message: "Nem megengedett mennyiség.", position: 'topCenter', timeout: 2000, close: false, color: 'red' });
      }
    }
  }


  onClickSlideShow(index) {
    var imgs = [];
    for (var file of this.images) {
      imgs.push({ src: this.product_images_url+'/' + this.product.cikkszam + '/' + this.product.cikkszam + '_md.png', w: file.mdWidth, h: file.mdHeight });
    }
    
    console.log(this.configService.getPhotoswipeComp());
    this.configService.getPhotoswipeComp().openGallery(index, imgs);
  }

  ngOnInit() {

    var $scrollTop = $('.scroll-to-top-btn');
    $scrollTop.removeClass('visible');


    this.subscribeProduct = this.store.select('product').subscribe(
      product => {
        if (product && product.id > 0) {
          this.initProduct(product);
          this.googleAnalyticsEventsService.emitEvent('product', 'open', product.nev , null);   
        }
      },
      error => console.log(error)
    );

    this.subscribeUser = this.store.select('user').subscribe(
      user => {
        if (user != null && user.id > 0) {
          this.user = user;
        } else {
          this.user = null;
        }
      },
      error => console.log(error)
    );

    this.subscribeOrder = this.store.select('order').subscribe(
      order => {
        if (this.preOrderTeljDatum && order && this.preOrderTeljDatum != order.hatido) {
          this.productService.getProduct(this.productId);
        }
        if (order) {
          this.order = order;
          this.preOrderTeljDatum = order.hatido;
        }
      },
      error => console.log(error)
    );

    this.subscribeFavorites = this.store.select('favorites').subscribe(
      favorites => {
        if (favorites) {
          this.favorites = favorites;
          this.setIsFavorite();
        }
      },
      error => console.log(error)
    );

  }

  initProduct(product) {
    if (product.file) {
      this.images[0] = JSON.parse(product.file);
      this.images[0].cikkszam = this.product.cikkszam;
    }
    this.product = product;
    this.titleService.setTitle(this.product.nev);

    product.nev = this.product.nev;
    this.step = Utils.getStep(product);
    this.qty = this.step;

    if (product.defImage == "no-available") {
    } else {
    }
    this.blockUI.stop();
  }

  onClickSignInUp() {
    let navigationExtras: NavigationExtras = {};
    this.router.navigate([{ outlets: { popup: 'signinup' } }], navigationExtras);
  }

  setIsFavorite() {
    var isFavorite = this.favorites.find(f => f == this.productId);
    this.isFavorite = (isFavorite) ? true : false;
  }

  onClickAddToFavorites() {
    event.stopPropagation();
    this.favoritesService.addFavoritesItem(this.product.cikkszam);
  }

  onClickRemoveFavorite() {
    event.stopPropagation();
    this.favoritesService.removeFavoritesItem(this.product.cikkszam);    
  }

  
  ngOnDestroy(): void {
    this.store.dispatch({ type: 'PRODUCT_UPDATED', payload: null });
    this.subscribeProduct.unsubscribe();
    this.subscribeUser.unsubscribe();
    this.subscribeOrder.unsubscribe();
    //  this.subscribeFolders.unsubscribe();
    //  this.subscribeFavorites.unsubscribe();


  }


}
