import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { User } from '../models/user.model';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { HttpErrorResponse } from '@angular/common/http';
import { AppState } from '../app-state';
import { API_URL } from '../utils/constants';
import { ShippingAddress } from '../models/shipping-address.model';
import { Helpdesk } from '../models/helpdesk.model';

@Injectable()
export class UserService {
  user: User;
  constructor(
    private httpClient: HttpClient,
    private store: Store<AppState>
  ) {
    this.store.select('user').subscribe(
      user => {
        if (user != null && user.id > 0) {
          this.user = user;
        } else {
          this.user = null;
        }
      },
      error => console.log(error)
    );

  }

  checkTokenIsValid(): Observable<any> {
    return this.httpClient.get(API_URL + "user/checktoken");
  }

  createUser(user: User): Observable<User> {
    return this.httpClient.post<User>(API_URL + "user", user);
  }

  updateProfile(user: User): Observable<User> {
    return this.httpClient.post<User>(API_URL + "user/profile", user);
  }

  updateAddress(user: User): Observable<User> {
    return this.httpClient.post<User>(API_URL + "user/address", user);
  }

  /*updateShippingAddress(shippingAddress: ShippingAddress): Observable<any>{
    return this.httpClient.post<ShippingAddress[]>(API_URL + "user/shipping-address", shippingAddress);
  }*/
  updateShippingAddress(user: User): Observable<any> {
    return this.httpClient.post<User>(API_URL + "user/shipping-address", user);
  }

  deleteShippingAddress(id: number): Observable<any> {
    return this.httpClient.delete<ShippingAddress[]>(API_URL + "user/shipping-address/" + id);
  }

  passwordRecovery(email: string): Observable<User> {
    return this.httpClient.get<User>(API_URL + "user/password-recovery/" + email);
  }

  createToken(user: User): Observable<User> {
    return this.httpClient.post<User>(API_URL + "token", user);
  }

  getUser() {
    this.httpClient.get<User>(API_URL + "user")
      .subscribe(
        user => {
          this.store.dispatch({ type: 'USER_UPDATED', payload: user });
        },
        (err: HttpErrorResponse) => {
        }
      );
  }

  getHelpdesks() {
    this.httpClient.get<Helpdesk[]>(API_URL + 'user/helpdesk')
      .subscribe(
        helpdesks => {
          this.store.dispatch({ type: 'HELPDESKS_UPDATED', payload: helpdesks });
          console.log(helpdesks);
        },
        (err: HttpErrorResponse) => {

        }
      );
  }
  
  postHelpdesk(helpdesk: Helpdesk): Observable<any> {
    return this.httpClient.post<any>(API_URL + "user/helpdesk", helpdesk);
  }

  private handleError(error: any): Promise<any> {
    console.log('An error occurred', error); // for demo purposes only
    return Promise.reject(error.message || error);
  }
}
