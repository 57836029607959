import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params, NavigationExtras } from '@angular/router';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';

import { PasswordValidation } from '../../../utils/password-validation';
import {
  NEV_MAX_LENGTH, NEV1_MAX_LENGTH, PASSWORD_REGEX, EMAIL_REGEX, EMAIL_MAX_LENGTH, CONTACT_PERSON_MAX_LENGTH, TEL1_MAX_LENGTH,
  HELYSEG_MAX_LENGTH, CIM_MAX_LENGTH, PASSWORD_MAX_LENGTH, TAX_REGEX
} from '../../../utils/constants';

import { User } from '../../../models/user.model';
import { TokenInterceptor } from '../../../token.interceptor';
import { UserService } from '../../../services/user.service';
import { HttpErrorResponse } from '@angular/common/http';
import { USER_UPDATED } from '../../../reducers/user';
import { AppState } from '../../../app-state';
import { Store } from '@ngrx/store';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { NgxIzitoastService } from 'ngx-izitoast';
declare var $: any;

@Component({

  templateUrl: './sign-in-up.page.html',

})
export class SignInUpPage {
  signinForm: FormGroup;
  signupForm: FormGroup;
  submitClicked: boolean;
  signupInProgress: boolean;
  signinInProgress: boolean;
  successSignup: boolean;
  @BlockUI() blockUI: NgBlockUI;
  signinSubmitClicked: boolean;
  user: User = null;

  emailMaxLength: number = EMAIL_MAX_LENGTH;
  nevMaxLength: number = NEV_MAX_LENGTH;
  contactPersonMaxLength: number = CONTACT_PERSON_MAX_LENGTH;
  tel1MaxLength: number = TEL1_MAX_LENGTH;
  helysegMaxLength: number = HELYSEG_MAX_LENGTH;
  cimMaxLength: number = CIM_MAX_LENGTH;
  passwordMaxLength: number = PASSWORD_MAX_LENGTH;

  taxNumberMask = [/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, '-', /[0-9]/, '-', /[0-9]/, /[0-9]/];
  zipNumberMask = [/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];


  constructor(
    public iziToast: NgxIzitoastService,
    private router: Router,
    private userService: UserService,

    private fb: FormBuilder,
    private store: Store<AppState>) {

    this.createForm();
    this.createSignInForm();
  }

  ngOnInit() {
  }

  onSubmitSignup() {
    this.submitClicked = true;

    if (this.signupForm.status == 'VALID') {
      this.signupInProgress = true;

      this.signupForm.controls['nev1'].setValue(this.signupForm.controls['nev'].value.substring(0, 40));
      this.signupForm.controls['nev1a'].setValue(this.signupForm.controls['nev'].value.substring(40, 80));

      this.blockUI.start();
      this.userService.createUser(this.signupForm.value)
        .subscribe(
          user => {
            this.successSignup = true;
            this.signupInProgress = false;
            $('.popup-container-inner').scrollTop(0);
            this.blockUI.stop();
          },
          (err: HttpErrorResponse) => {
            this.blockUI.stop();
            if (err.error.email) {
              this.signupForm.controls['email'].setErrors({ 'existed': true });
            }
            if (err.error.taxnum) {
              this.signupForm.controls['aiasz'].setErrors({ 'existed': true });
            }
            this.signupInProgress = false;
          }
        );

    }
  }

  onSubmitSignin() {
    this.signinSubmitClicked = true;

    if (this.signinForm.status == 'VALID') {
      this.blockUI.start();
      this.signinInProgress = true;
      this.userService.createToken(this.signinForm.value)
        .subscribe(
          user => {
            this.blockUI.stop();
            this.signinInProgress = false;
            this.iziToast.show({ title: user.contactPerson + " sikeresen bejelentkezett!", position: 'topCenter', timeout: 2000 });

            this.user = user;
            localStorage.setItem('token', this.user.token);
            this.store.dispatch({ type: USER_UPDATED, payload: user });
            this.router.navigate(['/']);

          },
          (err: HttpErrorResponse) => {
            this.blockUI.stop();
            this.signinInProgress = false;
            console.log(err);
            this.iziToast.show({ title: "Sikertelen belépési kísérlet!", position: 'topCenter', timeout: 2000 });

          }
        );

    } else {

    }
  }


  createForm() {
    this.signupForm = this.fb.group({
      nev: new FormControl('', [Validators.required]),
      nev1: new FormControl('', []),
      nev1a: new FormControl('', []),
      tel1: new FormControl('', [Validators.required]),
      contactPerson: new FormControl('', [Validators.required]),
      aiasz: new FormControl('', [Validators.required, Validators.pattern(TAX_REGEX)]),
      password: new FormControl('', [
        Validators.required,
        Validators.pattern(PASSWORD_REGEX)
      ]),
      confirmPassword: new FormControl('', [
        Validators.required,
        Validators.pattern(PASSWORD_REGEX)
      ]),
      email: new FormControl('', [
        Validators.maxLength(this.emailMaxLength),
        Validators.required,
        Validators.pattern(EMAIL_REGEX)]),
      irsz: new FormControl('', [Validators.required]),
      helyseg: new FormControl('', [Validators.required]),
      cim: new FormControl('', [Validators.required])
    },
      {
        validator: PasswordValidation.MatchPassword // your validation method
      }
    );
  }

  createSignInForm() {
    this.signinForm = this.fb.group({
      email: new FormControl('', [
        Validators.required]),
      password: new FormControl('', [
        Validators.required
      ]),

    });
  }

  onClickRecoveryPwd() {
    let navigationExtras: NavigationExtras = {
      queryParams: {}
    };
    this.router.navigate([{ outlets: { popup: 'recovery-password' } }], navigationExtras);
  }
}
