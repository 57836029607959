import { Injectable } from '@angular/core';
//import { Http, Response, RequestOptions, Headers } from '@angular/http';
import { Store } from '@ngrx/store';
import { Folder } from '../models/folder.model';
import { Gyarto } from '../models/gyarto.model';
import { FOLDERS_UPDATED } from '../reducers/folders';
import { UJSAGOK_UPDATED } from '../reducers/ujsagok';
import { GYARTOK_UPDATED } from '../reducers/gyartok';
import { MARKAK_UPDATED } from '../reducers/markak';
import { ALERGENEK_UPDATED } from '../reducers/alergenek';
import { Observable,Subject } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { HttpErrorResponse } from '@angular/common/http';
import { AppState } from '../app-state';
import { API_URL } from '../utils/constants';

@Injectable()
export class FolderService {
  public folders: Folder[];
  public removeFolderObservable = new Subject<string>();
  public filterCounterObservable = new Subject<number>();
  public foldersMap: Map<string, string> = new Map<string, string>();
  constructor(
    private httpClient: HttpClient,
    private store: Store<AppState>
   ) { }

    getFolders() {
      this.httpClient.get<Folder[]>(API_URL + "folder")
        .subscribe(
          folders => {          
            var folder:any;
            for(folder of folders) {
              this.foldersMap.set(folder.id, folder.name);
            }
            this.folders = folders;
            this.store.dispatch({ type: FOLDERS_UPDATED, payload: folders });     
          },
          (err: HttpErrorResponse) => {
            
          }
      );
    }


    getUjsagok() {
      
      this.httpClient.get<Folder[]>(API_URL + "folder/ujsag")
        .subscribe(
          ujsagok => {          
            this.store.dispatch({ type: UJSAGOK_UPDATED, payload: ujsagok });     
          },
          (err: HttpErrorResponse) => {
            
          }
      );
    }

    getAlergenek() {
      this.httpClient.get<Gyarto[]>(API_URL + "folder/alergen")
        .subscribe(
          alergenek => {          
            this.store.dispatch({ type: ALERGENEK_UPDATED, payload: alergenek });     
          },
          (err: HttpErrorResponse) => {
            
          }
      );
    }

    getGyartok() {
      this.httpClient.get<Gyarto[]>(API_URL + "folder/gyarto")
        .subscribe(
          gyartok => {          
            this.store.dispatch({ type: GYARTOK_UPDATED, payload: gyartok });     
          },
          (err: HttpErrorResponse) => {
            
          }
      );
    }

    getMarkak() {
      this.httpClient.get<Gyarto[]>(API_URL + "folder/marka")
        .subscribe(
          markak => {          
            this.store.dispatch({ type: MARKAK_UPDATED, payload: markak });     
          },
          (err: HttpErrorResponse) => {
            
          }
      );
    }
    
    getUjsagokByUser() {
      this.httpClient.get<Folder[]>(API_URL + "folder/ujsag_by_user")
        .subscribe(
          ujsagok => {          
            this.store.dispatch({ type: UJSAGOK_UPDATED, payload: ujsagok });     
          },
          (err: HttpErrorResponse) => {
            
          }
      );
    }


    /*setFilterByManufact(folderId: string) {
      var filter =  new Filter();
      var folderBranch = [];
      var clickedFolderId = folderId;
      var folder = this.folders.find(f => f.id == clickedFolderId);
      var hasChild = this.folders.find(f => f.parentId == clickedFolderId);
      filter.hasChildFolder = hasChild;
      folderBranch.push(folder.id);    
      filter.folders = folderBranch;    
      this.store.dispatch({ type: 'FILTER_UPDATED', payload: filter });
    }

    setFilterByCategory(categoryId: number) {      
      var filter =  new Filter();    
      filter.category = categoryId;
      this.store.dispatch({ type: 'FILTER_UPDATED', payload: filter });
    }
  */

  removeFolderFromFilter(folderId) {
    this.removeFolderObservable.next(folderId);
  }

  updateFilterCounter(filtersNum) {
    this.filterCounterObservable.next(filtersNum);
  }

  private extractData(res: Response) {
    let body = res.json();
    return body || {};
  }

  private handleError(error: any): Promise<any> {
    console.log('An error occurred', error); // for demo purposes only
    return Promise.reject(error.message || error);
  }
}
