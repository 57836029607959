import { Component, OnInit, Output, EventEmitter, ViewChild, ViewContainerRef, ComponentFactoryResolver, ComponentFactory, ComponentRef } from '@angular/core';
import { AppState } from '../../app-state';
import { CheckoutService } from '../../services/checkout.service';
//import { ConfigService } from '../../services/config.service';
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormArray, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute, Params, NavigationExtras, NavigationEnd } from '@angular/router';
import { CartItem } from '../../models/cartitem.model';
import { Order } from '../../models/order.model';
import { Store } from '@ngrx/store';
import { FoldersComponent } from '../../components/folders/folders.component';
import { ShippingAddress } from '../../models/shipping-address.model';
import { SZALLMOD_HAZHOZSZALLITAS, SZALLMOD_SZEMELYESATVETEL } from '../../utils/constants';
//import { ADDRESS_MAX_LENGTH, CITY_MAX_LENGTH, ZIP_MAX_LENGTH, NAME_MAX_LENGTH } from '../../utils/constants';
import { OrderSettingsModal } from '../../components/order-settings/order-settings.modal';
import { User } from 'src/app/models/user.model';

declare var $: any;

@Component({
  selector: 'shipping',
  templateUrl: './shipping.component.html',
  //styleUrls: ['./layout.component.css']
})
export class ShippingComponent implements OnInit {
  shippingForm: FormGroup;
  //subscribeShipAddrs: any;
  subscribeShopConfig: any;
  subscribeUser: any;
  subscribeStepObsrv: any;
  subscribeOrder: any;
  shopConfig: any;
  shippingMode: any;
  selectedShippingMethod: any;
  //selectedShippingAddrId: any;
  user: User;
  order: Order;
  @Output() onNextToPayment = new EventEmitter();
  @Output() onBackToAddress = new EventEmitter();
  @Output() onShippingValid = new EventEmitter();
  shipAddrs: ShippingAddress[] = [];
  hazhozszallitas: string = SZALLMOD_HAZHOZSZALLITAS;
  szemelyesAtvetel: string = SZALLMOD_SZEMELYESATVETEL;
  modalRef : NgbModalRef;

  constructor(
    private modalService: NgbModal,
    private checkoutService: CheckoutService,
    //  private configService: ConfigService,
    private router: Router,
    private fb: FormBuilder,
    private store: Store<AppState>) {
    this.shopConfig = {
      shippingMethods: []
    };
    //this.createForm();
    this.subscribeStepObsrv = this.checkoutService.stepChangeObservable.subscribe(value => {
      if (value == 2) {
        this.sendDataStatus();
      }
    })
  }

  ngOnInit() {
    //this.createForm();
    this.initSubscrOrder();
    this.subscribeUser = this.store.select('user').subscribe(
      user => {
        if (user != null && user.id > 0) {
          this.user = user;
        } else {
          this.user = null;
        }
      },
      error => console.log(error)
    );
    this.subscribeShopConfig = this.store.select('shopConfig').subscribe(
      shopConfig => {
        if (shopConfig) {
          this.shopConfig = shopConfig;
        }
      },
      error => console.log(error)
    );
    /*    this.subscribeShopConfig = this.store.select('shopConfig').subscribe(
          shopConfig => {
            if (shopConfig) {
              this.shopConfig = shopConfig;
              this.initSubscrOrder();
            }
          },
          error => console.log(error)
        );*/
  }

  initSubscrOrder() {
    this.subscribeOrder = this.store.select('order').subscribe(
      order => {
        this.order = order;
       // this.sendDataStatus();
       
      },
      error => console.log(error)
    );


   
  }

  

  sendDataStatus() {
    //this.store.dispatch({ type: 'ORDER_UPDATED', payload: this.order });
    
    if(this.order.hatido && this.order.szallmod) { 
      this.onShippingValid.emit(true);
    } else {
      this.onShippingValid.emit(false);
    }
  }

  onClickEditAddress() {
    let navigationExtras: NavigationExtras = {
      queryParams: {}
    };
    this.router.navigate([{ outlets: { popup: 'shipping-address' } }], navigationExtras);
  }

  editShippingMode() {
    this.modalRef = this.modalService.open(OrderSettingsModal, { size: 'lg', backdrop:'static' });
    this.modalRef.componentInstance.visibledCancel = false;
  }

  /*
  onChangeShippingAddr(shipAddr: ShippingAddress) {    
    this.order.shippingAddressId = shipAddr.id;
    this.store.dispatch({ type: 'ORDER_UPDATED', payload: this.order });
  }

  setCheckedShippingMode() {
    this.shippingMethodModel = this.order.szallmod;    
    this.order.szallmod = this.shippingMethodModel;
    this.store.dispatch({ type: 'ORDER_UPDATED', payload: this.order });
    
  }*/


  /*
  setCheckedShippingMode() {
    for (var sm of this.shopConfig.shippingMethods) {
      sm.default = false;
      if (sm.id == this.order.shippingMode) {
        sm.default = true;
      }
    }
  }

  

  

  onChangeShippingMode(shippingMode) {
    this.order.shippingMode = shippingMode;
    var sM = this.shopConfig.shippingMethods.find(m => m.id == this.order.shippingMode);    
    this.order.shippingCost = sM.cost;        
    this.store.dispatch({ type: 'ORDER_UPDATED', payload: this.order });    
  }

  onClickBackToAddress() {
    this.leavePage();
    this.onBackToAddress.emit();
  }

  

 
*/
  onClickBackToAddress() {
    this.onBackToAddress.emit();
  }

  onClickNext() {    
    this.onNextToPayment.emit();
  }

  createForm() {

    this.shippingForm = this.fb.group({
      method: new FormControl('', [Validators.required]),
    }
    );
  }

  ngOnDestroy(): void {
    this.subscribeShopConfig.unsubscribe();
    this.subscribeOrder.unsubscribe();
    this.subscribeStepObsrv.unsubscribe();
    
  }
}
