import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Product } from '../models/product.model';
import { CartItem } from '../models/cartitem.model';
import { Order } from '../models/order.model';
import { FOLDERS_UPDATED } from '../reducers/folders';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { HttpErrorResponse } from '@angular/common/http';
import { AppState } from '../app-state';
import { API_URL } from '../utils/constants';
import { NgxIzitoastService } from 'ngx-izitoast';
import { NgbActiveModal, NgbModal,NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { OrderSettingsModal } from '../components/order-settings/order-settings.modal';


@Injectable()
export class CartService {
  subscribeCartItems: any;
  subscribeProducts: any;
  subscribeOrder: any;
  cartItems: CartItem[] = [];
  cartSum: number = 0;
  cartSumBrutto: number = 0;
  nettoSum: number = 0;
  cartAFA: number = 0;
  cartNettoMegtakaritas: number = 0;
  order: Order;
  modalRef : NgbModalRef;
  constructor(
    private modalService: NgbModal,
    public iziToast: NgxIzitoastService,
    private httpClient: HttpClient,
    private store: Store<AppState>
  ) {
    this.subscribeCartItems = this.store.select('cartItems').subscribe(
      cartItems => {
        if (cartItems) {
          this.cartItems = cartItems;
          this.recalcSum();
          this.saveCartLocalStorage(cartItems);
        }
      },
      error => console.log(error)
    );

    this.subscribeOrder = this.store.select('order').subscribe(
      order => {
        if (order) {
          this.order = order;
        }
      },
      error => console.log(error)
    );

    this.subscribeProducts = this.store.select('products').subscribe(
      products => {
        if (products && products.length > 0) {
          this.resumeCart(products);
        }
      },
      error => console.log(error)
    );
  }


  addCartItemPreCheck(user, product, qty) {
    if (user && user.id > 0) {
      if(product.wPriceNetto) {
      if (this.order && this.order.hatido && this.order.szallmod) {
        this.addCartItem(product, qty);
      } else {
        this.modalRef = this.modalService.open(OrderSettingsModal, {size:'lg'});        
        this.modalRef.componentInstance.addCartAfterSetting = { product: product, qty: qty, cartService: this};
      }
      } else {
        this.iziToast.show({ title: 'Hiányzó ár!', message:'A termék nem rakható kosárba.', position: 'topCenter', timeout: 2500, color:'red', close:false });

      }
    } else {
      this.iziToast.show({ title: '', message: "A rendelés összeállítához kérjük jelentkezzen be.", position: 'topCenter', timeout: 2500, color:'red', close:false });
    }
  }

  addCartItem(product: Product, qty: number) {
    
      this.iziToast.show({ title: qty + ' x ' + product.nev, message: "kosárba rakva.", position: 'topCenter', timeout: 1500, color:'green', close:false });
      var existedCartItem = this.cartItems.find(i => i.cikkszam == product.cikkszam);
  
      if(product.dbmeny && product.dbmeny != 1) {
        qty = qty * product.dbmeny;
      }

      if (existedCartItem) {
        existedCartItem.qty = existedCartItem.qty + qty;
        this.store.dispatch({ type: 'UPDATE_CARTITEM_QTY', payload: existedCartItem });
      } else {
        var cartItem = new CartItem();
        cartItem.id = product.id;
        cartItem.cikkszam = product.cikkszam;
        cartItem.nev = product.nev;
        cartItem.defImage = product.defImage;
        cartItem.qty = qty;
        cartItem.netto = product.wPriceNetto;
        cartItem.brutto = product.wPriceBrutto;
        cartItem.eredNettoListaar = product.eredNettoListaar;
        cartItem.eredBruttoListaar = product.eredBruttoListaar;
        cartItem.akcios = product.akcios;
        cartItem.me = product.me;
        cartItem.dbmeny = product.dbmeny;
        cartItem.keselt = product.keselt;
        cartItem.wPrice = product.wPrice;
        cartItem.kedvszaz = product.kedvszaz;
        cartItem.akedvszaz = product.akedvszaz;
        cartItem.akcioazon = product.akcioazon;
        this.store.dispatch({ type: 'ADD_CARTITEM', payload: cartItem });
      }
  
    
  }

  removeCartItem(cartItem: CartItem) {
    this.store.dispatch({ type: 'REMOVE_CARTITEM', payload: cartItem });
  }

  clearCart() {
    this.cartItems = [];
    localStorage.removeItem('cartItems');
    this.store.dispatch({ type: 'CLEAR_CART', payload: null });
  }


  resumeCart(products) {
    if (localStorage.getItem('cartItems') 
        && localStorage.getItem('cartItems') != '' && localStorage.getItem('token')) {
      var cartItems = JSON.parse(localStorage.getItem('cartItems'));
      var cartItemsRefresh: any[] = [];

      this.clearCart();
      var product: any;
      for (product of products) {

        var cartItem = cartItems.find(i => i.cikkszam == product.cikkszam);
        if (cartItem) {
          //cartItem.partnerPrice = variant.partnerPrice;
          //cartItem.price = variant.price;
          //cartItem.discount = product.discount;
          //cartItem.inStock = variant.inStock;
          cartItem.netto = product.wPriceNetto;
          cartItem.eredNettoListaar = product.eredNettoListaar;
          cartItem.akcios = product.akcios;  
          cartItem.brutto = product.wPriceBrutto;
          cartItem.eredBruttoListaar = product.eredBruttoListaar;
          cartItem.wPrice = product.wPrice;
          cartItem.kedvszaz = product.kedvszaz;
          cartItem.akedvszaz = product.akedvszaz;
          cartItem.akcioazon = product.akcioazon;
          cartItem.dbmeny = product.dbmeny;
          cartItem.keselt = product.keselt;
          cartItemsRefresh.push(cartItem);
        }
        if (cartItemsRefresh.length == cartItems.length) {
          break;
        }
      }
      console.log(cartItemsRefresh);
      for (var cartItem of cartItemsRefresh) {
        this.store.dispatch({ type: 'ADD_CARTITEM', payload: cartItem });

      }
    }


  }

  saveCartLocalStorage(cartItems) {
    localStorage.setItem('cartItems', JSON.stringify(cartItems));
  }

  recalcSum() {
    this.cartSum = 0;
    this.cartSumBrutto = 0;
    this.cartNettoMegtakaritas = 0;
    var eredetiNettoSum = 0;
    for (var cartItem of this.cartItems) {
      this.cartSum += cartItem.qty * cartItem.netto;
      this.cartSumBrutto += cartItem.qty * cartItem.brutto;
      if(cartItem.eredNettoListaar) {
        eredetiNettoSum += cartItem.qty * cartItem.eredNettoListaar;
      }
    }
    this.cartSum = Math.round(this.cartSum);
    this.cartSumBrutto = Math.round(this.cartSumBrutto);
    this.cartNettoMegtakaritas = Math.round(eredetiNettoSum) - this.cartSum;

    this.cartAFA =this.cartSumBrutto - this.cartSum;

  }
  /*
    recalcSum() {
      this.cartSum = 0;
      for (var cartItem of this.cartItems) {
        this.cartSum += cartItem.qty * cartItem.partnerPrice;
      }
      this.nettoSum = Math.round(this.cartSum / 1.27);
      this.cartAFA = this.cartSum - this.nettoSum;
  
    }
  
    
  
    
  
    addCartItem(product: Product, qty: number) {
      var selectedVariant = product.productVariantList.find(v => v.id == product.defVariantId);
      var existedCartItem = this.cartItems.find(i => i.productId == selectedVariant.origId);
      if (existedCartItem) {
        existedCartItem.qty = existedCartItem.qty + qty;
        this.store.dispatch({ type: 'UPDATE_CARTITEM_QTY', payload: existedCartItem });
      } else {
        var cartItem = new CartItem();
  
        cartItem.productWebId = product.id;
        cartItem.code = product.code;
        cartItem.defImage = product.defImage;
        cartItem.partnerPrice = selectedVariant.partnerPrice;
        cartItem.price = selectedVariant.price;
        cartItem.discount = product.discount;
        cartItem.qty = qty;
        cartItem.inStock = selectedVariant.inStock;
        cartItem.productId = selectedVariant.origId;
        cartItem.name = product.name;
        cartItem.unitName = selectedVariant.unitName;
      
        this.store.dispatch({ type: 'ADD_CARTITEM', payload: cartItem });
      }
  
    }
  
    private extractData(res: Response) {
      let body = res.json();
      return body || {};
    }
  
    private handleError(error: any): Promise<any> {
      console.log('An error occurred', error); // for demo purposes only
      return Promise.reject(error.message || error);
    }*/
}
