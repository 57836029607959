import { Component, OnInit, ElementRef, Input,ViewChild } from '@angular/core';
import { Router, ActivatedRoute, Params, NavigationExtras } from '@angular/router';
import { FolderService } from '../../services/folder.service';
import { AppState } from '../../app-state';
import { Store } from '@ngrx/store';
import {OwlCarousel} from 'ngx-owl-carousel';

declare var $: any;

@Component({
    selector: 'brands',
    templateUrl: './brands.component.html'

})
export class BrandsComponent implements OnInit {
    @ViewChild('owlElement', {static: false}) owlElement: OwlCarousel;
    owlReady: boolean;

    constructor(
        private router: Router,
        private folderService: FolderService,
        private store: Store<AppState>) {
    }

    ngOnInit() {

    }
    ngAfterViewChecked() {
        if (!this.owlReady) {
            if (this.owlElement) {
                this.owlReady = true;
            }
        }
    }

    owlMouseLeave() {
        if (this.owlReady) {
            this.owlElement.next();
        }
    }
    ngOnDestroy(): void {

    }
}
