import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { StoreModule } from '@ngrx/store';
import { TextMaskModule } from 'angular2-text-mask';
//import { NgxNouisliderModule } from 'ngx-nouislider';           VAGY
//import { NouisliderModule } from 'ng2-nouislider';
import { Ng5SliderModule } from 'ng5-slider';
import { BlockUIModule } from 'ng-block-ui';
import { NgxUploaderModule } from 'ngx-uploader';
//import { VirtualScrollerModule } from 'ngx-virtual-scroller'; ismétlődési porbléma miatt nem jó
//import { ScrollingModule } from '@angular/cdk/scrolling'; csak egy 1 sor / 1 elem megjelenítésre alkalma
import { OwlModule } from 'ngx-owl-carousel';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthGuard } from './auth.guard';
import { TokenInterceptor } from './token.interceptor';
import { InfiniteScrollModule } from 'ngx-infinite-scroll'; // a maxolennél már bevált
import { NgxIziToastModule } from 'ngx-izitoast';
import { SafeHtmlPipe } from './utils/safe-html-pipe';
import { SpacePipe } from './utils/space.pipe';




/*pages */
/*import { OrdersPage } from './pages/account/orders/orders-page';
import { OrderPage } from './pages/order/order-page';
import { AddressPage } from './pages/account/address/address-page';
import { ShippingAddressPage } from './pages/account/address/shipping-address-page';

import { PwdRecoveryPage } from './pages/account/pwd-recovery/pwd-recovery';
import { HomePage } from './pages/home/home-page';
import { ProductPage } from './pages/product/product-page';
import { ProductEditor } from './pages/product-editor/product-editor';
import { CartPage } from './pages/cart/cart-page';*/
import { OrderPage } from './pages/order/order-page';
import { CheckoutPage } from './pages/checkout/checkout-page';
import { CartPage } from './pages/cart/cart-page';
import { HomePage } from './pages/home/home.page';
import { StaticPage } from './pages/static/static-page';
import { ProfilePage } from './pages/account/profile/profile.page';
import { AddressPage } from './pages/account/address/address.page';
import { ShippingAddressPage } from './pages/account/address/shipping-address.page';
import { ProductPage } from './pages/product/product-page';
import { SignInUpPage } from './pages/account/sign-in-up/sign-in-up.page';
import { RecoveryPasswordPage } from './pages/account/recovery-password/recovery-password';
import { OrdersPage } from './pages/account/orders/orders-page';
import { HelpdeskPage } from './pages/account/helpdesk/helpdesk-page';


/* components */
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { Header2Component } from './components/header/header-2.component';
import { SiteMenuComponent } from './components/header/site-menu/site-menu.component';
import { ToolbarAccountComponent } from './components/header/toolbar-account/toolbar-account.component';
import { FoldersComponent } from './components/folders/folders.component';
import { FiltersComponent } from './components/filters/filters.component';
import { PriceRangeComponent } from './components/price-range/price-range.component';
import { ProductsComponent } from './components/products/products.component';
import { ToolbarCartComponent } from './components/toolbar-cart/toolbar-cart.component';
import { OrderSettingsModal } from './components/order-settings/order-settings.modal';
import { HelpdeskModal } from './components/helpdesk/helpdesk.modal';
import { AddressComponent } from './pages/checkout/address.component';
import { ShippingComponent } from './pages/checkout/shipping.component';
import { UjsagokComponent } from './components/ujsagok/ujsagok.component';
import { GyartokComponent } from './components/gyarto/gyartok.component';
import { MarkakComponent } from './components/marka/markak.component';
import { AlergenekComponent } from './components/alergen/alergenek.component';
import { ElsefilterComponent } from './components/elsefilter/elsefilter.component';
import { PaymentComponent } from './pages/checkout/payment.component';
import { ReviewComponent } from './pages/checkout/review.component';
import { FooterComponent } from './components/footer/footer.component';
import { FavoritesComponent } from './components/favorites/favorites.component';
import { PhotoSwipeComponent } from './components/photo-swipe/photo-swipe.component';
import { HeroSliderComponent } from './components/hero-slider/hero-slider.component';
import { BrandsComponent } from './components/brand/brands.component';

/*
import { MobileMenuComponent } from './components/header/mobile-menu/mobile-menu.component';


import { ToolbarSearchComponent } from './components/header/toolbar-search/toolbar-search.component';
import { FilesComponent } from './components/files/files.component';

import { SummaryComponent } from './components/summary/summary.component';


import { HeroSliderComponent } from './components/hero-slider/hero-slider.component';
import { CategorySliderComponent } from './components/category/category-slider.component';
import { CategoryMenuComponent } from './components/category/category-menu.component';
import { OnSaleSliderComponent } from './components/onsale-slider/onsale-slider.component';
import { PhotoSwipeComponent } from './components/photo-swipe/photo-swipe.component';*/

/*services*/
import { UserService } from './services/user.service';
import { FileService } from './services/file.service';
import { FolderService } from './services/folder.service';
import { ProductService } from './services/product.service';
import { CartService } from './services/cart.service';
import { OrderService } from './services/order.service';
import { CheckoutService } from './services/checkout.service';
import { ConfigService } from './services/config.service';
import { FavoritesService } from './services/favorites.service';
import { GoogleAnalyticsEventsService } from './services/google-analytics.service';

/*

import { FavoritesService } from './services/favorites.service';

import { ConfigService } from './services/config.service';

import { FileService } from './services/file.service';*/


/* reducers */
import { gyartokReducer } from './reducers/gyartok';
import { userReducer } from './reducers/user';
import { shippingAddressesReducer } from './reducers/shipping-addresses';
import { foldersReducer } from './reducers/folders';
import { filterReducer } from './reducers/filter';
import { productsReducer } from './reducers/products';
import { cartItemsReducer } from './reducers/cartitems';
import { orderReducer } from './reducers/order';
import { productReducer } from './reducers/product';
import { ujsagokReducer } from './reducers/ujsagok';
import { markakReducer } from './reducers/markak';
import { alergenekReducer } from './reducers/alergenek';
import { shopConfigReducer } from './reducers/shopconfig';
import { favoritesReducer } from './reducers/favorites';
import { ordersReducer } from './reducers/orders';
import { helpdesksReducer } from './reducers/helpdesks';
/*
import { favoritesItemsReducer } from './reducers/favoritesitems';
import { shopConfigReducer } from './reducers/shopconfig';
*/

@NgModule({
  declarations: [
    AppComponent,
    SignInUpPage,
    HomePage,
    StaticPage,
    HeaderComponent,
    Header2Component,
    SiteMenuComponent,
    ToolbarAccountComponent,
    SafeHtmlPipe,
    SpacePipe,
    ProfilePage,
    AddressPage,
    ShippingAddressPage,
    FoldersComponent,
    FiltersComponent,
    PriceRangeComponent,
    ProductsComponent,
    ToolbarCartComponent,
    OrderSettingsModal,
    HelpdeskModal,
    ProductPage,
    CartPage,
    CheckoutPage,
    AddressComponent,
    ShippingComponent,
    UjsagokComponent,
    PaymentComponent,
    ReviewComponent,
    FooterComponent,
    RecoveryPasswordPage,
    GyartokComponent,
    MarkakComponent,
    AlergenekComponent,
    FavoritesComponent,
    OrdersPage,
    OrderPage,
    PhotoSwipeComponent,
    ElsefilterComponent,
    HelpdeskPage,
    HeroSliderComponent,
    BrandsComponent
  ],
  entryComponents: [
    FoldersComponent,
    OrderSettingsModal,
    HelpdeskModal
  ],
  imports: [
    BrowserModule,
    CommonModule,
    HttpClientModule,    
    
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    StoreModule,
    TextMaskModule,
    //NgxNouisliderModule,
    //NouisliderModule,    
    NgxUploaderModule,
    OwlModule,
    BrowserAnimationsModule,    
    Ng5SliderModule,
    NgxIziToastModule,
    InfiniteScrollModule,
    BlockUIModule.forRoot(),
    StoreModule.forRoot({

      user: userReducer,
      shippingAddresses: shippingAddressesReducer,
      folders: foldersReducer,
      filter: filterReducer,
      products: productsReducer,
      cartItems: cartItemsReducer,
      order: orderReducer,
      product: productReducer,
      ujsagok: ujsagokReducer,
      shopConfig: shopConfigReducer,
      gyartok: gyartokReducer,
      markak: markakReducer,
      alergenek: alergenekReducer,
      favorites: favoritesReducer,
      orders: ordersReducer,
      helpdesks: helpdesksReducer

      /*
      
      
      folders: foldersReducer,
      filter: filterReducer,
      
      
      shopConfig: shopConfigReducer,
      
      orders: ordersReducer,
      favorites: favoritesItemsReducer*/
      
    }),
    RouterModule.forRoot([
      { path: '', component: HomePage },
      //{ path: '',  redirectTo:'signinup', pathMatch:'full' },
      {
        path: 'signinup',
        component: SignInUpPage,
        outlet:'popup'
      },
      {
        path: 'recovery-password',
        component: RecoveryPasswordPage,
        outlet:'popup'
      },
      {
        path: 'static',
        component: StaticPage,
        outlet: 'popup'
      },
      {
        path: 'profile',
        component: ProfilePage,
        outlet: 'popup'
      },
      {
        path: 'helpdesk',
        component: HelpdeskPage,
        outlet: 'popup'
      },
      {
        path: 'address',
        component: AddressPage,
        outlet: 'popup',
        canActivate: [AuthGuard]
      },
      {
        path: 'shipping-address',
        component: ShippingAddressPage,
        outlet: 'popup',
        canActivate: [AuthGuard]
      },
      {
        path: 'product',
        component: ProductPage,
        outlet: 'popup'
      },
      {
        path: 'cart',
        component: CartPage,
        outlet: 'popup',
        canActivate: [AuthGuard]
      },
      {
        path: 'checkout',
        component: CheckoutPage,
        outlet: 'popup',
        canActivate: [AuthGuard]
      },
      {
        path: 'orders',
        component: OrdersPage,
        outlet: 'popup'
      },
      {
        path: 'order',
        component: OrderPage,
        outlet: 'popup2'
      },
      /*{ path: 'password-recovery', component: PwdRecoveryPage },
      { path: '', component: HomePage },
      {
        path: 'order',
        component: OrderPage,
        outlet: 'popup2'
      },
      {
        path: 'product-editor',
        component: ProductEditor,
        outlet: 'popup2',
        canActivate: [AuthGuard]
      },
      {
        path: 'static',
        component: StaticPage,
        outlet: 'popup'
      },
      {
        path: 'address',
        component: AddressPage,
        outlet: 'popup',
        canActivate: [AuthGuard]
      },
      {
        path: 'shipping-address',
        component: ShippingAddressPage,
        outlet: 'popup',
        canActivate: [AuthGuard]
      },
      {
        path: 'profile',
        component: ProfilePage,
        outlet: 'popup'
      },
      {
        path: 'orders',
        component: OrdersPage,
        outlet: 'popup'
      },
      {
        path: 'checkout',
        component: CheckoutPage,
        outlet: 'popup',
        canActivate: [AuthGuard]
      },
    
      
      //{ path: 'orders', component: OrdersPageComponent },
      /*
      { path: 'profile', component: ProfilePageComponent },
      { path: 'address', component: AddressPageComponent },
      { path: 'orders', component: OrdersPageComponent },
      { path: 'product', component: ProductPageComponent },
      { path: 'cart', component: CartPageComponent },
      { path: 'shop-items', component: ShopItemsPageComponent }*/
    ]),
  ],
  providers: [
    UserService,
    FolderService,
    ProductService,
    CartService,
    OrderService,
    CheckoutService,
    ConfigService,  
    FavoritesService,    
    FileService,
    AuthGuard,
    GoogleAnalyticsEventsService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
  ],
  
  bootstrap: [AppComponent]
})
export class AppModule { }

