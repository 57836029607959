import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Product } from '../models/product.model';
import { User } from '../models/user.model';

import { Observable, Subject } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { HttpErrorResponse } from '@angular/common/http';
import { AppState } from '../app-state';
import { API_URL } from '../utils/constants';
import { EMAIL_REGEX, PASSWORD_REGEX, PRODUCT_IMAGES_DIR } from '../utils/constants';

@Injectable()
export class CheckoutService {
  stepChangeObservable = new Subject<number>();

  constructor(
    private httpClient: HttpClient,
    private store: Store<AppState>
  ) {

  }

  
  private extractData(res: Response) {
    let body = res.json();
    return body || {};
  }

  private handleError(error: any): Promise<any> {
    console.log('An error occurred', error); // for demo purposes only
    return Promise.reject(error.message || error);
  }
}
