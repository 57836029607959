import { Component, OnInit, ElementRef, Input } from '@angular/core';
import { Router, ActivatedRoute, Params, NavigationExtras } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OrderSettingsModal } from '../order-settings/order-settings.modal';
import { EMAIL_REGEX, PASSWORD_REGEX, PRODUCT_IMAGES_DIR } from '../../utils/constants';
import { FolderUtil } from '../../utils/folder-util';
import { CartService } from '../../services/cart.service';
import { Filter } from '../../models/filter.model';
import { AppState } from '../../app-state';
import { Store } from '@ngrx/store';
import { CartItem } from '../../models/cartitem.model';
import { Order } from 'src/app/models/order.model';
import { SZALLMOD_HAZHOZSZALLITAS, SZALLMOD_SZEMELYESATVETEL } from '../../utils/constants';
import { User } from 'src/app/models/user.model';
import { ShippingAddress } from 'src/app/models/shipping-address.model';
import { Utils } from 'src/app/utils/utils';

declare var $: any;

@Component({
    selector: 'toolbar-cart',
    templateUrl: './toolbar-cart.component.html'

})
export class ToolbarCartComponent implements OnInit {
    product_images_url: string = PRODUCT_IMAGES_DIR;
    subscribeCartItems: any;
    subscribeOrder: any;
    subscribeShipAddrs: any;
    subscribeUser: any;
    shippingAddr: ShippingAddress;
    cartItems: CartItem[] = [];
    order: Order = new Order();
    user: User;
    szemelyesAtvetel: string = SZALLMOD_SZEMELYESATVETEL;
    constructor(
        private router: Router,
        private modalService: NgbModal,
        private elementRef: ElementRef,
        private cartService: CartService,
        private store: Store<AppState>) {
    }

    ngOnInit() {
        this.subscribeCartItems = this.store.select('cartItems').subscribe(
            cartItems => {
                if (cartItems) {
                    this.cartItems = cartItems;
                } else {
                    this.cartItems = [];
                }
            },
            error => console.log(error)
        );

        this.subscribeOrder = this.store.select('order').subscribe(
            order => {
                if (order) {
                    this.order = order;
                    if (this.order.shippingAddressId) {
                        //this.subscrAddress();
                    }
                }
            },
            error => console.log(error)
        );

        this.subscribeUser = this.store.select('user').subscribe(
            user => {
                if (user != null && user.id > 0) {
                    this.user = user;
                } else {
                    this.user = null;
                }
            },
            error => console.log(error)
        );


    }

    removeCartItem(cartItem: CartItem) {
        this.cartService.removeCartItem(cartItem);
    }

    onClickShowCartDetails() {
        Utils.closeRightPanel($);
        let navigationExtras: NavigationExtras = {
            queryParams: {}
        };
        this.router.navigate([{ outlets: { popup: 'cart' } }], navigationExtras);
    }

    onClickCartItem(cartItem: any) {
        Utils.closeRightPanel($);
        let navigationExtras: NavigationExtras = {
            queryParams: { 'id': cartItem.cikkszam }
        };
        this.router.navigate([{ outlets: { popup: 'product' } }], navigationExtras);
    }

    onClickCheckout() {
        Utils.closeRightPanel($);
        let navigationExtras: NavigationExtras = {
            queryParams: {}
        };
        this.router.navigate([{ outlets: { popup: 'checkout' } }], navigationExtras);
    }

    onClickChangeSzall() {
        if (this.user && this.user.id > 0) {
            Utils.closeRightPanel($);
            this.modalService.open(OrderSettingsModal, { size: 'lg' });
        } else {

        }
    }

    onClickSignInUp() {
        let navigationExtras: NavigationExtras = {};
        this.router.navigate([{ outlets: { popup: 'signinup' } }], navigationExtras);
    }

    ngOnDestroy(): void {
        this.subscribeCartItems.unsubscribe();
        this.subscribeOrder.unsubscribe();
    }
}
