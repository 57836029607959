import { Action } from '@ngrx/store';

import { Filter } from '../models/filter.model';
import { ActionWithPayload } from './action-with-payload';


export const CONFIG_UPDATED = 'CONFIG_UPDATED';
export function shopConfigReducer(state: any = null, action: ActionWithPayload<any>): any {
  switch (action.type) {
    case CONFIG_UPDATED:                 

      return Object.assign({},action.payload);      
    default:
      return state;
  }
}