import { Component, OnInit, Renderer } from '@angular/core';
import { Router, ActivatedRoute, Params, NavigationExtras, NavigationEnd } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from '../../app-state';
import { Filter } from 'src/app/models/filter.model';
import { NgxIzitoastService } from 'ngx-izitoast';
import { GoogleAnalyticsEventsService } from '../../services/google-analytics.service';
import { Title } from "@angular/platform-browser";
import { User } from 'src/app/models/user.model';

declare var $: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html'
  //styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  keywordMinLength: number = 3;
  keywordInputValue: string = '';
  currentPath: string;
  subscribeCartItems: any;
  subscribeFilter: any;
  subscribeUser: any;
  subscribeFavoritesItems:any;
  cartItems: any = [];
  favorites: any = [];
  filter: Filter;
  user: User;
  
  constructor(
    private titleService: Title,
    private googleAnalyticsEventsService: GoogleAnalyticsEventsService,
    public iziToast: NgxIzitoastService,
    private renderer: Renderer,
    private store: Store<AppState>,
    private router: Router) {
    router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.currentPath = val.url;
      }
    });
  }

  ngOnInit() {    

    this.subscribeFilter = this.store.select('filter').subscribe(
      filter => {
        if (filter) {          
          this.filter = filter;
          this.keywordInputValue = this.filter.keyword;
        } 
      },
      error => console.log(error)
    );

    this.subscribeCartItems = this.store.select('cartItems').subscribe(
      cartItems => {
        if (cartItems) {
          this.cartItems = cartItems;
        } else {
          this.cartItems = [];
        }
      },
      error => console.log(error)
    );

    this.subscribeFavoritesItems = this.store.select('favorites').subscribe(
      favorites => {
        if (favorites) {          
          this.favorites = favorites;          
        } else {
          this.favorites = [];          
        }
      },
      error => console.log(error)
    );    
    this.subscribeUser = this.store.select('user').subscribe(
      user => {
        if (user != null && user.id > 0) {
          this.user = user;
        } else {
          this.user = null;
        }
      },
      error => console.log(error)
    );

  }

  onClickLogo() {    
    this.titleService.setTitle('Egyedhús webáruház');
    if (this.currentPath == '/') {
      //this.store.dispatch({ type: 'FILTER_UPDATED', payload: new Filter() });
      this.router.navigate(['/']);
    } else if (this.currentPath.indexOf('address') > -1 || this.currentPath.indexOf('profile') > -1) {
      
      this.router.navigate(['/']);
    } else {
      this.router.navigate([{ outlets: { popup: null } }]);
    }
  }

  downloadAkciosPdf() {

    window.open('assets/pdf/akcios-ujsag.pdf','_blank');
  }

  onClickSearchByKeyword() {
    this.closeMobileMenu();
    this.updateFilter();
  }

  onClickClearKeyword() {
    this.keywordInputValue = '';    
    this.filter.keyword = this.keywordInputValue;
    this.store.dispatch({ type: 'FILTER_UPDATED', payload: this.filter });
  }

  onBlurSearchInput(e) {  //ezt ne
  }

  onKeyupSearchInput(e) {
    if (e.keyCode === 13) {
      this.closeMobileMenu();
      this.updateFilter()
    }
  }

  closeMobileMenu() {
    $('.mobile-menu').toggleClass('open');
    $('.mobile-menu-toggle').find('i').toggleClass('icon-x')
  }
  
  updateFilter() {
    if(this.keywordInputValue.length < this.keywordMinLength && this.keywordInputValue.length >0) { 
      
      this.iziToast.show({title: 'Kulcsszó min '+this.keywordMinLength+' karakter.', position:'topCenter', timeout: 2000, close:false, color:'red'});

    } else { 
      this.googleAnalyticsEventsService.emitEvent('search', this.keywordInputValue, '', null);    

      this.filter.keyword = this.keywordInputValue;
      this.store.dispatch({ type: 'FILTER_UPDATED', payload: this.filter });
    }
  }

  onClickStaticPageLink(pageId) {
    this.closeMobileMenu();
    let navigationExtras: NavigationExtras = {
      queryParams: { 'p': pageId }
    };
    this.router.navigate([{ outlets: { popup: 'static' } }], navigationExtras);
  }

  ngOnDestroy(): void {
    this.subscribeCartItems.unsubscribe();
    this.subscribeFavoritesItems.unsubscribe();
    this.subscribeFavoritesItems.unsubscribe();
    this.subscribeUser.unsubscribe();
  }
}
