import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params, NavigationExtras } from '@angular/router';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { AppState } from '../../../app-state';
import { User } from '../../../models/user.model';
import { Order } from '../../../models/order.model';
import { UserService } from '../../../services/user.service';
import { OrderService } from '../../../services/order.service';

declare var iziToast: any;
declare var $: any;
declare var velocity: any;

@Component({
  templateUrl: './orders-page.html',
  //styleUrls: ['./layout.component.css']
})
export class OrdersPage implements OnInit {
  subscribeOrders: any;
  subscribeUser:any;
  orders: Order[] = []; 
  visibledOrders: Order[] = []; 
  user: User; 
  currentPage: number = 1;
  pageSize: number = 6;
  listItemsNum: number = 0;
  collectionSize: number = 0;

  constructor(
    private router: Router,
    private userService: UserService,
    private orderService: OrderService,
    private fb: FormBuilder,
    private store: Store<AppState>) {
  }

  ngOnInit() {
    var $scrollTop = $('.scroll-to-top-btn');
    $scrollTop.removeClass('visible');
    this.subscribeUser = this.store.select('user').subscribe(
      user => {
        if(user != null) { 
          this.user = user;        
        }
      },
      error => console.log(error)
    );
    this.subscribeOrders = this.store.select('orders').subscribe(
      orders => {
        if(orders) { 
          this.orders = orders;    
          this.collectionSize = this.orders.length;    
          this.setVisibledOrders();
          console.log(this.orders);
        }
      },
      error => console.log(error)
    );
    this.orderService.getOrders();
  }
  
  onClickOrder(order: any) {
    let navigationExtras: NavigationExtras = {
      queryParams: { 'id': order.id }
    };
    this.router.navigate([{ outlets: { popup2: 'order' } }], navigationExtras);

  }

  onClickMenuItem(menuItemId: string) {        
    let navigationExtras: NavigationExtras = {
      queryParams: {  }
    };
    this.router.navigate([{ outlets: { popup: menuItemId } }], navigationExtras);
    //this.router.navigate(['/'+menuItemId]);
  }

  onPageChange(page: number) {
    //this.scrollTop('.container');
    this.currentPage = page;   
    this.setVisibledOrders();
  }

  setVisibledOrders() {
    this.visibledOrders = this.orders.slice((this.currentPage - 1) * this.pageSize, (this.currentPage - 1) * this.pageSize + this.pageSize);
    console.log(this.visibledOrders);
  }

  
  /*scrollTop(target) {
    $(target).velocity( 'scroll', {
      container: $( '.outerMainContainer' ),				
      duration: 1200,
      easing: 'easeOutExpo',
      mobileHA: false
    } );    
  }*/

  ngOnDestroy(): void {
    this.subscribeOrders.unsubscribe();    
    this.subscribeUser.unsubscribe();    
  }
}
