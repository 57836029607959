import { Component, OnInit, Output, ViewChild, EventEmitter, ViewContainerRef, ComponentFactoryResolver, ComponentFactory, ComponentRef } from '@angular/core';
import { AppState } from '../../app-state';
import { CheckoutService } from '../../services/checkout.service';
import { FormControl, FormArray, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute, Params, NavigationExtras, NavigationEnd } from '@angular/router';
import { CartItem } from '../../models/cartitem.model';
import { Product } from '../../models/product.model';
import { Store } from '@ngrx/store';
import { FoldersComponent } from '../../components/folders/folders.component';
import { Folder } from '../../models/folder.model';
import { User } from '../../models/user.model';
import { Order } from '../../models/order.model';
//import { ADDRESS_MAX_LENGTH, CITY_MAX_LENGTH, ZIP_MAX_LENGTH, NAME_MAX_LENGTH } from '../../utils/constants';
declare var $: any;
declare var velocity: any;
@Component({
  selector: 'address',
  templateUrl: './address.component.html',

})
export class AddressComponent implements OnInit {
  order: Order;
 // addressForm: FormGroup;
  subscribeStepObsrv: any;
  subscribeUser: any;
  subscribeOrder: any;
  subscribeShopConfig: any;
  user: User;
  submitClicked: boolean = true;
  @Output() onNextToShipping = new EventEmitter();  
  @Output() onAddressValid = new EventEmitter();

  /*zipMaxLength: number = ZIP_MAX_LENGTH;
  cityMaxLength: number = CITY_MAX_LENGTH;
  addressMaxLength: number = ADDRESS_MAX_LENGTH
  nameMaxLength: number = NAME_MAX_LENGTH;*/
  //zipNumberMask = [/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];

  constructor(
    private checkoutService: CheckoutService,
    private fb: FormBuilder,
    private router: Router,
    private store: Store<AppState>) {



    this.subscribeStepObsrv = this.checkoutService.stepChangeObservable.subscribe(value => {
      console.log(value);
      if (value == 1) {
        this.submitClicked = false;               
        this.onAddressValid.emit(true);
        //this.store.dispatch({ type: 'ORDER_UPDATED', payload: this.order });
        /*if (this.addressForm.valid) {
          this.onAddressValid.emit(true);
        } else {
          this.onAddressValid.emit(false);
        }*/
      }
    });

    //this.createForm();
  }


  ngOnInit() {
    this.subscribeUser = this.store.select('user').subscribe(
      user => {
        if (user != null && user.id > 0) {          
          this.user = user;          
          this.initSubscrOrder();
        } else {
          this.user = null;
        }
      },
      error => console.log(error)
    );

  }
  

  initSubscrOrder() {
    this.subscribeOrder = this.store.select('order').subscribe(
      order => {      
        if (order == null) {
         // this.initOrder();
         // this.initSubscrConfig();
        } else {
          this.order = order;
         // this.initSubscrConfig();
        }
        console.log('============>',order);
      },
      error => console.log(error)
    );
  }

  
  onClickNext() {      
    this.store.dispatch({ type: 'ORDER_UPDATED', payload: this.order });
    this.onNextToShipping.emit();
  }

  onClickEdit() {    
    let navigationExtras: NavigationExtras = {
      queryParams: { }
    };
    this.router.navigate([{ outlets: { popup: 'address' } }], navigationExtras);
  }

  onClickBackToCart() {    
    this.store.dispatch({ type: 'ORDER_UPDATED', payload: this.order });
    let navigationExtras: NavigationExtras = {
      queryParams: {}
    };
    this.router.navigate([{ outlets: { popup: 'cart' } }], navigationExtras);
  }

  /*onChanges(): void {
    this.addressForm.valueChanges.subscribe(val => {

    });
  }

  initSubscrConfig() {
    this.subscribeShopConfig = this.store.select('shopConfig').subscribe(
      shopConfig => {
        if (shopConfig) {
          var defMode = shopConfig.shippingMethods.find(m => m.default);
          this.order.shippingMode = defMode.id;
          this.order.shippingCost = defMode.cost;
        }
      },
      error => console.log(error)
    );
  }

 


  initOrder() {
    console.log('//////////initorder');
    this.order = new Order();
    this.order.paytypeCode = this.user.paytypeCode;    
    this.store.dispatch({ type: 'ORDER_UPDATED', payload: this.order });
  }


  initForm() {
   
  }

  createForm() {

    this.addressForm = this.fb.group({
      name: new FormControl('', [Validators.required]),
      city: new FormControl('', [Validators.required]),
      zip: new FormControl('', [Validators.required]),
      address: new FormControl('', [Validators.required]),
    }
    );
  }
  */

  ngOnDestroy(): void {
    console.log('address comp destroy');
    this.subscribeUser.unsubscribe();
    this.subscribeOrder.unsubscribe();
    
    /*if (this.subscribeShopConfig) {
      this.subscribeShopConfig.unsubscribe();
    }*/
//    this.subscribeStepObsrv.unsubscribe();    
  }
}
