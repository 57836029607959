import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AppState } from './app-state';
import { User } from './models/user.model';
import { Observable, Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import { Router, ActivatedRoute, Params, NavigationExtras, NavigationEnd, Event } from '@angular/router';;

@Injectable()
export class AuthGuard implements CanActivate {
  subscribeUser: any;
  user: User;
  constructor(
      private store: Store<AppState>,
      private router: Router) {

    this.subscribeUser = this.store.select('user').subscribe(
      user => {
        if(user) { 
          this.user = user;
        }
      },
      error => console.log(error)
    );
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      
      /*if(state.url.indexOf('address') > -1 && this.user && this.user.role.indexOf('Admin') > -1) {
        this.router.navigate(['/']);
        return false;
      }
      if(state.url.indexOf('checkout') > -1 && this.user && this.user.role.indexOf('Admin') > -1) {
        this.router.navigate(['/']);
        return false;
      }
      if(state.url.indexOf('editor') > -1 && this.user && this.user.role.indexOf('User') > -1) {
        this.router.navigate(['/']);
        return false;
      }
      if(state.url.indexOf('cart') > -1 && this.user && this.user.role.indexOf('Admin') > -1) {
        this.router.navigate(['/']);
        return false;
      }*/
    
    return true;
  }

  ngOnDestroy(): void {
    this.subscribeUser.unsubscribe();
  }
}