import { Component, OnInit, Renderer } from '@angular/core';
import { Router, ActivatedRoute, Params, NavigationExtras, NavigationEnd } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from '../../app-state';
import { Title } from "@angular/platform-browser";
declare var $: any;

@Component({
  selector: 'app-header-2',
  templateUrl: './header-2.component.html'
  //styleUrls: ['./header.component.css']
})
export class Header2Component implements OnInit {
  currentPath: string;
  subscribeCartItems: any;
  subscribeFavoritesItems:any;
  cartItems: any = [];
  favorites: any = [];
  constructor(
    private titleService: Title,
    private renderer: Renderer,
    private store: Store<AppState>,
    private router: Router) {
    router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {

        this.currentPath = val.url;

      }
    });
  }

  ngOnInit() {    
    this.subscribeCartItems = this.store.select('cartItems').subscribe(
      cartItems => {
        if (cartItems) {
          this.cartItems = cartItems;
        } else {
          this.cartItems = [];
        }
      },
      error => console.log(error)
    );

    this.subscribeFavoritesItems = this.store.select('favorites').subscribe(
      favorites => {
        if (favorites) {          
          this.favorites = favorites;          
        } else {
          this.favorites = [];          
        }
      },
      error => console.log(error)
    );    

    
  }

  onClickLogo() {    
    this.titleService.setTitle('Egyedhús webáruház');
    this.router.navigate([{ outlets: { popup: null } }]);
    
  }

  ngOnDestroy(): void {
    this.subscribeCartItems.unsubscribe();
    this.subscribeFavoritesItems.unsubscribe();
  }
}
