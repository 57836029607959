import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params, NavigationExtras } from '@angular/router';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { User } from '../../../models/user.model';
import { UserService } from '../../../services/user.service';
import { HttpErrorResponse } from '@angular/common/http';
import { USER_UPDATED } from '../../../reducers/user';
import { AppState } from '../../../app-state';
import { Store } from '@ngrx/store';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { NgxIzitoastService } from 'ngx-izitoast';
import { CartItem } from 'src/app/models/cartitem.model';


@Component({
  selector: 'toolbar-account',
  templateUrl: './toolbar-account.component.html'

})
export class ToolbarAccountComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI;

/*  confirmPasswordFormControl: FormControl;
  signinForm: FormGroup;
  lostPasswordForm: FormGroup;
  user: User = null;
  signupForm: FormGroup;
  cityFormControl: FormControl;
  zipFormControl: FormControl;
  addressFormControl: FormControl;
  nameFormControl: FormControl;
  companyAddressFormControl: FormControl;
  taxNumberFormControl: FormControl;
  emailFormControl: FormControl;
  contactNameFormControl: FormControl;
  phoneNumberFormControl: FormControl;
  passwordFormControl: FormControl;
  signupSubmitClicked: boolean;
  signinSubmitClicked: boolean;
  resetPwSubmitClicked: boolean;
  showLostPasswordSentMsg: boolean;
  signupInProgress: boolean;
  successSignup: boolean;
  
  lostPassword: boolean;
  taxNumberMask = [/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, '-', /[0-9]/, '-', /[0-9]/, /[0-9]/];
  zipNumberMask = [/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];

  nameMaxLength: number = NAME_MAX_LENGTH;
  emailMaxLength: number = EMAIL_MAX_LENGTH;
  passwordMaxLength: number = PASSWORD_MAX_LENGTH;
  cityMaxLength: number = CITY_MAX_LENGTH;
  zipMaxLength: number = ZIP_MAX_LENGTH;
  addressMaxLength: number = ADDRESS_MAX_LENGTH;
  phoneNumberMaxLength: number = PHONE_MAX_LENGTH;
  contactNameMaxLength: number = CONTACT_NAME_MAX_LENGTH;
*/
  subscribeUser: any;
  subscribeCartItems: any;
  user: User = null;
  cartItems: CartItem[] = [];

  constructor(
    public iziToast: NgxIzitoastService,
    private store: Store<AppState>,
    private userService: UserService,
    private router: Router,
    private fb: FormBuilder) {

    
    this.subscribeUser = this.store.select('user').subscribe(
      user => {
        // console.log(user);         
        if (user != null && user.id > 0) {
          this.user = user;
        } else {
          this.user = null;
        }
      },
      error => console.log(error)
    );

   
  }

  ngOnInit() {

  }

  onFocusEmail() {
    
  }


  onClickLogout() {
    localStorage.removeItem('token');    
    this.iziToast.show({title: this.user.contactPerson+ " kijelentkezett!", position:'topCenter', timeout: 2000});
    this.store.dispatch({ type: USER_UPDATED, payload: new User() });
    this.user = null;
    
  }

  onClickSignInUp() {
    let navigationExtras: NavigationExtras = {     };
    this.router.navigate([{ outlets: { popup: 'signinup' } }], navigationExtras);    
  }
  
  onClickMenuItem(menuItemId: string) {    
    let navigationExtras: NavigationExtras = {
      queryParams: {}
    };
    this.router.navigate([{ outlets: { popup: menuItemId } }], navigationExtras);
  }

  

  ngOnDestroy(): void {
    this.subscribeUser.unsubscribe();
  }
}
