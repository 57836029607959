import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../app-state';
import { Filter } from '../../models/filter.model';
import { FormControl, FormArray, FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'elsefilter',
  templateUrl: './elsefilter.component.html'

})
export class ElsefilterComponent implements OnInit {
  filter: Filter = new Filter();
  subscribeFilter: any;
  form: FormGroup;

  constructor(
    private store: Store<AppState>,
    private formBuilder: FormBuilder,
  ) { }

  ngOnInit() {
    
    this.createForm();
    this.subscribeFilter = this.store.select('filter').subscribe(
      filter => {        
        if (filter) {
          this.filter = filter;
          this.initForm();
        } else {
        }
      },
      error => console.log(error)
    );
  }

  
  createForm() {
    this.form = this.formBuilder.group({
      ujdonsag: new FormControl(false, []),
      kifuto: new FormControl(false, [])
    }
    );
  }

  onChangeUjdonsag() {
    console.log(this.form.controls['ujdonsag'].value);
    this.filter.ujdonsag = this.form.controls['ujdonsag'].value;
    this.store.dispatch({ type: 'FILTER_UPDATED', payload: this.filter });
  }

  onChangeKifuto() {
    console.log(this.form.controls['kifuto'].value);
    this.filter.kifuto = this.form.controls['kifuto'].value;
    this.store.dispatch({ type: 'FILTER_UPDATED', payload: this.filter });
  }

  /*onChangeAlergenekSelect(e) {
    if (!e.checked.value) {
      this.filter.alergenek = this.filter.alergenek.filter(u => u != e.alergen.value);
    } else {
      if (!this.filter.alergenek.find(u => u == e.alergen.value)) {
        this.filter.alergenek.push(e.alergen.value);
      }
    }
    this.store.dispatch({ type: 'FILTER_UPDATED', payload: this.filter });
  }
*/
  initForm() {
    console.log('intform!!!!!!!!!!!!!');
    console.log(this.filter);
    this.form.controls['ujdonsag'].setValue(this.filter.ujdonsag);
    this.form.controls['kifuto'].setValue(this.filter.kifuto);

  }

  ngOnDestroy(): void {
    this.subscribeFilter.unsubscribe();
  }
}
