import { Component, OnInit, ViewChild, ViewContainerRef, ComponentFactoryResolver, ComponentFactory, ComponentRef } from '@angular/core';
import { AppState } from '../../app-state';
import { OrderService } from '../../services/order.service';
import { ConfigService } from '../../services/config.service';
import { FormControl, FormArray, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute, Params, NavigationExtras, NavigationEnd } from '@angular/router';
import { CartItem } from '../../models/cartitem.model';
import { Order } from '../../models/order.model';
import { User } from '../../models/user.model';
import { HttpErrorResponse } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { FoldersComponent } from '../../components/folders/folders.component';
import { Folder } from '../../models/folder.model';
declare var $: any;

@Component({
  templateUrl: './order-page.html',

})
export class OrderPage implements OnInit {
  subscribeShopConfig: any;
  subscribeUser: any;
  orderId: any;
  order: Order = new Order();
  orderStatus: string;
  shippingAddr: any = {};
  invoicedAddr: any = {};
  paymentMode: any = {};
  orderSum: number = 0;
  user: User;
  nettoSum: number = 0;
  bruttoSum: number = 0;
  afaSum: number = 0;
  shopConfig: any;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private orderService: OrderService,
    private configService: ConfigService,
    private store: Store<AppState>) {
  }

  ngOnInit() {    
    
    this.paymentMode.title = '';
    this.order.cartItems = [];
    this.route.queryParamMap.subscribe(params => {
      if ((this.orderId && this.orderId != params.get('id')) || !this.orderId) {
        this.orderId = params.get('id');
        this.orderService.getOrder(this.orderId).subscribe(
          order => {
            console.log(order);
            this.order = order;
            this.orderService.doSortDesc(this.order.orderLogList);
            this.orderStatus = this.order.orderLogList[0].statusz;
            //this.order.alap = Math.floor(this.order.alap);
            //this.order.brutto = Math.floor(this.order.brutto);
            var orderItem :any;
            for(orderItem of this.order.cartItems) {
              orderItem.kedvarSum = Math.floor(orderItem.kedvar * orderItem.menny);
              orderItem.kedvarSumVisszaigazolt = Math.floor(orderItem.kedvar * orderItem.smeny);
              if(this.orderStatus == 'S') {
                this.nettoSum = this.nettoSum + orderItem.kedvarSumVisszaigazolt;
                this.bruttoSum = this.bruttoSum + Math.floor(orderItem.kedvbrut * orderItem.smeny);;
              } else {
                this.nettoSum = this.nettoSum + orderItem.kedvarSum;
                this.bruttoSum = this.bruttoSum + Math.floor(orderItem.kedvbrut * orderItem.menny);;
              }
            }
            this.afaSum = this.bruttoSum - this.nettoSum;

            //this.shippingAddr = JSON.parse(this.order.shippingAddrJson);
            //this.invoicedAddr = JSON.parse(this.order.invoicedAddrJson);
            //this.initConfigSubscr();
            //this.calcOrderSum();
          },
          (err: HttpErrorResponse) => {

          }
        );
        //this.blockUI.start();
      }
    });
    this.subscribeUser = this.store.select('user').subscribe(
      user => {
        if (user != null && user.id > 0) {          
          this.user = user;                    
        } 
      },
      error => console.log(error)
    );

    this.subscribeShopConfig = this.store.select('shopConfig').subscribe(
      shopConfig => {
        if (shopConfig) {          
          this.shopConfig = shopConfig;                    
        }
      },
      error => console.log(error)
    );
  }

  calcOrderSum() {
    var item: any;

   /*for(item of this.order.cartItems) {

      if(this.orderStatus == 0) { 
        this.orderSum = this.orderSum + item.qty * item.partnerPrice;
      } else {
        this.orderSum = this.orderSum + item.confirmed_qty * item.partnerPrice;         
      }      
    }*/
    //this.orderSum = this.orderSum + this.order.shippingCost;
  }

  /*initConfigSubscr() {
  
  }*/

  onClickClose() {
    this.router.navigate([{ outlets: { popup2: null } }]);
  }


  ngOnDestroy(): void {
    this.subscribeShopConfig.unsubscribe();
    this.subscribeUser.unsubscribe();
  }
}
