import { Component, OnInit, ViewChild, ViewContainerRef, ComponentFactoryResolver, ComponentFactory, ComponentRef } from '@angular/core';
import { AppState } from '../../app-state';
import { Title} from "@angular/platform-browser";
import { FileService } from '../../services/file.service';
//import { ConfigService } from '../../services/config.service';
import { FormControl, FormArray, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute, Params, NavigationExtras, NavigationEnd } from '@angular/router';
import { User } from '../../models/user.model';
import { Page } from '../../models/page.model';
import { HttpErrorResponse } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { NgxIzitoastService } from 'ngx-izitoast';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
declare var $: any;

@Component({
  templateUrl: './static-page.html',

})
export class StaticPage implements OnInit {
  pageId: any;
  content:string = '';
  title:string = '';
  page: Page = new Page();
  subscribeUser: any;
  user: User = null;
  @BlockUI() blockUI: NgBlockUI;

  constructor(
    public iziToast: NgxIzitoastService,
    private titleService: Title,
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private fileService: FileService,    
    private store: Store<AppState>) {
      this.subscribeUser = this.store.select('user').subscribe(
        user => {
          if (user != null && user.id > 0) {
            this.user = user;
          } 
        },
        error => console.log(error)
      );

      this.route.queryParamMap.subscribe(params => {
        if ((this.pageId && this.pageId != params.get('p')) || !this.pageId) {
          this.pageId = params.get('p');          
          this.fileService.getPage(this.pageId).subscribe(
            page => {             
              this.page = page;
              this.content = page.content;
              this.title = page.title;
              
            },
            (err: HttpErrorResponse) => {
              console.log(err);
              
            }
        );
        }
      });
  }


  onClickSaveContent() {
    this.blockUI.start();
    var page = new Page();
    page.title = this.title;
    page.content = this.content;
    page.id = this.pageId;
    this.fileService.savePage(page).subscribe(
      page => {
        this.blockUI.stop();
        this.page = page;        
        this.iziToast.show({title: "Tartalom elmentve!", position:'topCenter', timeout: 2000});
      },
      (err: HttpErrorResponse) => {
        this.blockUI.stop();
        this.iziToast.show({title: "Hiba!", position:'topCenter', timeout: 2000});
      }
    );
  }

  ngOnInit() {    
    //var $scrollTop = $('.scroll-to-top-btn');
    //$scrollTop.removeClass('visible');
  }

  ngOnDestroy(): void {    
    this.subscribeUser.unsubscribe();
  }
}
