import { Action } from '@ngrx/store';
import { Order } from '../models/order.model';
import { ActionWithPayload } from './action-with-payload';

export const ORDERS_UPDATED = 'ORDERS_UPDATED';
//export const CARS_ADD = 'CARS_ADD';

export function ordersReducer(state: Order[] = [], action: ActionWithPayload<Order[]>) {
  switch (action.type) {
    case ORDERS_UPDATED:          
      var resultArray:Array<Order>=[] ;
      action.payload.forEach( i => { 
        resultArray.push(i);
      });
      return resultArray;    
     default:
	return state;
  }
}