import { Action } from '@ngrx/store';
import { Product } from '../models/product.model';
import { ActionWithPayload } from './action-with-payload';

export const PRODUCT_UPDATED = 'PRODUCT_UPDATED';

export function productReducer(state: Product = null, action: ActionWithPayload<Product>) {
  switch (action.type) {
    case PRODUCT_UPDATED:          
      return Object.assign({}, action.payload);                
    default:
	  return state;
  }
}