import { Marka } from '../models/marka.model';
import { ActionWithPayload } from './action-with-payload';

export const MARKAK_UPDATED = 'MARKAK_UPDATED';

export function markakReducer(state: Marka[] = [], action: ActionWithPayload<Marka[]>) {
  switch (action.type) {
    case MARKAK_UPDATED:          
      var resultArray:Array<Marka>=[] ;
      action.payload.forEach( i => { 
        resultArray.push(i);
      });
      return resultArray;
     default:
	return state;
  }
}