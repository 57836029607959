import { Action } from '@ngrx/store';
import { User } from '../models/user.model';
import { ActionWithPayload } from './action-with-payload';

export const USER_UPDATED = 'USER_UPDATED';

export function userReducer(state: User = null, action: ActionWithPayload<User>) {
  switch (action.type) {
    case USER_UPDATED:      
      return Object.assign({}, action.payload);                
    default:
	  return state;
  }
}