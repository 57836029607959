import { environment } from '../../environments/environment';

export const EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&�*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
export const PASSWORD_REGEX = /^.{6,40}$/;
export const TAX_REGEX = /^.{13}$/;
export const API_URL = environment.API_URL;

export const PRODUCT_IMAGES_DIR = 'assets/product_images';
//export const PRODUCT_IMAGES_DIR = 'http://egyedhus.mobiz.hu/assets/product_images';


export const NEV1_MAX_LENGTH = 40;
export const NEV_MAX_LENGTH = 80;
export const EMAIL_MAX_LENGTH = 60;
export const CONTACT_PERSON_MAX_LENGTH = 40;
export const TEL1_MAX_LENGTH = 15;
export const HELYSEG_MAX_LENGTH = 40;
export const CIM_MAX_LENGTH = 20;
export const PASSWORD_MAX_LENGTH = 40;
export const ORDER_NOTE_LENGTH = 80;


export const SZALLMOD_HAZHOZSZALLITAS = 'K';
export const SZALLMOD_SZEMELYESATVETEL = 'E';
export const SZALLDAYS_MIN = 1;
export const SZALLDAYS_MAX = 60;

export const CASH_PAYMENT_MODE = 'FIZK';
export const BANKTRANSFER_PAYMENT_MODE = 'FIZK';

export const PRODUCT_DATA_EXPIRED_TIME_SEC = 3600; // ha lejárt és megnyitja az oldal tab-ot akkor újratölti a product store-t, 
//a termék részletes oldal a lejáratig a store-ból veszi az adatokat

export const TEJMENTES_IMAGENAME = 'LAKTOZ';
export const SZOJAMENTES_IMAGENAME = 'SZOJA';
export const TOJASMENTES_IMAGENAME = 'TOJAS';
export const GLUTENMENTES_IMAGENAME = 'GLUTEN';


