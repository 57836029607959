import { Alergen } from '../models/alergen.model';
import { ActionWithPayload } from './action-with-payload';

export const ALERGENEK_UPDATED = 'ALERGENEK_UPDATED';

export function alergenekReducer(state: Alergen[] = [], action: ActionWithPayload<Alergen[]>) {
  switch (action.type) {
    case ALERGENEK_UPDATED:          
      var resultArray:Array<Alergen>=[] ;
      action.payload.forEach( i => { 
        resultArray.push(i);
      });
      return resultArray;
     default:
	return state;
  }
}