import { Component, OnInit, ViewChild, ViewContainerRef, ComponentFactoryResolver, ComponentFactory, ComponentRef } from '@angular/core';
import { AppState } from '../../app-state';
//import { Filter } from '../../models/filter.model';
//import { Product } from '../../models/product.model';
import { Store } from '@ngrx/store';
import { FoldersComponent } from '../../components/folders/folders.component';
import { Folder } from '../../models/folder.model';
import { FolderService } from '../../services/folder.service';
//import { MAXOLEN_ID, INNOTEC_ID, APEX_ID,TERMEK_KATEGORIAK, AMERICOL_ID, KERAMAXI_ID } from '../../utils/constants';
import { CartItem } from '../../models/cartitem.model';
import { Utils } from 'src/app/utils/utils';
import { GoogleAnalyticsEventsService } from '../../services/google-analytics.service';

declare var velocity: any;
declare var $: any;

@Component({
  templateUrl: './home.page.html',  
})
export class HomePage implements OnInit {
  //@ViewChild('cookieLaw') private cookieLawEl: any;
  subscribeFolders: any;
  subscribeFilter: any;
  subscribeCartItems: any;
  subscribeProduct: any;
  componentRef: ComponentRef<FoldersComponent>;
  selectedFolders: Folder[] = [];
  folders: Folder[] = [];
  cartItems: CartItem[] = [];
  filterCounter: number = 0;
  filterJSON:string;
  //filter: Filter;
  //product: Product;
  title: string = 'Termékeink'; 
  @ViewChild("foldersContainer", { read: ViewContainerRef, static: false }) container;

  constructor(
    private googleAnalyticsEventsService: GoogleAnalyticsEventsService,
    private folderService: FolderService,
    private resolver: ComponentFactoryResolver,
    private store: Store<AppState>) {
  }

  ngOnInit() {
    this.googleAnalyticsEventsService.emitEvent('home', 'open', '', null);    

    this.subscribeFilter = this.store.select('filter').subscribe(
      filter => {
        if (filter) {
          if(JSON.stringify(filter)!=this.filterJSON) {
            this.scrollTop();
            this.filterJSON = JSON.stringify(filter);
          }
          this.selectedFolders = [];
        }
      },
      error => console.log(error)
    );
    
    this.subscribeCartItems = this.store.select('cartItems').subscribe(
      cartItems => {
        if (cartItems) {
          this.cartItems = cartItems;
        } else {
          this.cartItems = [];
        }
      },
      error => console.log(error)
    );

    this.subscribeFolders = this.store.select('folders').subscribe(
      folders => {      
        if (folders != null && folders.length > 0) {
          this.folders = folders;  
          this.createFoldersComponent();
        }
      },
      error => console.log(error)
    );

    this.folderService.filterCounterObservable.subscribe(value => {      
      this.filterCounter = value;
    })
  }

  public dismiss(): void {
   
  }

  public scrollTop() { 
    $( 'html' ).velocity( 'scroll', {
      offset: 0,
      duration: 0,
      easing: 'easeOutExpo',
      mobileHA: false
    } );
  }

  onClickHome() {
    
  }

  setTitleByFilterCategory() {
    
  }


  setTitle( folderId: string) {
   
  }



  createFoldersComponent() {
    this.container.clear();
    const factory: ComponentFactory<FoldersComponent> = this.resolver.resolveComponentFactory(FoldersComponent);
    this.componentRef = this.container.createComponent(factory);

  }


  ngOnDestroy(): void {
    this.subscribeFolders.unsubscribe();    
    this.subscribeCartItems.unsubscribe();
  }
}
