import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../app-state';
import { ProductService } from '../../services/product.service';
import { Filter } from '../../models/filter.model';
import { FormGroup, FormControl } from '@angular/forms';
import { Options } from 'ng5-slider';

@Component({
  selector: 'price-range',
  templateUrl: './price-range.component.html'

})
export class PriceRangeComponent implements OnInit {
  filter: Filter;
  minPrice: number = 0;
  maxPrice: number = 0;
  currentMin: number = 1;
  currentMax: number = 10;
  sliderConfig: any;
  subscribeFilter: any;
  priceRange: any[] = [0, 0];
  currentFolderId: string;
  subscribeMinPrice: any;
  subscribeMaxPrice: any;
  sliderForm: FormGroup = new FormGroup({
    sliderControl: new FormControl([0, 0])
  });
  options: Options = {
    floor: 0,
    ceil: 0,
    step: 5,
    translate: (value: number): string => {
      return value + ' Ft';
    }
  };

  constructor(
    private productService: ProductService,
    private store: Store<AppState>,
    //  private productService: ProductService
  ) {

    this.subscribeMinPrice = this.productService.minPrice.subscribe(minPrice => {
      this.minPrice = Math.round(minPrice);
      if (this.minPrice != 1000000) {
        if (this.minPrice % 5 != 0) {
          this.minPrice = this.minPrice - this.minPrice % 5;
        }
        this.setSliderForm();
      }
      //this.priceRange = [this.priceMin, this.priceMax];
    });
    this.subscribeMaxPrice = this.productService.maxPrice.subscribe(maxPrice => {
      this.maxPrice = Math.round(maxPrice);
      if (this.maxPrice % 5 != 0) {
        this.maxPrice = this.maxPrice + (5 - this.maxPrice % 5);
      }
      this.setSliderForm();
      //this.priceRange = [this.priceMin, this.priceMax];

    });

  }

  ngOnInit() {
    //this.sliderForm.reset({sliderControl: [0, 10000]});

    //this.sliderForm.reset({ floor: this.minPrice, ceil: this.maxPrice });
    this.subscribeFilter = this.store.select('filter').subscribe(
      filter => {
        this.filter = filter;
        /*console.log(filter);
        if(filter.maxPrice) {          
          console.log(filter.minPrice, filter.maxPrice);
          this.sliderForm.reset({ sliderControl: [filter.minPrice, filter.maxPrice] });
        }*/
      },
      error => console.log(error)
    );
  }

  setSliderForm() {
    var min = 0;
    var max = 0;
    const newOptions: Options = Object.assign({}, this.options);
    if (this.minPrice == 1000000) {
      newOptions.ceil = 0;
      newOptions.floor = 0;
    } else {
      newOptions.ceil = this.maxPrice;
      newOptions.floor = this.minPrice;
      min = (this.filter.minPrice) ? this.filter.minPrice : this.minPrice;
      max = (this.filter.maxPrice) ? this.filter.maxPrice : this.maxPrice;
    }
    this.options = newOptions;
    this.sliderForm.reset({ sliderControl: [min, max] });
  }

  onClickFilterButton() {
    var values = this.sliderForm.controls['sliderControl'].value;
    this.filter.minPrice = values[0];
    this.filter.maxPrice = values[1];
    this.store.dispatch({ type: 'FILTER_UPDATED', payload: this.filter });
  }

  ngOnDestroy(): void {
    this.subscribeMaxPrice.unsubscribe();
    this.subscribeMinPrice.unsubscribe();
    this.subscribeFilter.unsubscribe();
  }
}
