import { Action } from '@ngrx/store';
import { Helpdesk } from '../models/helpdesk.model';
import { ActionWithPayload } from './action-with-payload';

export const HELPDESKS_UPDATED = 'HELPDESKS_UPDATED';
//export const CARS_ADD = 'CARS_ADD';

export function helpdesksReducer(state: Helpdesk[] = [], action: ActionWithPayload<Helpdesk[]>) {
  switch (action.type) {
    case HELPDESKS_UPDATED:          
      var resultArray:Array<Helpdesk>=[] ;
      action.payload.forEach( i => { 
        resultArray.push(i);
      });
      return resultArray;    
     default:
	return state;
  }
}