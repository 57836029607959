import { Component, NgModule, Pipe, PipeTransform } from '@angular/core'

@Pipe({ name: 'space' })
export class SpacePipe implements PipeTransform {

  transform(value: string, args: any[]): string {
    if (value) {
      value = value.replace('.00', ''); 
      return value.replace(',', ' ');
    }
    return '';
  }
}