import {Product} from '../models/product.model';

export class Utils  {
  
  public static getTuranapokText(orig: string) : String {
    var turanapokText = orig;
    turanapokText = turanapokText
        .replace('1', 'H')
        .replace('2', 'K')
        .replace('3', 'SZ')
        .replace('4', 'CS')
        .replace('5', 'P');    
    return turanapokText;
  }

  
  public static stripHTMLString(orig: string) : string {      
    return orig.replace(/(<([^>]+)>)/ig,"");
    
  }

  public static getStep(product: any) : number {      
    var step=0;
    if(product.dbmeny && product.dbmeny != 1 && product.keselt) {
      step = Math.ceil(product.keselt / product.dbmeny);
    }
    if(product.dbmeny && product.dbmeny != 1 && !product.keselt) {
      step = 1;
    }
    if(product.dbmeny == 1 || !product.dbmeny) {
      if(product.keselt) { 
        step = product.keselt;
      } else {
        step = 1;
      }
    }

    return step;
  }

  public static closeRightPanel($) { 
      $('.sidebar-toggle.position-right').removeClass('sidebar-open');
      $('.sidebar-offcanvas.position-right').removeClass('open');
  }

  

}