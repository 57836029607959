import { Gyarto } from '../models/gyarto.model';
import { ActionWithPayload } from './action-with-payload';

export const GYARTOK_UPDATED = 'GYARTOK_UPDATED';

export function gyartokReducer(state: Gyarto[] = [], action: ActionWithPayload<Gyarto[]>) {
  switch (action.type) {
    case GYARTOK_UPDATED:          
      var resultArray:Array<Gyarto>=[] ;
      action.payload.forEach( i => { 
        resultArray.push(i);
      });
      return resultArray;
     default:
	return state;
  }
}