import { Component, OnInit, ElementRef, Input } from '@angular/core';
import { Router, ActivatedRoute, Params, NavigationExtras } from '@angular/router';
import { EMAIL_REGEX, PASSWORD_REGEX, PRODUCT_IMAGES_DIR } from '../../utils/constants';

import { FolderUtil } from '../../utils/folder-util';
import { CartService } from '../../services/cart.service';
import { Filter } from '../../models/filter.model';
import { AppState } from '../../app-state';
import { Store } from '@ngrx/store';
import { FolderService } from '../../services/folder.service';


declare var $: any;

@Component({
    selector: 'footer-component',
    templateUrl: './footer.component.html'

})
export class FooterComponent implements OnInit {


    constructor(
        private folderService: FolderService,
        private router: Router,
        private cartService: CartService,
        private store: Store<AppState>) {
    }

    ngOnInit() {

    }

    onClickStaticPageLink(pageId) {
        let navigationExtras: NavigationExtras = {
            queryParams: { 'p': pageId }
        };
        this.router.navigate([{ outlets: { popup: 'static' } }], navigationExtras);

    }

    onClickSignInUp() {
        let navigationExtras: NavigationExtras = {};
        this.router.navigate([{ outlets: { popup: 'signinup' } }], navigationExtras);
    }

    onClickShowCart() {
        let navigationExtras: NavigationExtras = {};
        this.router.navigate([{ outlets: { popup: 'cart' } }], navigationExtras);
    }

    scrollTop(target) {
        $(target).velocity('scroll', {
            container: $('.outerMainContainer'),
            duration: 600,
            easing: 'easeOutExpo',
            mobileHA: false
        });
    }

    scrollTopInner(target) {
        $(target).velocity('scroll', {
            container: $('.popup-container'),
            duration: 600,
            easing: 'easeOutExpo',
            mobileHA: false
        });
    }

    ngOnDestroy(): void {

    }
}
