import { Component, OnInit, ElementRef, ViewChild, ViewContainerRef, ComponentFactoryResolver, ComponentFactory, ComponentRef } from '@angular/core';
import { AppState } from '../../app-state';
import { CheckoutService } from '../../services/checkout.service';
import { OrderService } from '../../services/order.service';
import { UserService } from '../../services/user.service';
import { ConfigService } from '../../services/config.service';
import { FormControl, FormArray, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute, Params, NavigationExtras, NavigationEnd } from '@angular/router';
import { CartItem } from '../../models/cartitem.model';
import { ShippingAddress } from '../../models/shipping-address.model';
import { Store } from '@ngrx/store';
import { FoldersComponent } from '../../components/folders/folders.component';
import { User } from '../../models/user.model';
import { Order } from '../../models/order.model';
import { Subject } from '../../../../node_modules/rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { CartService } from '../../services/cart.service';
import { NgxIzitoastService } from 'ngx-izitoast';
import { ORDER_NOTE_LENGTH } from '../../utils/constants';
import { Utils } from '../../utils/utils';

declare var $: any;


@Component({
  templateUrl: './checkout-page.html'
})
export class CheckoutPage implements OnInit {
  @ViewChild('modal', { static: true }) modal: ElementRef;
  subscribeOrder: any;
  subscribeUser: any;
  //subscribeShipAddrs: any;
  subscribeShopConfig: any;
  subscribeCartItems: any;
  step: number = 1;
  status: boolean[] = [false, false, false, false];
  error: boolean[] = [false, false, false, false];
  addressStatus: boolean;
  shippingStatus: boolean;
  paymentStatus: boolean;
  reviewStatus: boolean;
  subscribeConfigObsrv: any;
  shopconfig: any;
  cartItems: CartItem[] = [];
  order: Order;
  modalTitle: string;
  modalNoButtonTitle: string;
  modalYesButtonTitle: string;
  modalMsg: string;
  @BlockUI() blockUI: NgBlockUI;
  user: User = new User();
  kozl: string;
  successOrder: boolean;
  errorOrderCode: string;
  newOrderId: number;
  shopConfig: any;
  rendHianyzoOsszeg: boolean;
  rendHianyzoAdat: boolean;
  orderNoteMaxLength: number = ORDER_NOTE_LENGTH;

  //shipAddrs: ShippingAddress[] = [];
  constructor(
    public iziToast: NgxIzitoastService,
    private cartService: CartService,
    private modalService: NgbModal,
    private userService: UserService,
    private checkoutService: CheckoutService,
    private orderService: OrderService,
    private configService: ConfigService,
    private router: Router,
    private store: Store<AppState>) {

    /*this.subscribeShipAddrs = this.store.select('shippingAddresses').subscribe(
      shipAddrs => {
        if (shipAddrs) {
          this.shipAddrs = shipAddrs;
        }
      },
      error => console.log(error)
    );*/




    this.subscribeShopConfig = this.store.select('shopConfig').subscribe(
      shopConfig => {
        if (shopConfig) {
          this.shopConfig = shopConfig;
          this.initSubscribeCartItems();
        }
      },
      error => console.log(error)
    );

    this.subscribeUser = this.store.select('user').subscribe(
      user => {
        if (user != null && user.id > 0) {
          this.user = user;
          //this.userService.getShippingAddresses();
        } else {
          this.user = new User();
        }
      },
      error => console.log(error)
    );

    this.subscribeOrder = this.store.select('order').subscribe(
      order => {
        if (order) {

          this.order = order;
          console.log(this.order);
          if (this.order.kozl) {
            this.kozl = this.order.kozl;
          }
          if (!this.order.szallmod || !this.order.hatido || !this.order.fizmod) {
            this.rendHianyzoAdat = true;
          }
        }
      },
      error => console.log(error)
    );


  }

  initSubscribeCartItems() {
    this.subscribeCartItems = this.store.select('cartItems').subscribe(
      cartItems => {
        if (cartItems) {
          this.cartItems = cartItems;
          this.checkMinOrderSum(this.shopConfig.minOrderSum);
        }
      },
      error => console.log(error)
    );
  }

  checkMinOrderSum(minOrderSum) {

    if (this.cartService.cartSum < minOrderSum) {
      this.rendHianyzoOsszeg = true;
    } else {
      this.rendHianyzoOsszeg = false;
    }
    //if(this.cartService.cartSum < minOrderSum) { 
    //  this.rendHianyzo = minOrderSum - this.cartService.cartSum;
    //  console.log(this.rendHianyzo);
    //this.iziToast.show({title: "A minimális rendelés összege: "+minOrderSum+" Ft", position:'topCenter', timeout: 2000});
    //}
  }

  ngOnInit() {
    var $scrollTop = $('.scroll-to-top-btn');
    $scrollTop.removeClass('visible');
  }

  onClickBackToHome() {
    this.router.navigate([{ outlets: { popup: null } }]);
  }

  onClickStep(step: number) {
    console.log('onClickStep:' + step);
    this.checkoutService.stepChangeObservable.next(this.step);
    $('.popup-container-inner').scrollTop(0);
    this.step = step;
    for (var i = step - 1; i < 4; i++) {
      this.status[i] = false;
    }

  }

  onNextToShipping() {
    $('.popup-container-inner').scrollTop(0);
    this.step = 2;
    this.status[0] = true;
    this.error[0] = false;
  }


  onAddressValid(e) {
    $('.popup-container-inner').scrollTop(0);
    if (e) {
      this.status[0] = true;
      this.error[0] = false;
    } else {
      this.status[0] = false;
      this.error[0] = true;
    }
  }


  onShippingValid(e) {
    $('.popup-container-inner').scrollTop(0);
    console.log(e);
    if (e) {
      this.status[1] = true;
      this.error[1] = false;
    } else {
      this.status[1] = false;
      this.error[1] = true;
    }
  }

  onClickSubmitOrder() {
    if(!this.order.fizmod) {
      this.iziToast.show({title: "Figyelem!", message:'A fizetési mód nincs kiválasztva!', position:'topCenter', timeout: 3000, color:'red'});

    } else { 
      this.confirmOrder();
    }

  }

  onChangeNote() {  
    this.order.kozl = Utils.stripHTMLString(this.kozl);
    this.store.dispatch({ type: 'ORDER_UPDATED', payload: this.order });
  }


  confirmOrder() {
    this.errorOrderCode = '';
    this.modalTitle = 'Rendelés megerősítés';
    this.modalMsg = 'Indulhat a rendelés elküldése?';
    this.modalNoButtonTitle = 'MÉGSEM';
    this.modalYesButtonTitle = 'IGEN';

    this.modalService.open(this.modal).result.then((result) => {
      if (result == 'yes') {

        this.blockUI.start();
        this.order.cartItems = this.cartItems;

        this.orderService.postOrder(this.order)
          .subscribe(
            response => {
              this.newOrderId = response.id;
              this.blockUI.stop();
              this.successOrder = true;
              this.cartService.clearCart();
              localStorage.removeItem('order');              
              this.store.dispatch({ type: 'ORDER_UPDATED', payload: new Order() });
 
            },
            (res: HttpErrorResponse) => {
              console.log(res);
              this.blockUI.stop();
              $('.popup-container-inner').scrollTop(0);
              if(res.error && res.error.date) {
                this.iziToast.show({title: "Rendelés dátum hiba!", message: this.order.hatido+' teljesítési napra már nem adható le rendelés!', position:'topCenter', timeout: 4000, color:'red'});  
              } else { 
                this.iziToast.show({title: "Hiba!", message:'A rendelés feladása nem sikerült!', position:'topCenter', timeout: 3000, color:'red'});
              }
              
              this.errorOrderCode = 'error';
              
            }
          );
      }
    }, (reason) => {

    });
  }

  
  /*  
    onClickAddShippingAddr() {
      let navigationExtras: NavigationExtras = {
        queryParams: {}
      };
      this.router.navigate([{ outlets: { popup: 'shipping-address' } }], navigationExtras);
    }
  
    onAddressValid(e) {    
      $('.popup-container').scrollTop(0);
      if (e) {      
        this.status[0] = false;
        this.error[0] = false;
      } else {
        this.status[0] = false;
        this.error[0] = true;
      }
    }
  
    onShippingValid(e) {
      $('.popup-container').scrollTop(0);
      if (e) {     
        this.status[1] = false;
        this.error[1] = false;
      } else {
        this.status[1] = false;
        this.error[1] = true;
      }
    }
  
    onPaymentValid() {
      $('.popup-container').scrollTop(0);    
      this.status[2] = false;
    }
  
    onNextToShipping() {
      $('.popup-container').scrollTop(0);
      this.step = 2;
      this.status[0] = true;
      this.error[0] = false;
    }
  
   
  
    onBackToShipping() {
      $('.popup-container').scrollTop(0);
      this.step = 2;
      this.status[1] = false;
    }
  
    onNextToPayment() {
      $('.popup-container').scrollTop(0);
      this.step = 3;
      this.status[1] = true;
      this.error[1] = false;
    }
  
    onNextToReview() {
      $('.popup-container').scrollTop(0);
      this.step = 4;
      this.status[2] = true;
    }
  
  */
  onBackToAddress() {
    $('.popup-container-inner').scrollTop(0);
    this.step = 1;
    this.status[0] = false;
  }

  onPaymentValid() {
    $('.popup-container-inner').scrollTop(0);
    this.status[2] = true;
  }

  onNextToReview() {
    $('.popup-container-inner').scrollTop(0);
    this.step = 4;
    this.status[2] = true;
  }

  onBackToShipping() {
    $('.popup-container-inner').scrollTop(0);
    this.step = 2;
    this.status[1] = false;
  }

  onNextToPayment() {
    $('.popup-container-inner').scrollTop(0);
    this.step = 3;
    this.status[1] = true;
    this.error[1] = false;
  }


  ngOnDestroy(): void {
    this.subscribeUser.unsubscribe();
    this.subscribeCartItems.unsubscribe();
    this.subscribeOrder.unsubscribe();
    this.subscribeShopConfig.unsubscribe();
    //this.subscribeShipAddrs.unsubscribe();

  }
}
