import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Product } from '../models/product.model';
import { User } from '../models/user.model';
import { Observable, Subject } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { HttpErrorResponse } from '@angular/common/http';
import { AppState } from '../app-state';
import { API_URL } from '../utils/constants';
import { EMAIL_REGEX, PASSWORD_REGEX, PRODUCT_IMAGES_DIR } from '../utils/constants';
import { FolderService } from '../services/folder.service';
import { DatePipe } from '@angular/common';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

@Injectable()
export class ProductService {
  subscribeUser: any;
  minPrice = new Subject<number>();
  maxPrice = new Subject<number>();
  orderBy = new Subject<any>();
  datePipe = new DatePipe('en-US');
  @BlockUI() blockUI: NgBlockUI;
  public products: Product[] = [];
  constructor(
    private folderService: FolderService,
    private httpClient: HttpClient,
    private store: Store<AppState>
  ) {
    
    this.subscribeUser = this.store.select('user').subscribe(
      user => {
        console.log(user);
        if (user != null && user.id > 0) {
          this.getProducts("");
        }
        if ((user == null && !localStorage.getItem('token')) || (user != null && user.id == 0)) {
          this.getProducts("/public");
        }
      },
      error => console.log(error)
    );

  }

  
  getProducts(endpointPostfix) {    
    this.blockUI.start();
    var hatido = this.getHatarido(endpointPostfix);

    this.httpClient.get<Product[]>(API_URL + "product" + endpointPostfix + '/'+ hatido)
      .subscribe(
        products => {
          this.products = products;
          var product: any;       
          this.doSort(products);   
          for (product of products) {
            product.loadTms = Date.now() / 1000;
            product.subfolderName = (this.folderService.foldersMap.get(product.ccskod));
            product.topfolderName = (this.folderService.foldersMap.get(product.focsop));
            
            product.cikkszam = product.cikkszam.trim();
            if(product.alergen != '[]') {
              product.alergenArr = JSON.parse(product.alergen);
            }
            //console.log(product.ccskod);
          //  console.log(this.folderService.folders.indexOf(product.ccskod));
            //product.
          }          
          this.blockUI.stop();
          /*var product: any;
          for (product of products) {
            if (product.defImage == "no-available") {
              product.defImage = PRODUCT_IMAGES_DIR + '/' + product.defImage ;
            } else {
              product.defImage = PRODUCT_IMAGES_DIR + '/' + product.code + '/' + product.defImage;
            }
           
            product.price = product.productVariantList[0].price;
            product.partnerPrice = product.productVariantList[0].partnerPrice;
            product.defVariantId = product.productVariantList[0].id;
            product.inStock = product.productVariantList[0].inStock;
            product.onSalePrice = product.productVariantList[0].onSalePrice;
            product.name = product.code + ' ' + product.productVariantList[0].name;
            
          }*/          
          this.store.dispatch({ type: 'PRODUCTS_UPDATED', payload: products });
        },
        (err: HttpErrorResponse) => {
          this.blockUI.stop();
        }
      );
  }

  public doSort(products: any[]) {
    products.sort((obj1: any, obj2) => {
      if (obj1.wPriceNetto > obj2.wPriceNetto) {
        return 1;
      }

      if (obj1.wPriceNetto < obj2.wPriceNetto) {
        return -1;
      }
      return 0;
    });
  }

  /*getProduct(endpointPostfix, cikkszam) {
    var hatido = this.getHatarido(endpointPostfix);
    this.httpClient.get<Product>(API_URL + "product/details/" + endpointPostfix + cikkszam.trim() + '/' + hatido )
      .subscribe(
        product => {                    
          product.subfolderName = this.folderService.foldersMap.get(product.ccskod);
          product.topfolderName = this.folderService.foldersMap.get(product.focsop);
          product.cikkszam = product.cikkszam.trim();
          this.store.dispatch({ type: 'PRODUCT_UPDATED', payload: product });
        },
        (err: HttpErrorResponse) => {

        }
      );
  }*/
  getProduct(cikkszam) {
    var hatido = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    if(localStorage.getItem('order'))  {
      var order = JSON.parse(localStorage.getItem('order'));
      if(order.hatido) {
        hatido = order.hatido;
      }
    }
    this.httpClient.get<Product>(API_URL + "product/details/" + cikkszam.trim() + '/' + hatido )
      .subscribe(
        product => {                    
          product.subfolderName = this.folderService.foldersMap.get(product.ccskod);
          product.topfolderName = this.folderService.foldersMap.get(product.focsop);
          product.cikkszam = product.cikkszam.trim();
          if(product.alergen != '[]') {
            product.alergenArr = JSON.parse(product.alergen);
          }
          this.store.dispatch({ type: 'PRODUCT_UPDATED', payload: product });
        },
        (err: HttpErrorResponse) => {

        }
      );
  }


  private getHatarido(endpointPostfix) {
    var hatido = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    if(localStorage.getItem('order') && endpointPostfix == '')  {
      var order = JSON.parse(localStorage.getItem('order'));
      if(order.hatido) {
        hatido = order.hatido;
      }
    }
    return hatido;
  }

  private extractData(res: Response) {
    let body = res.json();
    return body || {};
  }

  private handleError(error: any): Promise<any> {
    console.log('An error occurred', error); // for demo purposes only
    return Promise.reject(error.message || error);
  }
}
