import { Action } from '@ngrx/store';
import { Folder } from '../models/folder.model';
import { ActionWithPayload } from './action-with-payload';

export const FOLDERS_UPDATED = 'FOLDERS_UPDATED';

export function foldersReducer(state: Folder[] = [], action: ActionWithPayload<Folder[]>) {
  switch (action.type) {
    case FOLDERS_UPDATED:
      var resultArray:Array<Folder>=[] ;
      action.payload.forEach( i => {
        resultArray.push(i);
      });
      return resultArray;
    default:
	  return state;
  }
}