import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../app-state';
import { Filter } from '../../models/filter.model';
import { Gyarto } from 'src/app/models/gyarto.model';
import { FormControl, FormArray, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { FolderService } from '../../services/folder.service';

@Component({
  selector: 'gyartok',
  templateUrl: './gyartok.component.html'

})
export class GyartokComponent implements OnInit {
  filter: Filter;
  subscribeFilter: any;  
  subscribeGyartok: any;
  gyartok: Gyarto[] = [];
  form: FormGroup;
  formItems: FormArray;
  
  constructor(
    private store: Store<AppState>,
    private formBuilder: FormBuilder,
    private folderService: FolderService,
  ) {  }

  ngOnInit() {
    
    this.subscribeGyartok = this.store.select('gyartok').subscribe(
      gyartok => {
        if (gyartok) {
          this.gyartok = gyartok;
          this.form = this.formBuilder.group({
            items: this.formBuilder.array([])
          });
          this.addItems();
          if (!this.subscribeFilter) {
            this.initSubscribeFilter();
          }
        }
        if (gyartok == null) {

          this.form = this.formBuilder.group({
            items: this.formBuilder.array([])
          });
        }
      },
      error => console.log(error)
    );

    this.folderService.getGyartok();


  }

  initSubscribeFilter() {
    console.log('initSubscribeFilter()');
    this.subscribeFilter = this.store.select('filter').subscribe(
      filter => {
        if (filter) {
          this.filter = filter;
          console.log(this.filter);
          this.initForm();
        } else {
        }
      },
      error => console.log(error)
    );
  }

  addItems(): void {
    this.formItems = this.form.get('items') as FormArray;
    for (var gyarto of this.gyartok) {
      this.formItems.push(this.createItem(gyarto));
    }
  }

  createItem(gyarto: Gyarto): FormGroup {
    return this.formBuilder.group({
      nev: gyarto.nev,
      gyarto: gyarto.gyarto,
      checked: new FormControl(false, []),
    });
  }

  getControls() {
    return (<FormArray>this.form.controls['items']).controls;
  }

  onChangeGyartokSelect(e) {
    if (!e.checked.value) {
      this.filter.gyartok = this.filter.gyartok.filter(u => u != e.gyarto.value);
    } else {
      if (!this.filter.gyartok.find(u => u == e.gyarto.value)) {
        this.filter.gyartok.push(e.gyarto.value);
      }
    }
    this.store.dispatch({ type: 'FILTER_UPDATED', payload: this.filter });
  }

  initForm() {
    var item: any;
    for (item of this.formItems.controls) {      
      if (this.filter.gyartok.indexOf(item.value.gyarto) > -1) {
        item.controls['checked'].setValue(true);
      } else {
        item.controls['checked'].setValue(false);
      }
    }
  }

  ngOnDestroy(): void {    
    //this.subscribeUser.unsubscribe();
    this.subscribeFilter.unsubscribe();
    this.subscribeGyartok.unsubscribe();
  }
}
