
import { Component, Renderer, ElementRef, ViewChild } from '@angular/core';
import { Title} from "@angular/platform-browser";
import { UserService } from './services/user.service';
import { ProductService } from './services/product.service';
import { OrderService } from './services/order.service';
import { ConfigService } from './services/config.service';
import { FolderService } from './services/folder.service';
import { Router, ActivatedRoute, Params, NavigationExtras, NavigationEnd } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from './app-state';
import { PRODUCT_DATA_EXPIRED_TIME_SEC } from './utils/constants';
import { HttpErrorResponse } from '@angular/common/http';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxIzitoastService } from 'ngx-izitoast';
import { environment } from '../environments/environment';
import { PhotoSwipeComponent } from './components/photo-swipe/photo-swipe.component';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  private subscribeUser: any;
  @ViewChild('photoSwipe', {static: true}) photoSwipe: PhotoSwipeComponent;;

  constructor(
    private iziToast: NgxIzitoastService,
    private configService: ConfigService,
    private orderService: OrderService,
    private titleService: Title,
    private store: Store<AppState>,
    private router: Router,
    private modalService: NgbModal,
    private renderer: Renderer,
    private userService: UserService,
    private folderService: FolderService,
    private productService: ProductService
    
  ) {
    //console.log(environment['version']);
    
    this.folderService.getFolders();
    

    router.events.subscribe((val) => {
     
      if (val instanceof NavigationEnd) {
        
        if (val.url == '/') {
         
          this.renderer.setElementClass(document.body, 'scrollbar-hide', false);
          
        } else {
          this.renderer.setElementClass(document.body, 'scrollbar-hide', true);
          
        }        
      }
    });

    

    var self = this;
    document.addEventListener('visibilitychange', function (e) {
      if (document.visibilityState == 'visible' && localStorage.getItem('token')) {
        self.userService.checkTokenIsValid().subscribe(result => {
          var now =  Date.now() / 1000;
          if(self.productService.products && self.productService.products[0] && now - self.productService.products[0].loadTms > PRODUCT_DATA_EXPIRED_TIME_SEC) {
            self.productService.getProducts('');
          }
        },
          (error: HttpErrorResponse) => {
            
            if (error.status == 401 || error.status === 403) {
              self.iziToast.show({title: "Lejárt login", message:'kérjük jelentkezzen be újra.', position:'topCenter', timeout: 2500});

            }
          });
      }
    }, false);

    if (localStorage.getItem('token')) {
      this.userService.getUser();
    }
    
  }

  ngOnInit() {        
    this.configService.setPhotoswipeComp(this.photoSwipe);    
    //this.iziToast.show({ title: 'Tisztelt Vásárlóink!', message:'A termék képek folyamatosan kerülnek felöltésre. Megértésüket köszönjük!', position: 'topCenter', timeout: 5000, close: true });
  }

  ngOnDestroy() {
    //this.subscribeUser.unsubscribe();
    
  }
  
}
