import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params, NavigationExtras, NavigationEnd } from '@angular/router';
import { Folder } from '../../../models/folder.model';
import { Filter } from '../../../models/filter.model';
import { AppState } from '../../../app-state';
import { NgxIzitoastService } from 'ngx-izitoast';
import { Store } from '@ngrx/store';
import { FolderService } from '../../../services/folder.service';
import { User } from 'src/app/models/user.model';

declare var $: any;

@Component({
  selector: 'site-menu',
  templateUrl: './site-menu.component.html'

})
export class SiteMenuComponent implements OnInit {  
  currentPath: string;
  subscribeFolders:any;
  subscribeFilter: any;
  subscribeUser: any;
  folders: Folder[] = [];
  filter: Filter = new Filter();
  user: User;
  constructor(
    private router: Router,    
    private store: Store<AppState>,
    private folderService : FolderService,
    public iziToast: NgxIzitoastService,

  ) {
    router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.currentPath = val.url;
      }
    });
  }

  ngOnInit() {
    this.subscribeFilter = this.store.select('filter').subscribe(
      filter => {
        if (filter) {                    
          this.filter = filter;                              
        }
      },
      error => console.log(error)
    );

    this.subscribeFolders = this.store.select('folders').subscribe(
      folders => {      
        if (folders != null && folders.length > 0) {
          this.folders = folders;            
        }
      },
      error => console.log(error)
    );
    this.subscribeUser = this.store.select('user').subscribe(
      user => {
        if (user != null && user.id > 0) {
          this.user = user;
        } else {
          this.user = null;
        }
      },
      error => console.log(error)
    );
  }

  removeFolder(folderId: any, name) {   
    if(this.filter.subfolders.indexOf(folderId) == -1) {
      this.iziToast.show({title: '"'+name+'"' , message: "hozzáadva a szűrő feltételhez.", position:'topCenter', timeout: 1500, close:false});
    }
    this.folderService.removeFolderFromFilter(folderId);
  }

  onClickHome() {
    this.router.navigate([{ outlets: { popup: null } }]);
    if (this.currentPath == '/') {
      
    }
  }

  onClickRegist() {
    this.router.navigate([{ outlets: { popup: 'signinup' } }]);    
  }
  
  onClickStaticPageLink(pageId) {
    let navigationExtras: NavigationExtras = {
      queryParams: { 'p': pageId }
    };
    this.router.navigate([{ outlets: { popup: 'static' } }], navigationExtras);

  }

  scrollTopInner(target) {
    $(target).velocity('scroll', {
      container: $('.popup-container'),
      duration: 600,
      easing: 'easeOutExpo',
      mobileHA: false
    });
  }

  scrollTop(target) {
    $(target).velocity('scroll', {
      container: $('.outerMainContainer'),
      duration: 600,
      easing: 'easeOutExpo',
      mobileHA: false
    });
  }

  ngOnDestroy(): void {
    this.subscribeFilter.unsubscribe();       
    this.subscribeFolders.unsubscribe();       
  }
}
