import { ActionWithPayload } from './action-with-payload';

export const ADD = 'ADD_FAVORITESITEM';
export const ADD_ITEMS = 'ADD_FAVORITESITEMS';
export const REMOVE = 'REMOVE_FAVORITESITEM';
export const CLEAR = 'CLEAR_FAVORITESITEM';


export function favoritesReducer(state: string[] = null, action: ActionWithPayload<string[]>) {
  switch (action.type) {

    case CLEAR:
      var resultArray: Array<string> = null;
      return resultArray;
    case ADD:
      if (state == null) {
        state = [];
      }
      state = state.concat(action.payload[0])
      return state;
    case ADD_ITEMS:
      var state: Array<string> = [];
      action.payload.forEach(i => {
        state.push(i);
      });
      return state;
    case REMOVE:
      state = state.filter(i => i != action.payload[0]);
      return state;
    default:
      return state;
  }
}