import { Action } from '@ngrx/store';

import { Filter } from '../models/filter.model';
import { ActionWithPayload } from './action-with-payload';


export const FILTER_UPDATED = 'FILTER_UPDATED';
export function filterReducer(state: Filter = new Filter(), action: ActionWithPayload<Filter>): Filter {
  switch (action.type) {
    case FILTER_UPDATED:                 
      return Object.assign({},action.payload);      
    default:
      return state;
  }
}