import { Injectable } from '@angular/core';
import { Router, ActivatedRoute, Params, NavigationExtras, NavigationEnd, Event } from '@angular/router';;
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { catchError, map, switchMap, finalize, filter, take } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AppState } from './app-state';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { User } from './models/user.model';
//import { API_URL } from './utils/constants';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  token: string;
  subscribeUser: any;
  isRefreshingToken: boolean;
  tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
 

  constructor(
    private http: HttpClient,
    private store: Store<AppState>,
    private router: Router) {    
           
    if(localStorage.getItem('token')) {
      this.token = localStorage.getItem('token');
    }
    this.subscribeUser = this.store.select('user').subscribe(
      user => {
        if(user != null) {     
          if(user.token) { 
            this.token = user.token;
          }
        }
      },
      error => console.log(error)
    );
  }

  

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if(!this.token && localStorage.getItem('token')) {
      this.token = localStorage.getItem('token');
    }
    if(!localStorage.getItem('token')) {
      this.token = '';
    }
    request = request.clone({
      setHeaders: {
        Authorization: `Bearer ${this.token}`
      }
    });
    if (!request.headers.has('Content-Type')) {
      request = request.clone({ headers: request.headers.set('Content-Type', 'application/json') });
    }

    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
        }
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        if (error.status == 401 || error.status === 403) {
          localStorage.removeItem('user');
          localStorage.removeItem('token');
          this.store.dispatch({ type: 'USER_UPDATED', payload: new User() });
        }
        return throwError(error);
      }));
  }

  /*private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
 
    if(!this.isRefreshingToken) {
      this.isRefreshingToken = true;
 
      // Reset here so that the following requests wait until the token
      // comes back from the refreshToken call.
      this.tokenSubject.next(null);
      console.log('refreshToken 1');
      return this.refreshToken()
        .pipe(
          switchMap((user: any) => {
           
            return null;
            //return <any>this.authService.logout();
          }),
          catchError(err => {
            return null;
            //return <any>this.authService.logout();
          }),
          finalize(() => {
            this.isRefreshingToken = false;
          })
        );
    } else {
      this.isRefreshingToken = false;
      return null;
      return this.tokenSubject
        .pipe(filter(token => token != null),
          take(1),
          switchMap(token => {
          return next.handle(this.addTokenToRequest(request, token));
        }));
    }
  }*/

  /*refreshToken() : Observable<any> {
    //let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    //let token = currentUser.refreshToken;
    console.log('refreshToken 2');
    
    return this.http.post<any>(API_URL + "token/refresh", { 'token': this.token })
      .pipe(
        map(user => {

          if (user && user.accessToken) {
            localStorage.setItem('currentUser', JSON.stringify(user));
          }

          return <any>user;
      }));
  }*/
  
  ngOnDestroy(): void {
    this.subscribeUser.unsubscribe();
  }
}