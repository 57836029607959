import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params, NavigationExtras } from '@angular/router';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { AppState } from '../../../app-state';
import { User } from '../../../models/user.model';
import { UserService } from '../../../services/user.service';
import { CIM_MAX_LENGTH, HELYSEG_MAX_LENGTH } from '../../../utils/constants';
import { HttpErrorResponse } from '@angular/common/http';
import { NgxIzitoastService } from 'ngx-izitoast';

@Component({
  templateUrl: './address.page.html',  
})
export class AddressPage implements OnInit {
  addressForm: FormGroup;
  subscribeUser: any;
  user: User = new User();
  submitClicked: boolean;
   
  helysegMaxLength: number = HELYSEG_MAX_LENGTH;
  cimMaxLength: number = CIM_MAX_LENGTH;
  zipNumberMask = [/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
  taxNumberMask = [/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, '-', /[0-9]/, '-', /[0-9]/, /[0-9]/];

  constructor(
    public iziToast: NgxIzitoastService,
    private router: Router,
    private userService: UserService,
    private fb: FormBuilder,
    private store: Store<AppState>) {
    this.createForm();
  }

  ngOnInit() {
    //var $scrollTop = $('.scroll-to-top-btn');
    //$scrollTop.removeClass('visible');
    this.subscribeUser = this.store.select('user').subscribe(
      user => {
        if (user != null && user.id > 0) {
          this.user = user;
          this.initForm();
        } else {
          this.user = null;
        }
      },
      error => console.log(error)
    );
  }


  onClickSave() {
    this.submitClicked = true;    
    if (this.addressForm.status == 'VALID') {
      this.userService.updateAddress(this.addressForm.value).subscribe(
        user => {
          this.store.dispatch({ type: 'USER_UPDATED', payload: user });
          this.addressForm.markAsPristine();
          this.iziToast.show({title: "Adatok elmentve!", position:'topCenter', timeout: 2000});          
        },
        (err: HttpErrorResponse) => {

        }
      );
    } 
  }

  createForm() {
    this.addressForm = this.fb.group({      
      sznev: new FormControl('', [Validators.required]),
      aiasz: new FormControl('', [Validators.required]),
      lhelyseg: new FormControl('', [Validators.required]),
      lirsz: new FormControl('', [Validators.required]),
      lcim: new FormControl('', [Validators.required]),
    }
    );
  }

  onClickMenuItem(menuItemId: string) {    
    let navigationExtras: NavigationExtras = {
      queryParams: {  }
    };
    this.router.navigate([{ outlets: { popup: menuItemId } }], navigationExtras);
  }

  initForm() {    
    this.addressForm.controls['sznev'].setValue(this.user.sznev);
    this.addressForm.controls['aiasz'].setValue(this.user.aiasz);
    this.addressForm.controls['lhelyseg'].setValue(this.user.lhelyseg);
    this.addressForm.controls['lirsz'].setValue(this.user.lirsz);
    this.addressForm.controls['lcim'].setValue(this.user.lcim);    
  }

  ngOnDestroy(): void {
    this.subscribeUser.unsubscribe();    
  }
}
