export class User {
  id: number = 0;
  email: string;
  password: string;
  token: string;
  role: string;
  contactPerson: string;  
  nev1: string;
  nev1a: string;
  aiasz: string;
  tel1: string;
  helyseg: string;
  irsz: string;
  cim: string;
  lhelyseg: string;
  lirsz: string;
  lcim: string;
  sznev: string;
  tura: string;
  fizmod: string;
  /*taxNumber: string;
  phoneNumber: string;
  contactName: string;

  name: string;  
  city: string;
  zip: string;
  address: string;

  shippingName: string;
  shippingCity: string;
  shippingZip: string;
  shippingAddress: string;

  paytypeCode: number;*/

}
