import { Action } from '@ngrx/store';
import { ShippingAddress } from '../models/shipping-address.model';
import { ActionWithPayload } from './action-with-payload';

export const SHIPPING_ADDRESSES_UPDATED = 'SHIPPING_ADDRESSES_UPDATED';
//export const CARS_ADD = 'CARS_ADD';

export function shippingAddressesReducer(state: ShippingAddress[] = [], action: ActionWithPayload<ShippingAddress[]>) {
  switch (action.type) {
    case SHIPPING_ADDRESSES_UPDATED:          
      var resultArray:Array<ShippingAddress>=[] ;
      action.payload.forEach( i => { 
        resultArray.push(i);
      });
      return resultArray;
     default:
	return state;
  }
}