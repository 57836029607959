import { CartItem } from './cartitem.model';
import { ShippingAddress } from './shipping-address.model';
import { OrderLog } from './orderlog.model';

export class Order {
  id:number;
  
  hatido: string;

  cartItems: CartItem[];    
  szallmod: string;
  shippingAddressId: number;
  kozl: string;
  fizmod: string;
  orderLogList: OrderLog[];  
  alap: number;
  brutto: number;
  /*paytypeCode: number;
  shippingAddress: ShippingAddress;
  shippingAddressId: number;
  shippingCost: number;
  shippingMode: any;  
  shippingAddrJson: string;
  invoicedAddrJson: string;

  orderLogList: OrderLog[];  
  note: string;
  kanna: number;
  freeShipping: boolean;*/
}
