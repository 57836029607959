import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../app-state';
import { Filter } from '../../models/filter.model';
import { Ujsag } from 'src/app/models/ujsag.model';
import { FormControl, FormArray, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { FolderService } from '../../services/folder.service';

@Component({
  selector: 'ujsagok',
  templateUrl: './ujsagok.component.html'

})
export class UjsagokComponent implements OnInit {
  filter: Filter;
  subscribeFilter: any;
  subscribeUser:any;
  subscribeUjsagok: any;
  ujsagok: Ujsag[] = [];
  form: FormGroup;
  formItems: FormArray;
  visibled: boolean;

  constructor(
    private store: Store<AppState>,
    private formBuilder: FormBuilder,
    private folderService: FolderService,

  ) {

  }

  ngOnInit() {
    
    this.subscribeUjsagok = this.store.select('ujsagok').subscribe(
      ujsagok => {
        if (ujsagok) {
          this.ujsagok = ujsagok;
          this.form = this.formBuilder.group({
            items: this.formBuilder.array([])
          });
          this.addItems();
          if (!this.subscribeFilter) {
            this.initSubscribeFilter();
          }
        }
        if (ujsagok == null) {

          this.form = this.formBuilder.group({
            items: this.formBuilder.array([])
          });
        }
      },
      error => console.log(error)
    );

    this.subscribeUser = this.store.select('user').subscribe(
      user => {
        console.log(user);
        if (user != null && user.id > 0) {                      
          this.folderService.getUjsagokByUser();
        } else if(!localStorage.getItem('token')) {
          console.log('getujságok');
          this.folderService.getUjsagok();
        }
      },
      error => console.log(error)
    );


  }

  initSubscribeFilter() {
    console.log('initSubscribeFilter()');
    this.subscribeFilter = this.store.select('filter').subscribe(
      filter => {
        if (filter) {
          this.filter = filter;
          console.log(this.filter);
          this.initForm();
        } else {
        }
      },
      error => console.log(error)
    );
  }

  addItems(): void {
    this.formItems = this.form.get('items') as FormArray;
    for (var ujsag of this.ujsagok) {
      this.formItems.push(this.createItem(ujsag));
    }
  }

  createItem(ujsag: Ujsag): FormGroup {
    return this.formBuilder.group({
      nev: ujsag.nev,
      akcio: ujsag.akcio,
      checked: new FormControl(false, []),
    });
  }

  getControls() {
    return (<FormArray>this.form.controls['items']).controls;
  }

  onChangeUjsagokSelect(e) {
    if (!e.checked.value) {
      this.filter.ujsagok = this.filter.ujsagok.filter(u => u != e.akcio.value);
    } else {
      if (!this.filter.ujsagok.find(u => u == e.akcio.value)) {
        this.filter.ujsagok.push(e.akcio.value);
      }
    }
    this.store.dispatch({ type: 'FILTER_UPDATED', payload: this.filter });
  }

  initForm() {
    var item: any;
    for (item of this.formItems.controls) {
      console.log(item.value.akcio);
      if (this.filter.ujsagok.indexOf(item.value.akcio) > -1) {
        item.controls['checked'].setValue(true);
        //item.value.checked.setValue(true);
      } else {
        item.controls['checked'].setValue(false);
        //item.value.checked.setValue(false);
        //item.value.checked = false;
      }
    }
  }

  ngOnDestroy(): void {
    // this.subscribeMaxPrice.unsubscribe();
    // this.subscribeMinPrice.unsubscribe();
    this.subscribeUser.unsubscribe();
    this.subscribeFilter.unsubscribe();
    this.subscribeUjsagok.unsubscribe();
  }
}
