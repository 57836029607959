import { Component, OnInit, Injectable, ViewChild, ElementRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { DatePipe } from '@angular/common';
import { Utils } from '../../utils/utils';
import { I18n, CustomDatepickerI18n } from '../../utils/cal-locale';
import { AppState } from '../../app-state';
import { NgbActiveModal, NgbModal, NgbDate, NgbDatepickerI18n, NgbDatepicker, NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { User } from 'src/app/models/user.model';
import { Order } from 'src/app/models/order.model';
import { UserService } from 'src/app/services/user.service';
import { CartService } from 'src/app/services/cart.service';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgxIzitoastService } from 'ngx-izitoast';
import { SZALLMOD_HAZHOZSZALLITAS, SZALLMOD_SZEMELYESATVETEL, SZALLDAYS_MIN, SZALLDAYS_MAX } from '../../utils/constants';


@Component({
  selector: 'order-settings-modal',
  templateUrl: './order-settings.modal.html',
  providers: [I18n, { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n }] // define custom NgbDatepickerI18n provider
})
export class OrderSettingsModal implements OnInit {
  teljesitesForm: FormGroup;
  isDisabled: any;
  user: User;
  subscribeUser: any;
  subscribeOrder: any;
  //subscribeShipAddrs: any;
  subscribeCartItems: any;
  order: Order = new Order();
  tmpOrder: Order = new Order();
  minDateConfig: any;
  maxDateConfig: any;
  datePipe = new DatePipe('en-US');
  turanapokText: any;
  model: NgbDateStruct;
  submitClicked: boolean;
  cartItems: any[] = [];
  public visibledCancel: boolean = true;  
  hazhozszallMode: any = SZALLMOD_HAZHOZSZALLITAS;
  szemelyesatvetelMode: any = SZALLMOD_SZEMELYESATVETEL
  addCartAfterSetting:any;
  @ViewChild('dp', { static: false }) dp: ElementRef;

  constructor(
    public userService: UserService,
    public iziToast: NgxIzitoastService,
    private fb: FormBuilder,
    public modal: NgbActiveModal,
    private store: Store<AppState>
    
  ) {
    //if (!this.order.szallmod) {      
    //  this.order.szallmod = SZALLMOD_HAZHOZSZALLITAS;
    //}
    this.subscribeUser = this.store.select('user').subscribe(
      user => {
        if (user != null && user.id > 0) {
          this.user = user;
          /*if(!this.user.tura) {
            this.order.szallmod = SZALLMOD_SZEMELYESATVETEL;     
          } */         
          //this.setDisabledDays(this.order.szallmod);
          this.turanapokText = Utils.getTuranapokText(user.tura);         
         if(!this.subscribeOrder) {
           this.initsubscribeOrder();
         }
        } else {
          this.user = null;
        }
      },
      error => console.log(error)
    );

    this.subscribeCartItems = this.store.select('cartItems').subscribe(
      cartItems => {
        if (cartItems) {
          this.cartItems = cartItems;
        }
      },
      error => console.log(error)
    );

  }

  initsubscribeOrder() {
    this.subscribeOrder = this.store.select('order').subscribe(
      order => {
        
        if (order) {
          this.order = order;
          console.log(this.user.tura);
          console.log(this.order);  
        
          //this.setDisabledDays(this.order.szallmod);            
        } 
        if(this.order.szallmod) {
          this.setDisabledDays(this.order.szallmod);
        } else {
          if(!this.order) { 
            this.order = new Order();
          }
          if(this.user.tura) {
            this.order.szallmod =SZALLMOD_HAZHOZSZALLITAS; 
            this.setDisabledDays(SZALLMOD_HAZHOZSZALLITAS);
          } else {
            this.order.szallmod =SZALLMOD_SZEMELYESATVETEL;
            this.setDisabledDays(SZALLMOD_SZEMELYESATVETEL);
          }
        }
        
        this.createForm();
        this.initForm();
        this.initHataridoMinMax();

      },
      error => console.log(error)
    );
  }

  ngOnInit() {

  }

  initHataridoMinMax() {
    var minDate = new Date();
    var dayCounter = 0;
    do {
      minDate = new Date(minDate.getTime() + (86400 * 1000));
      if (minDate.getDay() != 0 && minDate.getDay() != 6) {
        dayCounter++;
      }
      if (dayCounter == SZALLDAYS_MIN) {
        break;
      }
    } while (true);
    var today = new Date()
    var maxTime = today.getTime() + (86400 * 1000) * SZALLDAYS_MAX;
    var maxDate = new Date();
    maxDate.setTime(maxTime);
    this.minDateConfig = { year: minDate.getFullYear(), month: minDate.getMonth() + 1, day: minDate.getDate() };
    this.maxDateConfig = { year: maxDate.getFullYear(), month: maxDate.getMonth() + 1, day: maxDate.getDate() };
  }

  onChangeSzallmod() {   
    this.model = null;
    this.setDisabledDays(this.teljesitesForm.controls['szallmod'].value);
  }

  onClickCancel() {
    //this.order.szallmod = this.origOrder.szallmod;
    //this.order.hatido = this.origOrder.hatido;
    //this.model = null;
    //this.modal.close();
  }

  /*public changeSzallmod(method) {
    console.log('changeSzallmod(method)', method);
    this.order.szallmod = method;
    this.order.hatido = '';
    this.model = null;
    this.setDisabledDays();
  }*/

  setDisabledDays(szallmod) {
    console.log('szallmod:',szallmod);
    this.isDisabled = (date: NgbDate, current: { month: number }) => {
      var d = new Date();
      d.setDate(date.day);
      d.setMonth(date.month - 1);
      d.setFullYear(date.year);
      if (szallmod == SZALLMOD_HAZHOZSZALLITAS && this.user.tura.indexOf(d.getDay().toString()) == -1) {
        return true;
      }
      if (szallmod == SZALLMOD_SZEMELYESATVETEL && (d.getDay() == 0 || d.getDay() == 6)) {
        return true;
      }
      

    };
  }

  public onClickDelete() {
    this.teljesitesForm.controls['hatido'].setValue(null);
    this.teljesitesForm.controls['szallmod'].setValue(SZALLMOD_HAZHOZSZALLITAS);
    delete this.order.szallmod;
    delete this.order.hatido;
    localStorage.setItem('order', JSON.stringify(this.order));
    this.store.dispatch({ type: 'ORDER_UPDATED', payload: this.order });
    this.modal.close();
    this.iziToast.show({ title: "Teljesítési adatok törölve!", position: 'topCenter', timeout: 2000 });

  }

  public onClickOK() {
    
    
    this.submitClicked = true;
    if (this.teljesitesForm.status == 'VALID') {
      if(this.addCartAfterSetting) {
        this.addCartAfterSetting.cartService.addCartItem(this.addCartAfterSetting.product, this.addCartAfterSetting.qty);
        this.addCartAfterSetting = null;
      }
      var d = new Date();
      d.setDate(this.teljesitesForm.controls['hatido'].value.day);
      d.setMonth(this.teljesitesForm.controls['hatido'].value.month - 1);
      d.setFullYear(this.teljesitesForm.controls['hatido'].value.year);
     
      this.order.hatido = this.datePipe.transform(d, 'yyyy-MM-dd');
      this.order.szallmod = this.teljesitesForm.controls['szallmod'].value;
      //this.order.shippingAddressId = this.teljesitesForm.controls['shippingAddressId'].value;
      localStorage.setItem('order', JSON.stringify(this.order));
      this.store.dispatch({ type: 'ORDER_UPDATED', payload: this.order });
      this.modal.close();
      this.modMessage();
    }
  }

  modMessage() {
    var title = '';
    if (this.order.szallmod == SZALLMOD_HAZHOZSZALLITAS) {
      title = "Kiszállítás dátuma ";
    } else {
      title = "Átvétel dátuma ";
    }
    this.iziToast.show({ title: title, message: this.order.hatido, position: 'topCenter', timeout: 3000 });
  }


  createForm() {
    this.teljesitesForm = this.fb.group({
      szallmod: new FormControl('', [Validators.required]),
      hatido: new FormControl('', [Validators.required]),
      //shippingAddressId: new FormControl(0, [])
    }
    );
  }

  initForm() {
    var hatidoConf = null;
    if (this.order.hatido) {
      var p: any[] = [];
      var d = this.order.hatido.split('-');

      p[1] = parseInt(d[1]);
      p[0] = parseInt(d[0]);
      p[2] = parseInt(d[2]);
      hatidoConf = { year: p[0], month: p[1], day: p[2] };
      this.model = hatidoConf;
    }    
    this.teljesitesForm.controls['hatido'].setValue(hatidoConf);
    this.teljesitesForm.controls['szallmod'].setValue(this.order.szallmod);    
  }




  ngOnDestroy(): void {
    
    this.subscribeUser.unsubscribe();
    this.subscribeOrder.unsubscribe();
    this.subscribeCartItems.unsubscribe();
  
  }
}



