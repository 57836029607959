import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params, NavigationExtras } from '@angular/router';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { AppState } from '../../../app-state';
import { User } from '../../../models/user.model';
import { UserService } from '../../../services/user.service';
import { EMAIL_REGEX, PASSWORD_REGEX, NEV1_MAX_LENGTH, TEL1_MAX_LENGTH, PASSWORD_MAX_LENGTH, CONTACT_PERSON_MAX_LENGTH } from '../../../utils/constants';
import { PasswordValidation } from '../../../utils/password-validation';
import { HttpErrorResponse } from '@angular/common/http';
import { NgxIzitoastService } from 'ngx-izitoast';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
declare var $: any;

@Component({
  templateUrl: './profile.page.html',  
})
export class ProfilePage implements OnInit {
  profileForm: FormGroup;
  subscribeUser: any;
  user: User = new User();
  submitClicked: boolean;
  passwordMaxLength: number = PASSWORD_MAX_LENGTH;
  tel1MaxLength: number = TEL1_MAX_LENGTH;
  nev1MaxLength: number = NEV1_MAX_LENGTH;
  contactPersonMaxLength: number = CONTACT_PERSON_MAX_LENGTH;
  taxNumberMask = [/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, '-', /[0-9]/, '-', /[0-9]/, /[0-9]/];
  @BlockUI() blockUI: NgBlockUI;
  constructor(
    public iziToast: NgxIzitoastService,
    private router: Router,
    private userService: UserService,
    private fb: FormBuilder,
    private store: Store<AppState>) {

    this.createForm();
  }

  ngOnInit() {
    //var $scrollTop = $('.scroll-to-top-btn');
    //$scrollTop.removeClass('visible');
    this.subscribeUser = this.store.select('user').subscribe(
      user => {
        if (user != null && user.id>0) {
          this.user = user;          
          this.initForm();
        } else {
          
        }
      },
      error => console.log(error)
    );
  }

  onClickSave() {
    this.submitClicked = true;    
    if (this.profileForm.status == 'VALID') {      
      this.blockUI.start();
      this.userService.updateProfile(this.profileForm.value).subscribe(
        user => {
          this.blockUI.stop();
          this.store.dispatch({ type: 'USER_UPDATED', payload: user });
          this.profileForm.markAsPristine();
          this.iziToast.show({title: "Adatok elmentve!", position:'topCenter', timeout: 2000});          
        },
        (err: HttpErrorResponse) => {
          this.blockUI.stop();
        }
      );     
    } else {

    }
  }

  createForm() {    
    this.profileForm = this.fb.group({
      //sznev: new FormControl('', [Validators.required]),
      tel1: new FormControl('', [Validators.required]),
      contactPerson: new FormControl('', [Validators.required]),
      //aiasz: new FormControl('', [Validators.required]),
      password: new FormControl('', [        
        Validators.maxLength(50),        
        Validators.pattern(PASSWORD_REGEX)
      ]),
      confirmPassword: new FormControl('', [        
        Validators.maxLength(50),        
        Validators.pattern(PASSWORD_REGEX)
      ]),
      email: new FormControl({ value: '', disabled: true }),

    },
    {
      validator: PasswordValidation.MatchPassword // your validation method
    }
    );    
  }

  onClickMenuItem(menuItemId: string) {        
    let navigationExtras: NavigationExtras = {
      queryParams: {  }
    };
    this.router.navigate([{ outlets: { popup: menuItemId } }], navigationExtras);    
  }

  initForm() {
    //this.profileForm.controls['sznev'].setValue(this.user.sznev);
    this.profileForm.controls['email'].setValue(this.user.email);
    //this.profileForm.controls['aiasz'].setValue(this.user.aiasz);
    this.profileForm.controls['tel1'].setValue(this.user.tel1);
    this.profileForm.controls['contactPerson'].setValue(this.user.contactPerson);
  }

  ngOnDestroy(): void {
    this.subscribeUser.unsubscribe();    
  }
}
