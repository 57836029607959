export class FolderUtil  {
  
  public static convertArrayToTree(arr) {  
    var tree = [],
        mappedArr = {},
        arrElem,
        mappedElem;

    // First map the nodes of the array to an object -> create a hash table.
    for(var i = 0, len = arr.length; i < len; i++) {
      arrElem = arr[i];
      mappedArr[arrElem.id] = arrElem;        
      mappedArr[arrElem.id]['children'] = [];
    }

    for (var id in mappedArr) {
      if (mappedArr.hasOwnProperty(id)) {
        mappedElem = mappedArr[id];
        // If the element is not at the root level, add it to its parent array of children.
        if (mappedElem.parentId) {
          mappedArr[mappedElem['parentId']]['children'].push(mappedElem);
        }
        // If the element is at the root level, add it to first level elements array.
        else {
          tree.push(mappedElem);
        }
      }
    }


    return tree;

  }

  public static createHTMLTree(data) {
    var html = '';
    html += '<ul class="tree">';
    for(let item in data){        
        if(data[item].children.length > 0 && data[item].parentId == '') {
          html += '<li class="has-children" id="'+data[item].id+'_abc">';      
        } else {
          html += '<li>';      
        }
        if(data[item].children.length > 0){ // An array will return 'object'
            html += '<div class="d-flex"><a href="javascript:void(0)" class="category-link" id="'+ data[item].id+'"> ' + data[item].name + '</a>';
            html += (data[item].items) ? '</a>-<span></span>' : ' <i id="'+ data[item].id+'_icon" class="ml-1 align-self-center icon-square topfolder"></i></div>'; 
            html += FolderUtil.createHTMLTree(data[item].children); // Submenu found. Calling recursively same method (and wrapping it in a div)
        } else {
            //html += data[item].title // No submenu
            html += '<div class="d-flex"><a href="javascript:void(0)" class="category-link subcategory-link" id="'+ data[item].id+'">' + data[item].name + '</a>';
            html += (data[item].items) ? '</a><span></span>' : ' <i id="'+ data[item].id+'_check" class="ml-1 align-self-center icon-check hide-check subfolder"></i></div>'; 
        }
        html += '</li>';
    }
    html += '</ul>';

    return html;
  }

/* ORIG HTML TREE  NE TÖRÖLD!
  buildList(data, isSub){
    //var html = (isSub)?'<div>':''; // Wrap with div if true
    var html = (isSub)?'':''; // Wrap with div if true
    html += '<ul>';
    for(let item in data){
        console.log(data);
        if(data[item].children.length > 0 && data[item].parentid == 0) {
          html += '<li class="has-children">';      
        } else {
          html += '<li>';      
        }
        if(data[item].children.length > 0){ // An array will return 'object'
            if(isSub){
                console.log(data[item]);
                html += '<a href="#" class="teszt" id="'+ data[item].id+'"> ' + data[item].title + '</a><span>('+data[item].items+')</span>';
            } else {
                html += data[item].title;
            }
            html += this.buildList(data[item].children, isSub); // Submenu found. Calling recursively same method (and wrapping it in a div)
        } else {
            //html += data[item].title // No submenu
            html += '<a href="#" class="teszt" id="'+ data[item].id+'">' + data[item].title + '</a><span>('+data[item].items+')</span>';
        }
        html += '</li>';
    }
    html += '</ul>';
    //html += (isSub)?'</div>':'';
    html += (isSub)?'':'';
    return html;
  }
  */

}