import { Ujsag } from '../models/ujsag.model';
import { ActionWithPayload } from './action-with-payload';

export const UJSAGOK_UPDATED = 'UJSAGOK_UPDATED';

export function ujsagokReducer(state: Ujsag[] = [], action: ActionWithPayload<Ujsag[]>) {
  switch (action.type) {
    case UJSAGOK_UPDATED:          
      var resultArray:Array<Ujsag>=[] ;
      action.payload.forEach( i => { 
        resultArray.push(i);
      });
      return resultArray;
     default:
	return state;
  }
}