import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Product } from '../models/product.model';
import { CartItem } from '../models/cartitem.model';
import { FOLDERS_UPDATED } from '../reducers/folders';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { HttpErrorResponse } from '@angular/common/http';
import { AppState } from '../app-state';
import { API_URL } from '../utils/constants';

@Injectable()
export class FavoritesService {
  subscribeFavoritesItems: any;
  subscribeProducts: any;
  favorites: string[] = [];
  constructor(
    private httpClient: HttpClient,
    private store: Store<AppState>
  ) {

    var savedFavorites = JSON.parse(localStorage.getItem('favorites'));    
    if(savedFavorites) {
      this.store.dispatch({ type: 'ADD_FAVORITESITEMS', payload: savedFavorites });
    }
    
    this.subscribeProducts = this.store.select('products').subscribe(
      products => {
        if (products) {
          for(var favoriteId of this.favorites) { 
            if(!products.find(p => p.cikkszam == favoriteId)) {
              this.removeFavoritesItem(favoriteId);
            }
          }
        }
      },
      error => console.log(error)
    );    

    this.subscribeFavoritesItems = this.store.select('favorites').subscribe(
      favorites => {
        if (favorites) {          
          this.favorites = favorites;
          this.saveFavoritesLocalStorage(favorites);
        }
        if (favorites == null) {           
          this.favorites = [];
        }
      },
      error => console.log(error)
    );    
  }
  
  saveFavoritesLocalStorage(favorites) {    
    localStorage.setItem('favorites', JSON.stringify(favorites));
  }

  removeFavoritesItem(cikkszam: string) {
    this.store.dispatch({ type: 'REMOVE_FAVORITESITEM', payload: [cikkszam] });
  }

  clearAll() {    
    localStorage.removeItem('favorites');
    this.store.dispatch({ type: 'CLEAR_FAVORITESITEM', payload: null });
  }

  addFavoritesItem(cikkszam: string) {    
    if(!this.favorites.find(i=> i == cikkszam)) { 
      this.store.dispatch({ type: 'ADD_FAVORITESITEM', payload: [cikkszam] });
    }
  }

  private extractData(res: Response) {
    let body = res.json();
    return body || {};
  }

  private handleError(error: any): Promise<any> {
    console.log('An error occurred', error); // for demo purposes only
    return Promise.reject(error.message || error);
  }
}
