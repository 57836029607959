import { Component, OnInit, ElementRef, Input } from '@angular/core';
import { FolderUtil } from '../../utils/folder-util';
import { Filter } from '../../models/filter.model';
import { AppState } from '../../app-state';
import { Store } from '@ngrx/store';
import { FolderService } from '../../services/folder.service';
import { timer,Observable,Subject } from 'rxjs';
import { Ujsag } from 'src/app/models/ujsag.model';
import { Gyarto } from 'src/app/models/gyarto.model';
import { Marka } from 'src/app/models/marka.model';
import { Alergen } from 'src/app/models/alergen.model';


declare var $: any;


@Component({
  selector: 'filters',
  templateUrl: './filters.component.html'

})
export class FiltersComponent implements OnInit {
  filter: any;
  subscribeFilter:any;
  subscribeGyartok:any;
  subscribeMarkak:any;
  subscribeUjsagok: any;
  subscribeAlergenek: any;
  visibledSubFolders: any[] = [];
  visibledTopFolders: any[] = [];
  visibledUjsagok: any[] = [];
  visibledGyartok: any[] = [];
  visibledMarkak: any[] = [];
  visibledAlergenek: any[] = [];
  ujsagok: Ujsag[] = [];
  gyartok: Gyarto[] = [];
  markak: Marka[] = [];
  alergenek: Alergen[] = [];


  constructor(
    private folderService: FolderService,
    private elementRef: ElementRef,    
    private store: Store<AppState>) {
    
  }

  ngOnInit() {
    this.subscribeUjsagok = this.store.select('ujsagok').subscribe(
      ujsagok => {
        if (ujsagok) {                    
          this.ujsagok = ujsagok;                              
        }
      },
      error => console.log(error)
    );

    this.subscribeFilter = this.store.select('filter').subscribe(
      filter => {
        if (filter) {                    
          this.filter = filter;                    
          this.setVisibledFolders();          
          this.setVisibledUjsagok();    
          this.setVisibledGyartok();  
          this.setVisibledMarkak();  
          this.setVisibledAlergenek();  
          var count =this.visibledAlergenek.length + this.visibledGyartok.length + 
                this.visibledMarkak.length + this.visibledSubFolders.length + this.visibledTopFolders.length
                + this.visibledUjsagok.length;
          if(filter.minPrice || filter.maxPrice) {
            count++;
          }
          if(this.filter.keyword) { 
            count++;
          }
          if(this.filter.ujdonsag) { 
            count++;
          }
          if(this.filter.kifuto) { 
            count++;
          }
          this.folderService.updateFilterCounter(count);
        }
      },
      error => console.log(error)
    );

    this.subscribeGyartok = this.store.select('gyartok').subscribe(
      gyartok => {
        
        if (gyartok) {                              
          this.gyartok = gyartok;                                      
        }
      },
      error => console.log(error)
    );

    this.subscribeMarkak = this.store.select('markak').subscribe(
      markak => {        
        if (markak) {                              
          this.markak = markak;                                      
        }
      },
      error => console.log(error)
    );

    this.subscribeAlergenek = this.store.select('alergenek').subscribe(
      alergenek => {        
        if (alergenek) {                              
          this.alergenek = alergenek;                                      
        }
      },
      error => console.log(error)
    );
  }

  setVisibledUjsagok() {    
    this.visibledUjsagok = [];
    for(var ujsagId of this.filter.ujsagok) {      
      var ujsag = this.ujsagok.find(f=> f.akcio == ujsagId);
      this.visibledUjsagok.push(ujsag);
    }
  }

  setVisibledMarkak() {    
    this.visibledMarkak = [];
    for(var markaId of this.filter.markak) {      
      var marka = this.markak.find(m=> m.marka == markaId);
      this.visibledMarkak.push(marka);
    }    
  }

  setVisibledAlergenek() {    
    this.visibledAlergenek = [];
    for(var alergenId of this.filter.alergenek) {      
      var alergen = this.alergenek.find(m=> m.alergen == alergenId);
      this.visibledAlergenek.push(alergen);
    }    
  }

  setVisibledGyartok() {    
    this.visibledGyartok = [];
    for(var gyartoId of this.filter.gyartok) {      
      var gyarto = this.gyartok.find(f=> f.gyarto == gyartoId);
      this.visibledGyartok.push(gyarto);
    }
    console.log(this.visibledGyartok);
  }

  setVisibledFolders() {    
    this.visibledSubFolders = [];
    this.visibledTopFolders = [];

    var idx :any;;
    for(idx in this.filter.topfolders) {      
      this.filter.subfolders = this.filter.subfolders.filter(f=>f.substring(0, 2) != this.filter.topfolders[idx]);
    }

    for(idx in this.filter.topfolders) {
      var topfolder = this.folderService.folders.find(f=>f.id == this.filter.topfolders[idx]);
      this.visibledTopFolders.push(topfolder);
    }
    for(idx in this.filter.subfolders) {
      var subfolder = this.folderService.folders.find(f=>f.id == this.filter.subfolders[idx]);
      this.visibledSubFolders.push(subfolder);
    }    
  }

  removeKeyword() {
    this.filter.keyword = '';
    this.store.dispatch({ type: 'FILTER_UPDATED', payload: this.filter });
  }

  removeUjdonsag() {
    this.filter.ujdonsag = false;
    this.store.dispatch({ type: 'FILTER_UPDATED', payload: this.filter });
  }

  removeKifuto() {
    this.filter.kifuto = false;
    this.store.dispatch({ type: 'FILTER_UPDATED', payload: this.filter });
  }

  removePrice() {
    delete this.filter.minPrice;
    delete this.filter.maxPrice;
    this.store.dispatch({ type: 'FILTER_UPDATED', payload: this.filter });
  }

  removeFolder(folderId: any) {    
    this.folderService.removeFolderFromFilter(folderId);
  }

  removeUjsag(akcio: any) {    
    this.filter.ujsagok = this.filter.ujsagok.filter(u=> u != akcio);    
    this.store.dispatch({ type: 'FILTER_UPDATED', payload: this.filter });  
  }

  removeGyarto(gyarto: any) {        
    this.filter.gyartok = this.filter.gyartok.filter(u=> u != gyarto);    
    this.store.dispatch({ type: 'FILTER_UPDATED', payload: this.filter });  
  }

  removeAlergen(alergen: any) {        
    this.filter.alergenek = this.filter.alergenek.filter(u=> u != alergen);    
    this.store.dispatch({ type: 'FILTER_UPDATED', payload: this.filter });  
  }

  removeMarka(marka: any) {        
    this.filter.markak = this.filter.markak.filter(u=> u != marka);    
    this.store.dispatch({ type: 'FILTER_UPDATED', payload: this.filter });  
  }

  removeAllFilter() {
    this.folderService.removeFolderFromFilter('ALL');
    this.clearCheckedIcons();
    var filter = { subfolders: [], topfolders: [], ujdonsag:false, kifuto:false,  keyword: '', ujsagok:[], gyartok: [], markak: [], alergenek: []};
    this.store.dispatch({ type: 'FILTER_UPDATED', payload: filter });
  }

  clearCheckedIcons() {
    $('.topfolder').removeClass('icon-check-square');
    $('.topfolder').addClass('icon-square');
    $('.subfolder').addClass(' hide-check');
  }

  ngOnDestroy(): void {
    this.subscribeFilter.unsubscribe();  
    this.subscribeAlergenek.unsubscribe();  
    this.subscribeGyartok.unsubscribe();  
    this.subscribeMarkak.unsubscribe();  
    this.subscribeUjsagok.unsubscribe();  
  }
}
