import { Component, OnInit, ViewChild, ViewContainerRef, ComponentFactoryResolver, ComponentFactory, ComponentRef } from '@angular/core';
import { AppState } from '../../app-state';
import { CartService } from '../../services/cart.service';
import { FormControl, FormArray, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute, Params, NavigationExtras, NavigationEnd } from '@angular/router';
import { CartItem } from '../../models/cartitem.model';
import { Product } from '../../models/product.model';
import { Store } from '@ngrx/store';
import { FoldersComponent } from '../../components/folders/folders.component';
import { Folder } from '../../models/folder.model';
import { Order } from '../../models/order.model';
import { ShippingAddress } from '../../models/shipping-address.model';
import { User } from '../../models/user.model';
import { SZALLMOD_HAZHOZSZALLITAS, SZALLMOD_SZEMELYESATVETEL } from '../../utils/constants';
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { OrderSettingsModal } from '../../components/order-settings/order-settings.modal';
import { NgxIzitoastService } from 'ngx-izitoast';
import { EMAIL_REGEX, PASSWORD_REGEX, PRODUCT_IMAGES_DIR } from '../../utils/constants';

declare var $: any;

@Component({
  selector: 'review',
  templateUrl: './review.component.html',
  //styleUrls: ['./layout.component.css']
})
export class ReviewComponent implements OnInit {
  product_images_url: string = PRODUCT_IMAGES_DIR;
  subscribeCartItems: any;
  subscribeUser: any;
  subscribeOrder: any;
  subscribeShipAddrs: any;
  cartItems: CartItem[] = [];
  shopConfig: any;
  order: Order;
  subscribeShopConfig: any;
  paymentMethodName: string;  
  user: User = new User();
  note: string;  
  hazhozszallitas: string = SZALLMOD_HAZHOZSZALLITAS;
  szemelyesAtvetel: string = SZALLMOD_SZEMELYESATVETEL;
  modalRef : NgbModalRef;
  rendHianyzo: number = 0;
  constructor(
    public iziToast: NgxIzitoastService,
    private modalService: NgbModal,
    public cartService: CartService,
    private router: Router,
    private store: Store<AppState>) {
  }

  ngOnInit() {
    this.subscribeUser = this.store.select('user').subscribe(
      user => {
        if (user != null && user.id > 0) {
          this.user = user;
          console.log(this.user);
        }
      },
      error => console.log(error)
    );

    this.subscribeCartItems = this.store.select('cartItems').subscribe(
      cartItems => {
        if (cartItems) {
          this.cartItems = cartItems;
          if(!this.subscribeOrder ) { 
            this.initSubscribeOrder();
          }
        }
      },
      error => console.log(error)
    );
    
  }


  checkMinOrderSum(minOrderSum) {
    var cartSum = 0;
    console.log(minOrderSum,'+++++++++++++');
    if(this.cartService.cartSum < minOrderSum) { 
      //this.rendHianyzo = Math.floor(minOrderSum - this.cartService.cartSum);
      //this.iziToast.show({title: "A minimális rendelés összege: "+minOrderSum+" Ft", position:'topCenter', timeout: 2000});
    }

    /*for(var cartItem of this.cartItems) {
      cartSum += cartItem.price * cartItem.qty;
    } */   
    
  }

  initSubscribeOrder() {
    this.subscribeOrder = this.store.select('order').subscribe(
      order => {
        if (order) {
          
          this.order = order;                   
          console.log(this.order);
          if(this.order.fizmod == 'FIZU') {
            this.paymentMethodName =  'Banki átutalás';
          }
          if(this.order.fizmod == 'FIZK') {
            this.paymentMethodName =  'Készpénz';
          }
          this.initSubscrConfig();
        }
      },
      error => console.log(error)
    );
  }

  initSubscrConfig() {
    this.subscribeShopConfig = this.store.select('shopConfig').subscribe(
      shopConfig  => {
        if (shopConfig) {          
          this.shopConfig = shopConfig;
          this.checkMinOrderSum(shopConfig.minOrderSum);
        }
      },
      error => console.log(error)
    );
  }

  onClickCartItem(cartItem: any) {
    let navigationExtras: NavigationExtras = {
      queryParams: { 'id': cartItem.cikkszam }
    };
    this.router.navigate([{ outlets: { popup: 'product' } }], navigationExtras);
  }

  onClickOpenCart() {
    let navigationExtras: NavigationExtras = {
      queryParams: {}
    };
    this.router.navigate([{ outlets: { popup: 'cart' } }], navigationExtras);
  }

  editShippingMode() {
    this.modalRef = this.modalService.open(OrderSettingsModal, { size: 'lg', backdrop:'static' });
    this.modalRef.componentInstance.visibledCancel = false;
  }

  ngOnDestroy(): void {
    this.subscribeCartItems.unsubscribe();
    this.subscribeOrder.unsubscribe();
    this.subscribeShopConfig.unsubscribe();    
    this.subscribeUser.unsubscribe();
  }
}
